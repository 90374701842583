import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { useData } from '../../contextProviders/DataProvider';
import useSeasons from '../../dataHooks/useSeasons';
import DefaultObjects from '../../types/DefaultObjects';
import League from '../../types/League';
import Season from '../../types/Season';
import {} from '../../utils/Compete';
import Hourglass from '../common/Hourglass';

import SeasonEditor from './SeasonEditor';

interface SeasonsEditorProps {
  league?: League;
  isNewLeague?: boolean;
  includeCatchAll?: boolean;
  onAdd?: (season: Season) => void;
  onDelete?: (season: Season) => void;
  onChange?: (season: Partial<Season>) => void;
}

export default function SeasonsEditor(props: SeasonsEditorProps): ReactElement {
  const { league, isNewLeague = false, includeCatchAll = false, onAdd, onDelete, onChange } = props;
  const { isLeagueAdmin, userProfile } = useData();

  const { seasons, isSeasonsLoading, seasonsError } = useSeasons({
    leagueId: league && !isNewLeague ? league.itemId : null,
    orgId: league && !isNewLeague ? league.orgId : null,
    includeCatchAll: includeCatchAll,
  });

  const handleSeasonAdd = (parentLeague: League) => {
    if (onAdd) {
      const season = {
        ...DefaultObjects.Season,
        creatorId: userProfile?.itemId || '',
        isCatchAll: false,
        leagueId: parentLeague.itemId,
        leagueName: parentLeague.name,
        orgId: parentLeague.orgId,
        orgName: parentLeague.orgName,
      };
      onAdd(season);
    }
  };

  const handleSeasonChange = (season: Partial<Season>) => {
    if (onChange) {
      onChange(season);
    }
  };

  const handleSeasonDelete = (season: Season) => {
    if (onDelete) {
      onDelete(season);
    }
  };

  // Todo: Allow adding seasons when seasons for multiple leagues are displayed.
  if (isSeasonsLoading) {
    return <Hourglass />;
  } else if (seasonsError) {
    return <Alert severity="error">Error loading seasons: {seasonsError.message}</Alert>;
  } else {
    if (league && isLeagueAdmin(league.itemId, league.orgId)) {
      return (
        <Stack spacing={2}>
          <Typography variant="headline">Seasons</Typography>
          {seasons && 
            seasons.map((season) =>
              <SeasonEditor 
                key={season.itemId} 
                season={season} 
                onChange={handleSeasonChange} 
                onDelete={handleSeasonDelete} 
              />
            )
          }
          {onAdd && (
            <Button 
              color="secondary" 
              size="small" 
              variant="outlined" 
              startIcon={<AddIcon />} 
              onClick={() => handleSeasonAdd(league)}
            sx={{ width: 'fit-content' }}
            >
              Add season
            </Button>
          )}
        </Stack>
      );
    } else {
      return (
        <Alert severity="error">
          You are not authorized to edit this league
        </Alert>
      );
    }
  }
}

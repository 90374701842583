import { memo, ReactElement } from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useCourses from '../../dataHooks/useCourses';
import Course from '../../types/Course';
import Hourglass from '../common/Hourglass';

export type CourseSelectorProps = {
  value: string;
  handleChange: (course: Course) => void;
  disabled?: boolean;
  isRequired?: boolean;
  helperText?: string;
}

const CourseSelector = memo(function CourseSelector(props: CourseSelectorProps): ReactElement {
  const { value, handleChange, disabled, helperText, isRequired } = props;
  const { courses, isCoursesLoading, coursesError } = useCourses({});

  if (isCoursesLoading) {
    return <Hourglass />;
  }

  if (coursesError) {
    return <Typography>Error loading courses</Typography>;
  }

  if (!courses?.length) {
    return <Typography>No courses available</Typography>;
  }

  console.log('value: ', value);

  return (
    <TextField
      id="course"
      label="Course"
      required={isRequired}
      fullWidth
      disabled={disabled}
      helperText={helperText}
      select
      aria-label="Course"
      aria-required={isRequired}
      value={value}
      onChange={({ target }) => {
        const course = courses.find((course: Course) => course.itemId === target.value);
        if (course) {
          handleChange(course);
        }
      }}
    >
      {courses.map((course) => (
        <MenuItem key={course.itemId} value={course.itemId}>
          {course.name}
        </MenuItem>
      ))}
    </TextField>
  );
});

export default CourseSelector;

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Handbook from '../../types/Handbook';
import SanitizedHtml from '../common/SanitizedHtml';

function HandbookContentView({ content }: { content: Handbook['sections'][0] }) {
  return (
    <Box maxWidth="100%" overflow="auto">
      <Box
        maxWidth="100%"
        sx={{
          '& img': { maxWidth: '100%', height: 'auto' },
          '& table': { maxWidth: '100%', overflowX: 'auto', display: 'block' },
        }}
      >
        <SanitizedHtml content={content.html} />
      </Box>
    </Box>
  );
}

interface HandbookViewProps {
  handbook: Handbook;
  initialSection: string;
  onSectionChange: (_event: React.SyntheticEvent, newValue: string) => void;
  showTitle?: boolean;
}

export function isHandbookEmpty(handbook: Handbook): boolean {
  if (!handbook || !handbook.sections) {
    return true;
  }

  return handbook.sections.every((section) => {
    if (!section.html) {
      return true; // If html is missing, consider it empty
    }

    const strippedHtml = section.html.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const trimmedContent = strippedHtml.trim();
    return trimmedContent === ''; // Check for empty content after stripping
  });
}

export default function HandbookView(props: HandbookViewProps) {
  const { handbook, initialSection = '0', onSectionChange } = props;
  console.log('showTitle: ', props.showTitle);
  const showTitle = props.showTitle ?? true;

  return (
    <Box maxWidth="100%" overflow="auto">
      {showTitle && <Typography variant="headline">{handbook.name}</Typography>}
      {!isHandbookEmpty(handbook) ? (
        <TabContext value={initialSection}>
          <TabList
            onChange={onSectionChange}
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="handbook content tabs"
          >
            {handbook.sections.map((section, index) => (
              <Tab key={index} value={index.toString()} label={section.title || `Section ${index + 1}`} wrapped />
            ))}
          </TabList>
          {handbook.sections.map((section, index) => (
            <TabPanel key={index} value={index.toString()}>
              <HandbookContentView content={section} />
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <Typography variant="body1">This handbook has no content.</Typography>
      )}
    </Box>
  );
}

import { 
  forwardRef,
  ReactElement, 
} from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { 
  styled, 
  Theme,
  useThemeProps 
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CompDenomination } from "../../types/Competition";
import Score from "../../types/Score";

// TODO: Handle the case when the par is different on the same hole.
export interface ColumnProps {
  fields: string; // Comma-separated list of fields - used as the key.
  fieldLabels: string; // Comma-separated list of holes - if not provided, 1-18 displayed.
  holePars?: string; // Comma-separated list of pars for each hole - if not provided, no vs. par displayed.
}

export interface GratiScorecardProps {
  title?: string;
  columns: ColumnProps;
  rows: Score[];
  denomination?: CompDenomination;
  isStriped?: boolean;
  filterFunction?: (score: Score) => boolean;
}

interface GratiScorecardOwnerState extends GratiScorecardProps {

}

const GratiScorecardRoot = styled('div', {
  name: 'GratiScorecard',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    return styles.root;
  },
})<{ ownerState: GratiScorecardOwnerState}>(() => ({
}));

const GratiScorecardTitle = styled('div', { 
  name: 'GratiScorecard',
  slot: 'Title',
  overridesResolver: (props, styles) => {
    return styles.title;
  },
})<{ ownerState: GratiScorecardOwnerState }>(({ theme }) => ({
  ...theme.typography.title,
}));

const GratiScorecardHeader = styled('div', {
  name: 'GratiScorecard',
  slot: 'Header',
  overridesResolver: (props, styles) => {
    return styles.header;
  },
})<{ ownerState: GratiScorecardOwnerState }>(() => ({
}));

const GratiScorecardRow = styled(Stack, {
  name: 'GratiScorecard',
  slot: 'Row',
  overridesResolver: (props, styles) => {
    return styles.row;
  },
})<{ ownerState: GratiScorecardOwnerState}>(() => ({
}));

const GratiScorecardRowLabel = styled('div', {
  name: 'GratiScorecard',
  slot: 'RowLabel',
  overridesResolver: (props, styles) => {
    return styles.rowlabel;
  },
})<{ ownerState: GratiScorecardOwnerState}>(() => ({
}));

const GratiScorecardCell = styled('div', {
  name: 'GratiScorecard',
  slot: 'Cell',
  overridesResolver: (props, styles) => {
    return styles.cell;
  },
})<{ ownerState: GratiScorecardOwnerState}>(() => ({
}));

const GratiScorecardTotal = styled('div', {
  name: 'GratiScorecard',
  slot: 'Total',
  overridesResolver: (props, styles) => {
    return styles.total;
  },
})<{ ownerState: GratiScorecardOwnerState}>(() => ({
}));

export const GratiScorecard = forwardRef<HTMLDivElement, GratiScorecardProps>(
  function Gratiscorecard(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'GratiScorecard' });
    const { title, columns, rows, denomination, isStriped, filterFunction, ...other } = props;
    const isSingleLine = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')); // A scorecard row can fit on one line.
    const ownerState = { ...props };

    const fieldLabels = columns.fieldLabels.split(",");
    const holePars = columns.holePars ? columns.holePars.split(",") : [];

    const calculateTotal = (scores: number[]) => {
      return scores.reduce((total, score) => total + score, 0);
    };

    const CashRow = (props: {name: string, cashAmount: number, isStriped: boolean}): ReactElement => {
      const { name, cashAmount, isStriped } = props;

      return (
        <Stack direction="row" 
          sx={{ 
            my: 0, 
            display: "flex", 
            flexGrow: 1, 
            alignItems: "flex-start",
            backgroundColor: isStriped ? "surface.main" : "surface.variant",
            color: isStriped ? "surface.contrastText" : "surface.onVariant" }}>
          <GratiScorecardRowLabel ownerState={ownerState} key="headerlabel">
            <Typography variant="h6dense">
              {name}
            </Typography>
          </GratiScorecardRowLabel>
          <GratiScorecardTotal ownerState={ownerState} sx={{width: "6rem"}}>
            <Typography variant="h6dense">
              {cashAmount}
            </Typography>
          </GratiScorecardTotal>   
        </Stack>       
      );
    }

    const HoleScore = (props: {score: number, par: number}): ReactElement => {
      const { score, par } = props;
      const difference = par > 0 ? score - par : 0;
      const absDifference = Math.abs(difference);
      const borderColor = absDifference < 1 ? "black" : difference > 1 ? "Tomato" : "LimeGreen";
      const borderRadius = difference < 0 ? '50%' : '0%';
      const borderThickness = 
        absDifference === 0 ? "0rem" : absDifference === 1 ? "0.05rem solid" : 
          absDifference === 2 ? ".1rem solid" : ".15rem solid";

      const border = borderThickness + " " + borderColor; 

      return (
        <GratiScorecardCell ownerState={ownerState} sx={{p: 0, m: 0, textAlign: 'center'}} >
          <Box sx={{width: '80%', height: '80%', borderRadius: borderRadius, border: border}}>
            <Typography variant="pdense" sx={{m: 0, p: 0}}>
              {score}
            </Typography>
          </Box>
        </GratiScorecardCell>
      );
    }

    function chunkArray<T>(array: T[], chunkSize: number): T[][] {
      return array.reduce((result: T[][], item: T, index: number) => {
        const chunkIndex = Math.floor(index / chunkSize);
    
        if (!result[chunkIndex]) {
          result[chunkIndex] = [];
        }
    
        result[chunkIndex].push(item);
    
        return result;
      }, []);
    }

    const ScoreRow = (props: 
      {name: string, 
        scoreByHole: number[], 
        totalScore?: number, 
        parByHole?: number[], 
        isStriped: boolean}): ReactElement => {
      const name = props.name;
      const isStriped = props.isStriped;
      const totalScore = props.totalScore ? props.totalScore : calculateTotal(props.scoreByHole);
      const numColumns = isSingleLine ? Math.min(18, props.scoreByHole.length) : Math.min(9, props.scoreByHole.length);
      const scores = chunkArray(props.scoreByHole, numColumns);
      const pars = holePars.length > 0 ? chunkArray(holePars.map(Number), numColumns) : null;

      return (
        <Stack
          direction="row" 
          sx={{ 
            my: 0, 
            display: "flex", 
            flexGrow: 0, 
            backgroundColor: isStriped ? "surface.main" : "surface.variant",
            color: isStriped ? "surface.contrastText" : "surface.onVariant" }}>
          <GratiScorecardRowLabel ownerState={ownerState} key="headerlabel">
            <Typography variant="h6dense">
              {name}
            </Typography>
          </GratiScorecardRowLabel>
          <Stack key="score" direction="column" sx={{ display: "flex", flexGrow: 0, alignItems: "flex-start", my: 0}}>
            {scores.map((row, rowIndex) => (
              <Stack key={rowIndex} direction="row" sx={{ my: 0, display: "flex", alignItems: "center", justifyContent: "flex-start", flexGrow: 0, p: 0, m: 0}}>
                {row.map((score, hole) => (
                  <HoleScore key={hole} score={score} par={pars ? pars[rowIndex][hole] : 0} />
                ))}
              </Stack>
            ))}
          </Stack>
          <GratiScorecardTotal ownerState={ownerState}>
            <Typography variant="h6dense">
              {totalScore}
            </Typography>
          </GratiScorecardTotal>
        </Stack>
      );
    }

    const CashHeader = (): ReactElement => {
      return (
        <Stack direction="row" sx={{ my: 0, flexGrow: 1, display: "flex", width: 1 }}>
          <GratiScorecardRowLabel ownerState={ownerState}>
            <Typography variant="h6dense" sx={{ display: "block" }} >
              Player
            </Typography>
          </GratiScorecardRowLabel>
          <GratiScorecardTotal ownerState={ownerState} sx={{width: "6rem"}}>
            <Typography variant="h6dense">
              Cash
            </Typography>
          </GratiScorecardTotal>
        </Stack>
      )
    }

    const ScorecardHeader = (): ReactElement => {
      const numColumns = isSingleLine ? Math.min(18, fieldLabels.length) : Math.min(9, fieldLabels.length);
      const headerLabels = chunkArray(fieldLabels, numColumns);
      const headerPars = holePars.length > 0 ? chunkArray(holePars, numColumns) : null;

      return (
        <Stack sx={{ my: 0, width: 1 }}>
          {headerLabels.map((row, rowIndex) => (
            <Stack key={rowIndex} direction="row">
              <GratiScorecardRowLabel ownerState={ownerState}>
                <Stack key="headerlabel" sx={{my: 0 }}>
                  <Typography variant="h6dense">
                    Hole #
                  </Typography>
                  {holePars.length > 0 && 
                    <Typography variant="h6dense">
                      Par
                    </Typography>
                  }
                </Stack>
              </GratiScorecardRowLabel>
              {row.map((label, hole) => (
                <Stack key={hole} sx={{my: 0}}>
                  <GratiScorecardCell ownerState={ownerState}>
                    <Typography variant="h6dense" >
                      {label}
                    </Typography>
                  </GratiScorecardCell>
                  <GratiScorecardCell ownerState={ownerState}>
                    {headerPars && 
                      <Typography variant="h6dense">
                        {headerPars[rowIndex][hole]}
                      </Typography>
                    }
                  </GratiScorecardCell>
                </Stack>
              ))}
              <GratiScorecardTotal ownerState={ownerState}>
                { fieldLabels.length > 1 && rowIndex === headerLabels.length - 1 && 
                  <Typography variant="h6dense">
                    Total
                  </Typography>
                }
              </GratiScorecardTotal>
            </Stack>
          ))}
        </Stack>
      );
    }

    if (!rows || rows.length === 0) {
      return (
        <Stack>
          <Typography variant="title">
            {title}
          </Typography>
          <Typography variant="body1">
            No scores to display.
          </Typography>
        </Stack>
      );
    } else {
      return (
        <GratiScorecardRoot ref={ref} ownerState={ownerState} {...other}> 
          { title && 
            <GratiScorecardTitle ownerState={ownerState}>
              {title}
            </GratiScorecardTitle>
          }       
          <GratiScorecardHeader ownerState={ownerState}>
            {denomination === ('stroke' as CompDenomination) &&
              <ScorecardHeader />
            }
            {denomination === ('cash' as CompDenomination) &&
              <CashHeader />
            }
          </GratiScorecardHeader>

          {rows.filter(filterFunction ? filterFunction : () => true).map((score, index) =>
            <GratiScorecardRow key={"score" + score.playerId} ownerState={ownerState}>
              {denomination === ('stroke' as CompDenomination) &&
                <ScoreRow 
                  name={score.playerName} 
                  scoreByHole={score.scoreByHole ? score.scoreByHole.split(",").map(Number) : []} 
                  totalScore={score.totalScore}
                  isStriped={index % 2 === 0}
                />
              }
              {denomination === ('cash' as CompDenomination) &&
                <CashRow 
                  name={score.playerName} 
                  cashAmount={score.totalScore ? score.totalScore : 0}
                  isStriped={index % 2 === 0}
                />
              }
            </GratiScorecardRow>
          )}
        </GratiScorecardRoot>
      );
    }
  },
);  
import { doc, getDoc, setDoc } from 'firebase/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import DefaultObjects from '../types/DefaultObjects';
import Event from '../types/Event';
import Person from '../types/Person';
import Registration from '../types/Registration';
import { CurrentDateTime } from '../utils/utils';

export const useEventRegistrationMutators = () => {
  const { userProfile } = useData();
  const { isOrgAdmin } = useData();

  const addEventRegistration = async (
    event: Event,
    isRegistering: boolean,
    personToRegister?: Person
  ): Promise<Registration | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!event) {
      console.error('Event is null');
      return null;
    }

    try {
      let regUserId: string;
      let regUserName: string;

      if (personToRegister) {
        if (!isOrgAdmin(event.orgId) && personToRegister.itemId !== userProfile.itemId) {
          console.error('User not authorized to register for another user');
          return null;
        }
        regUserId = personToRegister.itemId;
        regUserName = personToRegister.name;
      } else {
        regUserId = userProfile.itemId;
        regUserName = userProfile.name;
      }

      const regRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}/reg/${regUserId}`);
      const regDoc = await getDoc(regRef);

      let reg: Registration;
      if (regDoc.exists()) {
        reg = {
          ...(regDoc.data() as Registration),
          datetime: CurrentDateTime(),
          isRegistered: isRegistering,
        };
      } else {
        reg = {
          ...DefaultObjects.Registration,
          eventId: event.itemId,
          itemId: regUserId,
          playerId: regUserId,
          playerName: regUserName,
          isRegistered: isRegistering,
        };
      }

      await setDoc(regRef, reg, { merge: true });
      return reg;
    } catch (error) {
      console.error('Error adding event registration:', error);
      return null;
    }
  };

  const updateEventRegistration = async (
    event: Event,
    playerId: string,
    registration: Partial<Registration>
  ): Promise<void> => {
    if (!userProfile) {
      console.error('User profile not found');
      return;
    }

    if (!event) {
      console.error('Event not found');
      return;
    }

    // A player can update their own registration or an admin can update any registration.
    if (userProfile.itemId !== playerId && !isOrgAdmin(event.orgId)) {
      console.error('Permission denied: must be player or admin');
      return;
    }

    try {
      // Create a copy to avoid modifying the original registration object
      const updatedRegistration = { ...registration };

      // Delete itemId and playerId if they exist
      delete updatedRegistration.itemId;
      delete updatedRegistration.playerId;

      const regRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}/reg/${playerId}`);
      await setDoc(regRef, { ...updatedRegistration, playerId }, { merge: true });
    } catch (error) {
      console.error('Error updating event registration:', error);
    }
  };

  return {
    addEventRegistration,
    updateEventRegistration,
  };
};

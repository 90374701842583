import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { CompDenomination } from '../../types/Competition';
import Result from '../../types/Result';
import Score from '../../types/Score';
import Season from '../../types/Season';
import { GratiScorecard } from '../common/GratiScorecard';
type SeasonResultsProps = {
  season: Season;
  memberId?: string;
};

export default function SeasonResults(props: SeasonResultsProps): ReactElement {
  const { season, memberId } = props;

  function filterPlayers(score: Score): boolean {
    if (score) {
      return memberId ? score.playerId === memberId : true;
    } else {
      return false;
    }
  }

  if (season && season.standings && season.standings.length > 0) {
    return (
      <Stack key={'standings' + season.itemId} spacing={1} sx={{ display: 'flex', justifyContent: 'flex-start', flexGrow: 0 }}>
        {season.standings.map(
          (standing: Result) =>
            standing.scores && (
              <GratiScorecard
                key={standing.competition.name}
                title={standing.competition.name}
                columns={{
                  fields:
                    standing.competition.denomination === 'stroke' as CompDenomination
                      ? '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18'
                      : 'Cash' as CompDenomination,
                  fieldLabels:
                    standing.competition.denomination === 'stroke' as CompDenomination
                      ? '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18'
                      : 'Cash' as CompDenomination,
                  holePars:
                    standing.competition.denomination === 'stroke' as CompDenomination
                      ? standing.courseParByHole
                      : undefined,
                }}
                rows={standing.scores}
                filterFunction={filterPlayers}
                denomination={standing.competition.denomination}
              />
            )
        )}
      </Stack>
    );
  } else {
    return <Box key={'standings' + season.itemId}></Box>;
  }
}

import { 
  ReactElement,
} from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAuth } from "../../contextProviders/AuthProvider";
import Season from "../../types/Season";
import SeasonStandingCsvUpload from "../season/SeasonStandingCsvUpload";

import SeasonListItem from "./SeasonListItem";
import SeasonResults from "./SeasonResults";

type SeasonViewProps = {
  season: Season;
};

export default function SeasonView(props: SeasonViewProps ): ReactElement {
  const { season } = props;
  const { isGod } = useAuth();

  const SeasonView = (): ReactElement => {
    if (season) {
      return (
        <Stack spacing={2}>
          <SeasonListItem season={season} />
          <Divider sx={{ display: { sm: 'none' } }} />
          <Box>
            <Typography variant="headline">Results</Typography>
          </Box>
          <SeasonResults season={season} />
          {isGod && <SeasonStandingCsvUpload season={season} />}
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  if (season) {
    return (
      <Box>
        <SeasonView />
      </Box>
    );
  } else {
    return <> </>
  }
}
import { Timestamp } from 'firebase/firestore';

import { HoursMinutesToMilliseconds } from '../utils/utils';

import Course from './Course';
import Event from './Event';
import Handbook from './Handbook';
import League from './League';
import PersonDetails from './PersonDetails';
import Registration from './Registration';
import Season from './Season';

export const DefaultObjects = {
  Course: {
    creatorId: '',
    createTime: Timestamp.now(),
    googlePlaceId: '',
    isTest: false,
    itemId: '',
    logo: '',
    membership: '',
    name: '',
    orgId: '',
    orgName: '',
    par: 0,
    shortName: '',
    teeTimeSpacing: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    usgaCourseId: '',
  } as Course,
  PersonDetails: {
    itemId: '',
    creatorId: '',
    createTime: Timestamp.now(),
    name: '',
    birthday: Timestamp.now(),
    gender: '',
    localAddress: '',
    mailingAddress: '',
    phone: '',
  } as PersonDetails,
  Event: {
    name: '',
    coursePairs: [],
    areResultsPosted: false,
    competitions: [],
    creatorId: '',
    createTime: Timestamp.now(),
    days: [
      {
        courseId: '',
        datetime: Timestamp.now(),
        duration: HoursMinutesToMilliseconds(4, 0),
        isGolf: true,
        isShotgun: false,
      },
    ],
    datetime: Timestamp.now(),
    description: '',
    isGolf: true,
    isShotgun: false,
    isTest: false,
    itemId: '',
    leagueId: /* leagueId ?? */ '',
    leagueName: '',
    logo: '',
    maxPlayers: 0,
    numTeeTimes: 0,
    orgId: /* orgId ?? */ '',
    seasonId: /* seasonId ?? */ '',
    seasonName: '',
    signupOpensAt: Timestamp.now(),
    signupClosesAt: Timestamp.now(),
    socialLocation: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  } as Event,
  Handbook: {
    category: '',
    creatorId: '',
    createTime: Timestamp.now(),
    description: '',
    history: [],
    isDeleted: false,
    isFeatured: false,
    isPublic: false,
    isPublished: false,
    isTest: false,
    itemId: '',
    logo: '',
    name: '',
    orgId: '',
    orgName: '',
    sections: [],
    tags: [],
  } as Handbook,
  League: {
    name: '',
    competitionLevel: 0,
    creatorId: '',
    createTime: Timestamp.now(),
    description: '',
    isTest: false,
    itemId: '',
    logo: '',
    orgName: '',
    orgId: '',
  } as League,
  Registration: {
    eventId: '',
    playerId: '',
    playerName: '',
    comment: '',
    datetime: Timestamp.now(),
    group: 0,
    isEighteen: true,
    isPlayingGame: true,
    isRiding: true,
    isRegistered: false,
    isWaitListed: false,
  } as Registration,
  Season: {
    creatorId: '',
    createTime: Timestamp.now(),
    endTime: Timestamp.now(),
    isCatchAll: false,
    isDeleted: false,
    isTest: false,
    itemId: '',
    leagueId: '',
    leagueName: '',
    name: '',
    orgId: '',
    orgName: '',
    startTime: Timestamp.now(),
  } as Season,
  // Add other types here
};

export type SupportedTypes = keyof typeof DefaultObjects;
export default DefaultObjects;

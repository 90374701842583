import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventCard from '../components/event/EventCard';
import EventSchedule from '../components/event/EventSchedule';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import useEvents from '../dataHooks/useEvents';
import Event from '../types/Event';
import { IsEventSignupInProgress } from '../utils/utils';

//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function Home(): ReactElement {
  const navigate = useNavigate();

  const { isLoggedIn, isLoggingIn } = useAuth();
  const { canCreateEvents, userProfile } = useData();
  const { events, isEventsLoading, eventsError } = useEvents(userProfile ? { filter: "openForSignup" } : { });

  useEffect(() => {
    if (!isLoggingIn) {
      if (isLoggedIn) {
        //!isAuthenticated && navigate("/verify", { state: {isEmailSentFirst: false }});
      } else {
        navigate('/welcome');
      }
    }
  }, [isLoggingIn, isLoggedIn, navigate]);

  function checkIsEventOpen(event: Event): boolean {
    return IsEventSignupInProgress(event);
  }

  function OpenForSignupSection(): ReactElement {
    if (events) {
      const openEvents = events.filter(checkIsEventOpen);
      if (openEvents?.length > 0) {
        return (
          <Grid container direction="row" spacing={1}>
            {openEvents.flatMap((event: Event) => {
              return (
                <Grid key={'open' + event.itemId} minWidth={300} size={{ xs: 12, sm: 8, md: 6 }}>
                  <EventCard  variant="outlined" isClickable={true} event={event} />
                </Grid>
              );
            })}
          </Grid>
        );
      } else {
        return <Typography variant="body2">Sadly, no events open right now.</Typography>;
      }
    } else if (eventsError) {
      return <Typography variant="body2">Error loading events: {eventsError.message}</Typography>;
    } else {
      return <> </>;
    }
  }

  /* TODO: Rewrite without duplicate code */
  // TODO: Add league results section like: <LeagueResults league={league} memberId={userProfile.itemId}/>
  return (
    <GratiPageWrapper isContentLoading={isEventsLoading}>
      <Stack spacing={2} sx={{display: "flex", flexGrow: 1, p: 1}}>
        <Box sx={{pt: 1}}>
          <Typography variant="headline">Open for Signup</Typography>
          <OpenForSignupSection />
        </Box>
        <Divider variant="fullWidth" />
        <Box>
          <Typography variant="headline">Upcoming Events</Typography>
          <EventSchedule isUpcoming={true} />
        </Box>
        {canCreateEvents() && (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add event"
            component={Link}
            to="/event-create"
          >
            <AddIcon />
            Add event
          </Fab>
        )}
      </Stack>
    </GratiPageWrapper>
  );
}

import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Alert, debounce } from '@mui/material';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import Hourglass from '../components/common/Hourglass';
import OrgSelector from '../components/org/OrgSelector';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import useOrgsWithAdminRights from '../dataHooks/useOrgsWithAdminRights';
import { useInvitationMutators } from '../dataMutators/useInvitationMutators';
import Person from '../types/Person';
import { validateEmail } from '../utils/validators';

type InvitationEntry = {
  name: string;
  email: string;
  id: string; // for tracking individual entries
};

type InvitationForm = {
  orgId: string;
  isTest: boolean;
  entries: InvitationEntry[];
};

export default function InviteMembers(): ReactElement {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { isGod } = useAuth();
  const { addInvitation } = useInvitationMutators();
  const { orgs, isOrgsLoading, orgsError } = useOrgsWithAdminRights();

  const [emailState, setEmailState] = useState({
    isChecking: false,
    isInvalid: false,
  });

  const [invitation, setInvitation] = useState<InvitationForm>({
    orgId: orgId || '',
    isTest: false,
    entries: [],
  });

  const [currentEntry, setCurrentEntry] = useState<Omit<InvitationEntry, 'id'>>({
    name: '',
    email: '',
  });

  const [editingId, setEditingId] = useState<string | null>(null);

  const orgPairs = useMemo(() => {
    if (!orgs) return [];
    return orgs.map(org => ({
      orgId: org.itemId,
      orgName: org.name
    }));
  }, [orgs]);

  useEffect(() => {
    setPageName('Invite Members');
  }, [setPageName]);

  const handleSubmit = async () => {
    const promises = invitation.entries.map((entry) =>
      addInvitation(invitation.orgId, entry.name, entry.email, invitation.isTest)
    );

    await Promise.all(promises);
    navigate(`/org-applications/${invitation.orgId}`);
  };

  const handleOrgChange = useCallback((orgId: string) => {
    setInvitation(prev => ({
      ...prev,
      orgId,
      entries: []
    }));
  }, []);

  const handleAddOrUpdateEntry = () => {
    if (editingId) {
      setInvitation(prev => ({
        ...prev,
        entries: prev.entries.map(entry =>
          entry.id === editingId ? { ...currentEntry, id: entry.id } : entry
        ),
      }));
      setEditingId(null);
    } else {
      setInvitation(prev => ({
        ...prev,
        entries: [...prev.entries, { ...currentEntry, id: crypto.randomUUID() }],
      }));
    }
    setCurrentEntry({ name: '', email: '' });
  };

  const validateEmailField = debounce(async (email: string) => {
    // Don't check empty emails
    if (!email.trim()) {
      setEmailState((prev) => ({
        ...prev,
        isInvalid: false,
        isChecking: false,
      }));
      return;
    }

    setEmailState((prev) => ({ ...prev, isChecking: true }));

    // First check format
    const isInvalid = !validateEmail(email);
    if (isInvalid) {
      console.log('isInvalid: ', isInvalid);
      setEmailState((prev) => ({
        ...prev,
        isInvalid: true,
        isChecking: false,
      }));
      return;
    } else {
      setEmailState((prev) => ({
        ...prev,
        isInvalid: false,
        isChecking: false,
      }));
    }
  }, 500);

  const validateUser = (userProfile: Person | null | undefined) => {
    return isGod || (orgId && userProfile && orgs?.some(org => org.itemId === orgId)) ? true : false;
  };

  if (isOrgsLoading) {
    return <Hourglass />;
  }

  if (orgsError) {
    console.error('Error loading organizations:', orgsError);
    return <Alert severity="error">Unable to load organizations. Please try again later.</Alert>;
  }

  return (
    <GratiPageWrapper validateUser={validateUser} isContentLoading={isOrgsLoading}>
      <Stack
        spacing={2}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <OrgSelector 
          value={invitation.orgId}
          orgPairs={orgPairs}
          handleChange={(orgPair) => handleOrgChange(orgPair.orgId)}
        />
        {/* Display existing entries */}
        {invitation.entries.map((entry) => (
          <Paper key={entry.id} sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid size={{ xs: 8 }}>
                <Typography>
                  {entry.name} ({entry.email})
                </Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <IconButton
                  onClick={() => {
                    setCurrentEntry({ name: entry.name, email: entry.email });
                    setEditingId(entry.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setInvitation((prev) => ({
                      ...prev,
                      entries: prev.entries.filter((e) => e.id !== entry.id),
                    }));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 5 }}>
            <TextField
              required={invitation.entries.length === 0}
              autoFocus={true}
              id={'name'}
              label="Name"
              aria-label="name"
              fullWidth
              value={currentEntry.name}
              onChange={(e) => setCurrentEntry((prev) => ({ ...prev, name: e.target.value }))}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 5 }}>
            <TextField
              required={invitation.entries.length === 0}
              label="Email"
              fullWidth
              value={currentEntry.email}
              error={emailState.isInvalid}
              helperText={emailState.isChecking ? 'Checking email...' : emailState.isInvalid && 'Invalid email format'}
              onChange={(e) => {
                const newValue = e.target.value;
                setCurrentEntry((prev) => ({ ...prev, email: newValue }));
                validateEmailField(newValue);
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }} display="flex" alignItems="center">
            <Button
              id="add-invitation"
              aria-label="add invitation"
              variant="outlined"
              onClick={handleAddOrUpdateEntry}
              disabled={!currentEntry.name || !currentEntry.email || emailState.isInvalid}
              sx={{ minWidth: 'auto' }}
            >
              {editingId ? 'Update' : 'Add'}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ alignSelf: 'center' }}>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            disabled={invitation.entries.length === 0}
            sx={{ minWidth: 'auto', width: 'auto' }}
          >
            Invite {invitation.entries.length > 0 ? invitation.entries.length : ''} Member
            {invitation.entries.length !== 1 ? 's' : ''}
          </Button>
        </Box>
      </Stack>
      {isGod && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ backgroundColor: 'tertiary.container', color: 'tertiary.onContainer' }}
        >
          <FormGroup>
            <FormControlLabel
              id="isTest"
              value="top"
              aria-label="Is a test event"
              aria-required="false"
              label="Test?"
              control={
                <Switch
                  id="isTest"
                  checked={invitation.isTest}
                  color="secondary"
                  onChange={(e) => setInvitation({ ...invitation, isTest: e.target.checked })}
                />
              }
            />
          </FormGroup>
        </Stack>
      )}
    </GratiPageWrapper>
  );
}

import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

import useMembershipsOfPerson from '../dataHooks/useMembershipsOfPerson';
import useUserProfile from '../dataHooks/useUserProfile';
import Membership from '../types/Membership';
import Person from '../types/Person';

import { useAuth } from './AuthProvider';

export interface DataProviderProps {
  children?: ReactNode;
}

export interface DataContextModel {
  // User State
  userProfile: Person | null | undefined;
  isUserLoading: boolean;

  // God mode settings
  isAdminDisabled: boolean;
  adminDisabled: (disable: boolean) => void;

  // Admin checks
  isEventAdmin: (orgId: string, leagueId: string, eventId: string) => boolean | undefined;
  isLeagueAdmin: (leagueId: string, orgId: string) => boolean | undefined;
  isOrgAdmin: (orgId: string, person?: Person) => boolean | undefined;
  hasAdminRightsOverPerson: (person: Person) => boolean;

  // Membership checks
  isSelf: (person: Person) => boolean;
  isOrgMember: (orgId: string) => boolean | undefined;
  isOrgMemberOfAny: () => boolean | undefined;
  isLeagueMember: (leagueId: string) => Promise<boolean>;
  areInASharedOrg: (resourceProfile: Person, requesterProfile?: Person) => boolean;

  // Capability checks
  canEditPerson: (person: Person) => boolean;
  canEditPersonDetails: (person: Person) => boolean;
  canCreateEvents: () => boolean;
  canCreateHandbooks: (orgId?: string) => boolean | undefined;
  canCreateLeagues: (orgId?: string) => boolean | undefined;
  canCreateSeasons: (orgId?: string, leagueId?: string) => boolean | undefined;

  // Getters
  getAdminOrgs: () => string[];
  getAdminLeagues: () => string[];
  getMemberLeagues: () => [string, string][];
  getMemberOrgs: () => string[];

  // Membership Management
  activeMemberships: Membership[] | undefined;
  memberships: Membership[] | undefined;
  getOrgMembership: (orgId: string, personId?: string) => Promise<Membership | null>;
  getLeagueMembership: (leagueId: string, personId?: string) => Promise<Membership | null>;
}

export const DataContext = createContext<DataContextModel>({} as DataContextModel);

export function useData(): DataContextModel {
  return useContext(DataContext);
}

export const DataProvider = ({ children }: DataProviderProps): ReactElement => {
  const { isGod, isLoggedIn, isLoggingIn, user } = useAuth();
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  const { userProfile, isUserProfileLoading, userProfileError } = useUserProfile({user: user ?? null});
  const { memberships, isMembershipsLoading, membershipsError } = useMembershipsOfPerson(userProfile ? { personId: userProfile.itemId } : { });

  // *************** Memberships ****************
  const [activeMemberships, setActiveMemberships] = useState<Membership[] | undefined>(undefined);

  // *************** Admin ****************
  const [isAdminDisabled, setIsAdminDisabled] = useState<boolean>(false);
  const [adminLeagues, setAdminLeagues] = useState<string[]>([]);
  const [memberLeagues, setMemberLeagues] = useState<[string, string][]>([]);

  useEffect(() => {
    if (isUserProfileLoading) {
      setIsUserLoading(true);
      console.log('Loading user profile');
    } else if (userProfile) {
      setIsUserLoading(false);
      console.log('User profile loaded: ' + userProfile.name);
      console.log('userProfile.orgIds: ' + userProfile.orgIds);
    } else if (userProfile === null) {
      setIsUserLoading(false);
      console.log('User profile is null.');
    } else {
      setIsUserLoading(false);
      console.log('User profile unloaded.');
    } // Your existing profile loading logic
  }, [userProfile, isLoggingIn, isLoggedIn, isUserProfileLoading, userProfileError]);

  useEffect(() => {
    const setMembershipSubgroups = () => {
      // Get active memberships
      const activeMemberships = memberships.filter((m) => m.isActive);
      setActiveMemberships(activeMemberships);

      // Set admin leagues
      setAdminLeagues(activeMemberships.filter((m) => m.leagueAdmins).flatMap((m) => m.leagueAdmins || []));
      setMemberLeagues(
        activeMemberships
        .filter((m) => m.leagues)
        .flatMap((m) => (m.leagues || []).map((leagueId) => [m.orgId, leagueId] as [string, string]))
      );
    };

    if (!isMembershipsLoading && !membershipsError && memberships && memberships.length > 0) {
      setMembershipSubgroups();
    } else if (membershipsError) {
      console.log('Error loading memberships:', membershipsError);
    } else if (isMembershipsLoading) {
      console.log('Loading memberships');
    } else if (!memberships || memberships.length === 0) {
      console.log('No memberships found');
    }
  }, [memberships, isMembershipsLoading, membershipsError]);

  // *************** Helper functions ****************
  function isSelf(profile: Person) {
    return userProfile?.itemId === profile.itemId;
  }
  
  function isOrgMember(orgId: string): boolean {
    if (!userProfile || !memberships || isAdminDisabled) {
      return false;
    }

    if (isGod || userProfile.orgIds?.includes(orgId)) {
      return true;
    }

    return false;
  }

  function isOrgMemberOfAny(): boolean | undefined {
    if (!userProfile) {
      return undefined;
    } else if (userProfile.orgIds && userProfile.orgIds.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function isOrgAdminOfAny(): boolean | undefined{
    if (!userProfile) {
      return undefined;
    } else if (userProfile.adminOrgIds && userProfile.adminOrgIds.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function isLeagueAdminOfAny(): boolean | undefined {
    if (!userProfile || memberships === undefined) {
      return undefined;
    } else if (isOrgAdminOfAny() === true) {
      return true;
    } else if (adminLeagues.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async function isLeagueMember(leagueId: string): Promise<boolean> {
    if (!userProfile || isAdminDisabled) {
      console.log('isLeagueMember: no user profile or admin disabled');
      return false;
    }

    if (isGod) {
      return true;
    }

    if (isMembershipsLoading) {
      console.log('waiting for memberships to load...');
      return new Promise((resolve) => {
        const checkMemberships = setInterval(() => {
          if (!isMembershipsLoading && memberships) {
            clearInterval(checkMemberships);
            const isMember = memberLeagues.some(([, lid]) => lid === leagueId);
            console.log('memberLeagues: ', memberLeagues);
            console.log('isMember: ', isMember);
            resolve(isMember);
          }
        }, 100);
      });
    }

    if (!memberships) {
      console.log('no memberships found');
      return false;
    }

    return isGod || memberLeagues.some(([, lid]) => lid === leagueId);
  }

  // Pending is not included in this check. The reason is that a pendingOrgId is one that is waiting for the user to accept.
  // If we allowed an admin to change their profile, an admin could add any email address they know, then alter their profile.
  // This would allow them to change the profile of any user.
  function hasAdminRightsOverPerson(person: Person) {
    return (
      isGod ||
      isSelf(person) ||
      getAdminOrgs().some((orgId) => person?.orgIds?.includes(orgId) || person?.inactiveOrgIds?.includes(orgId))
    );
  }

  function areInASharedOrg(resourceProfile: Person, requesterProfile: Person | null | undefined = userProfile): boolean {
    if (isGod || isSelf(resourceProfile)) {
      return true;
    }

    if (!resourceProfile || !requesterProfile) {
      return false;
    }

    // If the requester and resource are in the same org, they share an org!
    if (resourceProfile.orgIds && requesterProfile.orgIds) {
      if (resourceProfile.orgIds?.some((id) => requesterProfile.orgIds?.includes(id))) {
        return true;
      }
    }

    // If the requester is an admin of any of the resource's inactive orgs, they share an org!
    if (resourceProfile.inactiveOrgIds && requesterProfile.adminOrgIds) {
      if (resourceProfile.inactiveOrgIds?.some((id) => requesterProfile.adminOrgIds?.includes(id))) {
        return true;
      }
    }

    return false;
  }

  // *************** Admin ****************
  function isOrgAdmin(orgId: string, person?: Person): boolean {
    if (!person && (!userProfile || isAdminDisabled)) {
      return false;
    }

    if (person) {
      return person.adminOrgIds?.find((org) => org === orgId) ? true : false;
    }

    if (isGod || userProfile?.adminOrgIds?.find((org) => org === orgId)) {
      return true;
    }

    return false;
  }

  function isLeagueAdmin(leagueId: string, orgId: string): boolean | undefined {
    if (!userProfile) {
      return undefined;
    } else if (!memberships || isAdminDisabled) {
      return false;
    }

    return isGod || isOrgAdmin(orgId) || getAdminLeagues().includes(leagueId);
  }

  function isEventAdmin(orgId: string, leagueId: string, eventId: string): boolean | undefined {
    // placeholder. will add fancier logic for who can edit an event later.
    return isLeagueAdmin(leagueId, orgId);
  }

  function canEditPerson(profile: Person): boolean {
    return isGod || isSelf(profile) || hasAdminRightsOverPerson(profile);
  }

  function canEditPersonDetails(profile: Person): boolean {
    return isGod || isSelf(profile);
  }

  function canCreateEvents(): boolean {
    return Boolean(
      isGod || (userProfile?.adminOrgIds && (userProfile?.adminOrgIds?.length > 0 || getAdminLeagues().length > 0))
    );
  }

  function canCreateHandbooks(orgId?: string): boolean | undefined {
    if (!userProfile) {
      return undefined;
    } else if (orgId) {
      return isOrgAdmin(orgId);
    } else {
      return isOrgAdminOfAny();
    }
  }

  function canCreateLeagues(orgId?: string): boolean | undefined {
    if (!userProfile) {
      return undefined;
    } else if (orgId) {
      return isOrgAdmin(orgId);
    } else {
      return isOrgAdminOfAny();
    }
  }

  function canCreateSeasons(orgId?: string, leagueId?: string): boolean | undefined {
    if (!userProfile) {
      return undefined;
    } else if (leagueId && orgId) {
      return isLeagueAdmin(leagueId, orgId);
    } else if (orgId) {
      return isOrgAdmin(orgId);
    } else {
      return isLeagueAdminOfAny();
    }
  }

  function getAdminOrgs(): string[] {
    return userProfile?.adminOrgIds || [];
  }

  function getAdminLeagues(): string[] {
    return adminLeagues;
  }

  function getMemberOrgs(): string[] {
    return userProfile?.orgIds || [];
  }

  function getMemberLeagues(): [string, string][] {
    return memberLeagues;
  }

  function adminDisabled(disable: boolean) {
    setIsAdminDisabled(disable);
  }

  const getLeagueMembership = async (leagueId: string, personId?: string): Promise<Membership | null> => {
    const membership = memberships?.find(
      (m) => m.leagues?.includes(leagueId) && m.itemId === (personId || userProfile?.itemId)
    );
    return membership || null;
  };

  const getOrgMembership = async (orgId: string, personId?: string): Promise<Membership | null> => {
    const membership = memberships?.find((m) => m.orgId === orgId && m.itemId === (personId || userProfile?.itemId));
    return membership || null;
  };

  const values = {
    // User state
    userProfile,
    isUserLoading,
    isAdminDisabled,
    adminDisabled,

    // Getters
    getLeagueMembership,
    getOrgMembership,

    // Authorization
    isEventAdmin,
    isLeagueAdmin,
    isOrgAdmin,
    isLeagueMember,
    isOrgMember,
    isOrgMemberOfAny,
    canCreateEvents,
    canCreateHandbooks,
    canCreateLeagues,
    canCreateSeasons,
    canEditPerson,
    canEditPersonDetails,
    areInASharedOrg,
    hasAdminRightsOverPerson,
    isSelf,
    getAdminLeagues,
    getAdminOrgs,
    getMemberLeagues,
    getMemberOrgs,

    // Membership management
    activeMemberships,
    memberships,
  };

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import useLeagues from '../../dataHooks/useLeagues';
import League from '../../types/League';
import Hourglass from '../common/Hourglass';

import { LeagueListItem } from './LeagueListItem';

export default function LeagueDirectory(): ReactElement {
  const { isGod } = useAuth();
  const { userProfile } = useData();
  const { leagues, isLeaguesLoading, leaguesError } = 
    useLeagues({ 
      person: userProfile, 
      includeCatchAll: isGod,
      includeOrgAdmined: true,
      includeLeagueAdmined: true,
      includeLeagueMember: true,
    });

  if (isLeaguesLoading) {
    return <Hourglass />;
  } else if (leaguesError) {
    return <Alert severity="error">Error loading leagues {leaguesError.message}</Alert>;
  } else if (!leagues || leagues.length === 0) {
    return <Alert severity="error">No leagues</Alert>;
  } else {
    return (
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        { leagues.flatMap((league: League) => {
            return (<LeagueListItem key={league.itemId} league={league} isDividerDisplayed={true} />);
          })
        }
      </List>
    );
  }
};

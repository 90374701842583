import { Timestamp } from 'firebase/firestore';

import { Typography } from '@mui/material';
import { Stack } from '@mui/material';

import usePersonDetails from '../../dataHooks/usePersonDetails';
import Person from '../../types/Person';
import { DisplayDate } from '../../utils/utils';
import Hourglass from '../common/Hourglass';

export interface PersonDetailsViewProps {
  person: Person;
}

export function PersonDetailsView(props: PersonDetailsViewProps) {
  const { person } = props;

  const { personDetails, isPersonDetailsLoading, personDetailsError } = usePersonDetails(person.itemId);

  if (isPersonDetailsLoading) {
    return <Hourglass />;
  }

  if (personDetailsError) {
    console.error(personDetailsError);
    return <div>Error loading person details</div>;
  }

  if (!personDetails) {
    return <div>No details found</div>;
  }

  return (
    <>
      {personDetails.phone && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="label">Phone Number:</Typography>
          <Typography variant="body1">{personDetails.phone}</Typography>
        </Stack>
      )}
      {personDetails.mailingAddress && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="label">Mailing Address:</Typography>
          <Typography variant="body1">{personDetails.mailingAddress}</Typography>
        </Stack>
      )}

      {personDetails.localAddress && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="label">Local Address:</Typography>
          <Typography variant="body1">{personDetails.localAddress}</Typography>
        </Stack>
      )}
      {personDetails.birthday && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="label">Birthday:</Typography>
          <Typography variant="body1">
            {personDetails.birthday instanceof Timestamp
              ? DisplayDate(personDetails.birthday.toDate(), Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMMM D')
              : ''}
          </Typography>
        </Stack>
      )}

      {personDetails.gender && (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="label">Gender:</Typography>
          <Typography variant="body1">{personDetails.gender}</Typography>
        </Stack>
      )}
    </>
  );
}

import React, { useState } from 'react';

import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RedoIcon from '@mui/icons-material/Redo';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import UndoIcon from '@mui/icons-material/Undo';

import { Editor } from '@tiptap/react';

interface GratiRichEditorToolbarProps {
  editor: Editor | null;
  sx?: SxProps;
}

export default function GratiRichEditorToolbar(props: GratiRichEditorToolbarProps) {
  const { editor, sx } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSizeChange = (size: string) => {
    if (!editor) return;

    editor.chain().focus();

    switch (size) {
      case 'caption':
        editor.chain().focus().setNode('paragraph', { class: 'caption' }).run();
        break;
      case 'normal':
        editor.chain().focus().setParagraph().run();
        break;
      case 'h5':
        editor.chain().focus().toggleHeading({ level: 5 }).run();
        break;
      case 'h3':
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      default:
        break;
    }

    handleMenuClose();
  };

  if (!editor) return null;

  return (
    <Stack direction="row" sx={sx}>
      <ToggleButtonGroup size="small" sx={{ padding: 0, margin: 0 }}>
        <ToggleButton value="undo" onClick={() => editor.chain().focus().undo().run()}>
          <UndoIcon />
        </ToggleButton>
        <ToggleButton value="redo" onClick={() => editor.chain().focus().redo().run()}>
          <RedoIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup size="small" sx={{ padding: 0, margin: 0 }}>
        <ToggleButton 
          value="textSize"
          onClick={handleMenuClick}
        >
          <TextFieldsIcon />
        </ToggleButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => handleSizeChange('h3')}>
            <Typography variant="h3">Huge</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSizeChange('h5')}>
            <Typography variant="h5">Large</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSizeChange('normal')}>
            <Typography variant="body1">Normal</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSizeChange('caption')}>
            <Typography variant="caption">Small</Typography>
          </MenuItem>
        </Menu>
        <ToggleButton
          value="orderedList"
          selected={editor.isActive('orderedList')}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <FormatListNumberedIcon />
        </ToggleButton>
        <ToggleButton
          value="bulletList"
          selected={editor.isActive('bulletList')}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <FormatListBulletedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider orientation="vertical" flexItem />
      <ToggleButtonGroup size="small">
        <ToggleButton
          value="bold"
          selected={editor.isActive('bold')}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton
          value="italic"
          selected={editor.isActive('italic')}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          value="strike"
          selected={editor.isActive('strike')}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        >
          <StrikethroughSIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

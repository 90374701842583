import { ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueEditor from '../components/league/LeagueEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useOrg from '../dataHooks/useOrg';
import { useLeagueMutators } from '../dataMutators/useLeagueMutators';
import DefaultObjects from '../types/DefaultObjects';
import League from '../types/League';

export default function LeagueCreatePage(): ReactElement {
  const orgId = useParams().orgId;
  const navigate = useNavigate();
  const { setPageName } = useApp();
  const { canCreateLeagues, userProfile } = useData();
  const { addLeague } = useLeagueMutators();
  const { org, isOrgLoading, orgError } = useOrg(orgId);

  useEffect(() => {
    if (!orgId) {
      setPageName('Create League');
    } else if (org) {
      setPageName(`Create ${org.name} League`);
    }
  }, [setPageName, orgId, isOrgLoading, org]);

  const createNewLeague = (): League => {
    return {
      ...DefaultObjects.League,
      creatorId: userProfile?.itemId || '',
      isCatchAll: false,
      orgName: org?.name || '',
      orgId: org?.itemId || '',
    };
  };

  const handleSubmit = (league: League) => {
    if (league) {
      addLeague(league);
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validateUser = () => {
    return canCreateLeagues(orgId) ?? false;
  };

  return (
    <GratiPageWrapper validateUser={validateUser} isContentLoading={isOrgLoading}>
      <>
        {org && (
          <Box>
            <LeagueEditor league={createNewLeague()} isNew={true} onCancel={handleCancel} onSubmit={handleSubmit} />
          </Box>
        )}
        {orgError && (
          <Box>
            <Typography component="body">Error loading org {orgError.message}</Typography>
          </Box>
        )}
      </>
    </GratiPageWrapper>
  );
}

import { doc, deleteDoc, getDoc, Timestamp, setDoc } from "firebase/firestore";

import { useData } from "../contextProviders/DataProvider";
import { createCollection, firestore } from "../firebase";
import Application from "../types/Application";

export const useApplicationMutators = () => {
  const applicationsCol = createCollection<Application>('application');
  const { userProfile } = useData();

  const addApplication = async (
    orgId: string,
    name: string,
    mailingAddress: string,
    email: string,
    ghin: string,
    phone: string,
    isApproved = false,
    isTest = false
  ): Promise<void> => {
    try {
      // Get org details
      const orgRef = doc(firestore, 'org/' + orgId);
      const orgDoc = await getDoc(orgRef);
      const orgName = orgDoc.data()?.name || '';

      // Create application
      const applicationRef = doc(applicationsCol);
      const application: Application = {
        createTime: Timestamp.now(),
        creatorId: userProfile?.itemId || '',
        itemId: applicationRef.id,
        datetime: Timestamp.now(),
        email,
        details: {
          itemId: applicationRef.id,
          createTime: Timestamp.now(),
          creatorId: userProfile?.itemId || '',
          name,
          ...(mailingAddress && { mailingAddress }),
          ...(phone && { phone }),
        },
        ghin,
        isActive: false,
        level: 'member',
        name,
        isTest,
        orgId,
        orgName,
        isApproved,
        isRejected: false,
        isPaid: false,
      };

      await setDoc(applicationRef, application);
    } catch (error) {
      console.error('Error adding application:', error);
      // TODO: Consider proper error handling since this can be called by anyone
    }
  };

  const deleteApplication = async (appId: string): Promise<void> => {
    if (!appId) {
      console.error('No application ID provided');
      return;
    }

    try {
      const applicationRef = doc(applicationsCol, appId);
      await deleteDoc(applicationRef);
    } catch (error) {
      console.error('Error deleting application:', error);
    }
  };

  const updateApplication = async (appId: string, isApproved: boolean, isRejected: boolean) => {
    if (!appId) {
      console.error('No application ID provided');
      return;
    }

    try {
      // reference to the document to update
      const applicationRef = doc(applicationsCol, appId);

      // Update the value of the application
      await setDoc(applicationRef, { isApproved: isApproved, isRejected: isRejected }, { merge: true });
    } catch (error) {
      alert(error);
    }
  };

  return {
    addApplication,
    deleteApplication,
    updateApplication,
  };
};
import { getDoc } from "firebase/firestore";
import { doc, collection, setDoc, Timestamp } from "firebase/firestore";

import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import Invitation from "../types/Invitation";

export const useInvitationMutators = () => {
  const { userProfile } = useData();

  const addInvitation = async (
    orgId: string,
    name: string,
    email: string,
    isTest = false,
    personId?: string
  ): Promise<void> => {
    if (!userProfile?.itemId) {
      console.error('User profile not found');
      return;
    }

    try {
      // Get org name
      const orgRef = doc(firestore, 'org/' + orgId);
      const orgDoc = await getDoc(orgRef);
      const orgName = orgDoc.data()?.name || '';

      // Create invitation
      const invitationRef = doc(collection(firestore, `org/${orgId}/invitation`));
      const invitation: Invitation = {
        createTime: Timestamp.now(),
        creatorId: userProfile.itemId,
        itemId: invitationRef.id,
        email,
        isTest,
        name,
        orgId,
        orgName,
        ...(personId && { personId }),
      };

      await setDoc(invitationRef, invitation);
    } catch (error) {
      console.error('Error adding invitation:', error);
    }
  };

  return {
    addInvitation,
  };
};
import { memo, ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Event from '../../types/Event';

type EventTypeEditorProps = {
  isGolf: boolean;
  isMultiDay: boolean;
  onGolfChange: (isGolf: boolean) => void;
  onMultiDayChange: (isMultiDay: boolean) => void;
  onUpdate: (updates: Partial<Event>) => void;
}

const EventTypeEditor = memo(function EventTypeEditor(props: EventTypeEditorProps): ReactElement {
  const { isGolf, isMultiDay, onGolfChange, onMultiDayChange, onUpdate } = props;

  return (
    <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <ToggleButtonGroup
        color="primary"
        value={isGolf ? "golf" : "social"}
        exclusive
        onChange={(_, value) => {
          if (value) {
            onGolfChange(value === "golf");
            onUpdate({
              isGolf: value === "golf"
            });
          }
        }}
        size="small"
        aria-label="Event Type"
      >
        <ToggleButton value="golf">Golf</ToggleButton>
        <ToggleButton value="social">Social</ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        color="primary"
        value={isMultiDay ? "multiday" : "oneday"}
        exclusive
        onChange={(_, value) => {
          if (value) {
            onMultiDayChange(value === "multiday");
          }
        }}
        size="small"
        aria-label="Is Multi-day"
      >
        <ToggleButton value="oneday">Single Day</ToggleButton>
        <ToggleButton value="multiday">Multi-day</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
});

export default EventTypeEditor;
import { ReactElement, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { getDownloadURL, ref, UploadResult } from 'firebase/storage';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SaveIcon from '@mui/icons-material/Save';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import useOrgsWithAdminRights from '../../dataHooks/useOrgsWithAdminRights';
import League from '../../types/League';
import Season from '../../types/Season';
import { CompetitiveLevels } from '../../utils/Compete';
import GratiImageUpload from '../common/GratiImageUpload';
import Hourglass from '../common/Hourglass';
import OrgSelector from '../org/OrgSelector';
import SeasonsEditor from '../season/SeasonsEditor';

export type LeagueEditorProps = { 
  league: League; 
  isNew?: boolean;
  onCancel: () => void;
  onDelete?: (league: League) => void;
  onSubmit: (league: League) => void;
  onSeasonAdd?: (season: Season) => void;
  onSeasonDelete?: (season: Season) => void;
  onSeasonChange?: (season: Partial<Season>) => void;
};

export default function LeagueEditor(props: LeagueEditorProps): ReactElement {
  const { isNew = false, onCancel, onDelete, onSubmit, onSeasonAdd, onSeasonDelete, onSeasonChange } = props;

  const { imagesRef, isGod } = useAuth();
  const { isLeagueAdmin, isOrgAdmin } = useData();

  const [competitiveHover, setCompetitiveHover] = useState(-1);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [league, setLeague] = useState<League>(props.league);
  const [leagueLogoUrl, setLeagueLogoUrl] = useState('');

  const { orgs, isOrgsLoading, orgsError } = useOrgsWithAdminRights();
  
  // Get org pairs from orgs user can admin
  const orgPairs = useMemo(() => {
    if (!isNew || !orgs) return [];
    return orgs.map(org => ({
      orgId: org.itemId,
      orgName: org.name
    }));
  }, [orgs, isNew]);

  useEffect(() => {
    if (league && league.logo) {
      getDownloadURL(ref(imagesRef, league.logo))
        .then((url) => {
          setLeagueLogoUrl(url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [imagesRef, league, league?.logo]);

  const handleImageUpdated = (snapshot: UploadResult | null) => {
    if (league) {
      setLeague({ ...league, logo: snapshot ? snapshot.ref.name : "" });
    }
    console.log('Uploaded a blob or file!');
  };

  /*
  const handleSeasonAdded = (parentLeague: League) => {
    addSeason({
      endTime: CurrentDate(),
      leagueId: parentLeague.itemId,
      leagueName: parentLeague.name,
      orgId: parentLeague.orgId,
      orgName: parentLeague.orgName,
      name: '',
      startTime: CurrentDate(),
    });
  };
  */

  const handleSubmit = () => {
    onSubmit(league);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    setDeleteConfirm(false);
    if (onDelete) {
      onDelete(league);
    }
  };

  if (isNew && isOrgsLoading) {
    return <Hourglass />;
  }

  if (isNew && orgsError) {
    console.error('Error loading organizations:', orgsError);
  }

  if (league) {
    if (isOrgAdmin(league.orgId) || isLeagueAdmin(league.itemId, league.orgId)) {
      return ( 
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Stack spacing={2}>
            <Typography variant="headline">
              {isNew ? 'Create League' : 'Edit League'}
            </Typography>
            <TextField
              id="name"
              label="League Name"
              aria-label="League Name"
              aria-required="true"
              defaultValue={league.name}
              onChange={({ target }) => setLeague({ ...league, name: target.value })}
            />
            <GratiImageUpload
              onImageUpdated={handleImageUpdated}
              storageImageFileName={league.itemId + '-logo'}
              label="Logo"
            />
            <Stack direction="row">
              <Typography variant="body1" sx={{pr: 2}}>Competition </Typography>
              <Box
                sx={{
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Rating
                  name="hover-feedback"
                  aria-label="Competitive Level"
                  value={league.competitionLevel}
                  precision={1}
                  icon={<EmojiEventsIcon fontSize="inherit" />}
                  getLabelText={(value: number) => `$Competitive Level ${value}`}
                  onChange={(_event: SyntheticEvent, newValue: number | null) => {
                    setLeague({ ...league, competitionLevel: newValue ? newValue : 0 });
                  }}
                  onChangeActive={(_event: SyntheticEvent, competitiveHover: number) => {
                    setCompetitiveHover(competitiveHover);
                  }}
                  emptyIcon={<EmojiEventsIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                {league.competitionLevel !== null && (
                  <Box sx={{ ml: "1rem" }}>
                    {CompetitiveLevels[competitiveHover !== -1 ? competitiveHover : league.competitionLevel]}
                  </Box>
                )}
              </Box>
            </Stack>
            {isNew ?
              <OrgSelector 
                value={league.orgId} 
                orgPairs={orgPairs}
                handleChange={(orgPair) => 
                  setLeague({ ...league, "orgId": orgPair.orgId, "orgName": orgPair.orgName })} /> :
              <>
                <Typography variant="label" component="span">Organization</Typography>
                <Typography variant="body1" component="span">{league.orgName}</Typography>
              </>
            }
            { (onSeasonAdd || onSeasonChange || onSeasonDelete) &&
              <SeasonsEditor 
                league={league} 
                isNewLeague={isNew} 
                onAdd={onSeasonAdd} 
                onDelete={onSeasonDelete} 
                onChange={onSeasonChange}/>
            }
          </Stack>

          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={8}>
            <Button size="medium" variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
              Save
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            {isOrgAdmin(league.orgId) && (
              <>
                <Button
                  size="medium"
                  variant="text"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete
                </Button>
                {deleteConfirm && (
                  <span>
                    <Button size="large" onClick={() => handleDelete()}>
                      Confirm
                    </Button>
                    <Button size="large" onClick={() => setDeleteConfirm(false)}>
                      Cancel
                    </Button>
                  </span>
                )}
              </>
            )}
          </Stack>
          {isGod && league &&
            <Stack 
              direction="row" 
              justifyContent="flex-start" 
              alignItems="center" spacing={2} 
              sx={{ backgroundColor: "tertiary.container", color: "tertiary.onContainer", mt: 2, p: 1 }}
            >
              <FormGroup>
                <FormControlLabel
                  id="isTest"
                  value="top"
                  aria-label="Is a test league"
                  aria-required="false"
                  label="Test?"
                  control={
                    <Switch
                      id={'isTest' + league.isTest}
                      checked={league.isTest}
                      color="secondary"
                      onChange={(e) => setLeague({ ...league, isTest: e.target.checked })}
                    />
                  }
                />
              </FormGroup>
            </Stack>
          }
        </Box>
      );
    } else {
      return (
        <Typography variant="headline">
          Not authorized to edit this league
        </Typography>
      );
    }
  } else {
    return <></>;
  }
}
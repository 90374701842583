import { useMemo } from 'react';

import { and, collection, Query, query, where, orderBy, collectionGroup, or } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Handbook from '../types/Handbook';

interface UseHandbooksProps {
  orgId?: string;
}

export default function useHandbooks(props: UseHandbooksProps) {
  const { orgId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMember, getAdminOrgs, getMemberOrgs } = useData();

  const adminOrgs = useMemo(() => getAdminOrgs(), [getAdminOrgs]);
  const memberOrgs = useMemo(() => getMemberOrgs(), [getMemberOrgs]);

  const handbooksQuery = useMemo(() => {
    if (!userProfile || memberOrgs.length === 0) return null;

    if (orgId || memberOrgs.length === 1) {
      const queryOrgId = orgId || memberOrgs[0];
      console.log('queryOrgId', queryOrgId);
      return query(
        collection(firestore, 'org', queryOrgId, 'handbook') as Query<Handbook>,
        and(
          where('orgId', '==', queryOrgId),
          where('isDeleted', '==', false),
          where('isTest', '==', false)
        ),
        orderBy('name')
      );
    } else {
      const deletedConditions = [];
      const orgConditions = [];
      const testConditions = [];

      if (isGod) {
        testConditions.push(where('isTest', 'in', [true, false]));
        deletedConditions.push(where('isDeleted', 'in', [true, false]));
      } else {
        testConditions.push(where('isTest', '==', false));
        deletedConditions.push(where('isDeleted', '==', false));
      }

      if (adminOrgs.length > 0) {
        const nonAdminOrgs = memberOrgs?.filter((org) => !adminOrgs.includes(org));
        if (nonAdminOrgs?.length > 0) {
          orgConditions.push(
            or(
              and(
                where('orgId', 'in', nonAdminOrgs),
                where('isPublished', '==', true)
              ),
              where('orgId', 'in', adminOrgs),
            )
          )
        } else {
          orgConditions.push(
            where('orgId', 'in', adminOrgs)
          )
        }
      } else {
        if (memberOrgs?.length > 0) {
          orgConditions.push(
            and(
              where('orgId', 'in', memberOrgs),
              where('isPublished', '==', true)
            )
          )
        }
      }

      return query(
        collectionGroup(firestore, 'handbook') as Query<Handbook>,
        and(...deletedConditions, ...testConditions,...orgConditions),
        orderBy('name')
      );
    }
  }, [userProfile, adminOrgs, memberOrgs, orgId, isGod]);

  const [handbooks, isHandbooksLoading, handbooksError] = useCollectionData<Handbook>(handbooksQuery);

  if (!isUserLoading && !userProfile && !isHandbooksLoading && !handbooksError) {
    return {
      handbooks: null,
      isHandbooksLoading: false,
      handbooksError: new Error('User must be logged in to view handbooks'),
    };
  }

  if (!isUserLoading && !isHandbooksLoading && !handbooksError && !isGod && orgId && !isOrgMember(orgId)) {
    return {
      handbooks: null,
      isHandbooksLoading: false,
      handbooksError: new Error('Not authorized to view these handbooks'),
    };
  }

  return {
    handbooks: handbooks || null,
    isHandbooksLoading: isHandbooksLoading || isUserLoading,
    handbooksError,
  };
}

import { ReactElement, useEffect } from 'react';

import { Timestamp } from 'firebase/firestore';

import { useNavigate, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventAttendees from '../components/event/EventAttendees';
import EventCard from '../components/event/EventCard';
import EventEditor from '../components/event/EventEditor';
import EventRegEditor from '../components/event/EventRegEditor';
import EventTeeSheet from '../components/event/EventTeeSheet';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useEvent from '../dataHooks/useEvent';
import { useEventMutators } from '../dataMutators/useEventMutators';
import Event from '../types/Event';
import { DisplayDate, DisplayDates } from '../utils/utils';
import { IsEventUpcoming } from '../utils/utils';
interface EventPageProps {
  isEditMode?: boolean;
}

export default function EventPage(props: EventPageProps): ReactElement {
  const isEditMode = props.isEditMode ?? false;
  const { setPageName, updateActions } = useApp();
  const { isLeagueAdmin, isOrgAdmin } = useData();
  const { updateEvent } = useEventMutators();
  const { orgId, eventId } = useParams();
  const navigate = useNavigate();
  const { event, isEventLoading, eventError } = useEvent({ orgId, eventId });
  const location = useLocation();

  useEffect(() => {
    const handleEditClick = () => {
      if (event) {
        navigate('/event-edit/' + event.orgId + '/' + event.itemId, { replace: true });
      }
    };

    if (event) {
      const dateText =
        event.days.length > 1
          ? DisplayDates(
              event.days.map((day) => (day.datetime as Timestamp).toDate()),
              event.timezone
            )
          : DisplayDate((event.days[0].datetime as Timestamp).toDate(), event.timezone, 'M/D');

      setPageName(dateText + ' ' + event.name);
      if (isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId, event.orgId)) {
        updateActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    } else if (eventError) {
      setPageName(eventError.message);
    }
  }, [event, eventError, isLeagueAdmin, isOrgAdmin, updateActions, setPageName, navigate]);

  const handleSubmit = (event: Event) => {
    const parentPath = location.state?.from;

    if (event) {
      updateEvent(event);
    }

    if (parentPath === '/events') {
      const url = new URL(parentPath, window.location.origin);
      url.searchParams.set('eventId', event.itemId);
      navigate(url.pathname + url.search, { replace: true });
    } else {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate('/event/' + orgId + '/' + eventId, { replace: true });
  };

  const handleCreateTeeSheet = () => {
    if (event) {
      try {
        updateEvent({ itemId: event.itemId, leagueId: event.leagueId, orgId: event.orgId, isTeeSheetCreated: true });
      } catch (error) {
        console.log('Error setting isTeeSheetCreated to true: ' + error);
      }
    } else {
      console.log('Error setting isTeeSheetCreated to true: event is null');
    }
  };

  const Attendees = (): ReactElement => {
    if (event) {
      if (event.isGolf && event.isTeeSheetCreated) {
        return <EventTeeSheet eventId={event.itemId} orgId={event.orgId} />;
      } else {
        return (
          <Stack spacing={2} sx={{ display: 'flex', alignItems: 'flex-start', my: 2 }}>
            <EventAttendees event={event} isEditable={isLeagueAdmin(event.leagueId, event.orgId)} />
            {isLeagueAdmin(event.leagueId, event.orgId) && event.isGolf && (
              <Button
                type="button"
                key="formEdit"
                size="medium"
                variant="contained"
                color="secondary"
                onClick={() => handleCreateTeeSheet()}
              >
                Create Tee Sheet
              </Button>
            )}
          </Stack>
        );
      }
    } else {
      return <></>;
    }
  };

  const EventDetails = (): ReactElement => {
    if (event) {
      return (
        <Grid container spacing={2} sx={{ justifyContent: 'flex-start', backgroundColor: 'surface.main' }}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <EventCard event={event} variant="none" isClickable={false} />
            {event.isGolf && IsEventUpcoming(event) && !event.isTeeSheetCreated && (
              <EventRegEditor
                event={event}
                isMemberMember={event.isMemberMember ? true : false}
                isMemberGuest={event.isMemberGuest ? true : false}
              />
            )}
          </Grid>
          <Grid container size={{ xs: 12, sm: 6 }} sx={{ pl: 1 }}>
            <Stack direction="column" sx={{ width: 1 }}>
              <Divider variant="fullWidth" sx={{ display: { sm: 'none' } }} />
              <Attendees />
            </Stack>
          </Grid>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  return (
    <GratiPageWrapper isContentLoading={isEventLoading && !event}>
      <>
        {event && (
          <>
            {isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId, event.orgId) ? (
              <>
                {isEditMode ? (
                  <EventEditor
                    event={event}
                    isNewEvent={false}
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                  />
                ) : (
                  <EventDetails />
                )}
              </>
            ) : (
              <EventDetails />
            )}
          </>
        )}
      </>
    </GratiPageWrapper>
  );
}

import { doc, collection, writeBatch, updateDoc, deleteDoc, arrayUnion } from 'firebase/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { auth, firestore } from '../firebase';
import Org from '../types/Org';
import { CurrentDateTime } from '../utils/utils';

export const useOrgMutators = () => {
  const { isGod } = useAuth();
  const { userProfile } = useData();

  const addOrg = async (
    org: Org,
    isAdmin: boolean,
    isTest = false,
    isUserProfileUpdated = true
  ): Promise<Org | null> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return null;
    }

    if (!isGod) {
      console.error('Permission denied. User id:', userProfile.itemId);
      return null;
    }

    if (!org) {
      console.error('No org provided');
      return null;
    }

    try {
      const batch = writeBatch(firestore);

      // Create org
      const orgRef = doc(collection(firestore, 'org'));
      const newOrg = {
        ...org,
        creatorId: userProfile.itemId,
        createTime: CurrentDateTime(),
        itemId: orgRef.id,
        isActive: true,
        isTest: isTest,
      };
      batch.set(orgRef, newOrg);

      if (isUserProfileUpdated) {
        // Add membership to batch
        const membershipRef = doc(firestore, `org/${orgRef.id}/membership/${userProfile.itemId}`);
        batch.set(membershipRef, {
          isActive: true,
          isAdmin: isAdmin,
          isMember: true,
          itemId: userProfile.itemId,
          name: userProfile.name,
          orgId: orgRef.id,
          createTime: CurrentDateTime(),
          creatorId: userProfile.itemId,
        });

        // Update profile in batch
        const profileRef = doc(firestore, 'profile', userProfile.itemId);
        batch.update(profileRef, {
          orgIds: arrayUnion(orgRef.id),
        });
      }

      await batch.commit();
      return newOrg;
    } catch (error) {
      console.error('Error adding org:', error);
      return null;
    }
  };

  const deleteOrg = async (org: Org, isPermanent = false): Promise<void> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return;
    }

    if (!isGod) {
      console.error('Permission denied. User id:', userProfile.itemId);
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found:', org.itemId);
      return;
    }

    try {
      const orgRef = doc(firestore, 'org/' + org.itemId);

      if (isPermanent) {
        await deleteDoc(orgRef);
      } else {
        await updateDoc(orgRef, { ...org, isActive: false });
      }
    } catch (error) {
      console.error('Error deleting org:', error);
    }
  };

  const updateOrg = async (org: Partial<Org>): Promise<void> => {
    if (!userProfile) {
      console.error('User profile not found for auth id:', auth.currentUser?.uid);
      return;
    }

    if (!isGod) {
      console.error('Permission denied. User id:', userProfile.itemId);
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found:', org.itemId);
      return;
    }

    try {
      const orgRef = doc(firestore, 'org/' + org.itemId);
      await updateDoc(orgRef, { ...org });
    } catch (error) {
      console.error('Error updating org:', error);
    }
  };

  return {
    addOrg,
    deleteOrg,
    updateOrg,
  };
};
import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import useSeasons from '../../dataHooks/useSeasons';
import { CompDenomination } from '../../types/Competition';
import League from '../../types/League';
import Result from '../../types/Result';
import Score from '../../types/Score';
import Season from '../../types/Season';
import { GratiScorecard } from '../common/GratiScorecard';
import Hourglass from '../common/Hourglass';

type LeagueResultsProps = {
  league: League;
  memberId?: string;
};

export default function LeagueResults(props: LeagueResultsProps): ReactElement {
  const { league, memberId } = props;

  const { seasons, isSeasonsLoading, seasonsError } = useSeasons({ leagueId: league.itemId, orgId: league.orgId });

  function filterPlayers(score: Score): boolean {
    if (score) {
      return memberId ? score.playerId === memberId : true;
    } else {
      return false;
    }
  }

  const Standings = (props: { season: Season }) => {
    const season = props.season;
    console.log(season);
    if (season.standings && season.standings.length > 0) {
      return (
        <Stack spacing={1} sx={{ display: 'flex', justifyContent: 'flex-start', flexGrow: 0 }}>
          {season.standings.map(
            (standing: Result) =>
              standing.scores && (
                <GratiScorecard
                  key={standing.competition.name}
                  title={standing.competition.name}
                  columns={{
                    fields:
                      standing.competition.denomination === 'stroke' as CompDenomination
                        ? '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18'
                        : 'Cash' as CompDenomination,
                    fieldLabels:
                      standing.competition.denomination === 'stroke' as CompDenomination
                        ? '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18'
                        : 'Cash' as CompDenomination,
                    holePars:
                      standing.competition.denomination === 'stroke' as CompDenomination
                        ? standing.courseParByHole
                        : undefined,
                  }}
                  rows={standing.scores}
                  filterFunction={filterPlayers}
                  denomination={standing.competition.denomination}
                />
              )
          )}
        </Stack>
      );
    } else {
      return <Box key={season.itemId}></Box>;
    }
  };

  if (isSeasonsLoading) {
    return <Hourglass />;
  } else if (seasonsError) {
    return <Alert severity="error">Error loading seasons: {seasonsError.message}</Alert>;
  } else if (seasons) {
    return (
      <Container disableGutters className="BodyContainer" sx={{ flexGrow: 1 }}>
        {seasons.map((season) => (
          <Standings key={'standings' + season.itemId} season={season} />
        ))}
      </Container>
    );
  } else {
    return <> </>;
  }
}

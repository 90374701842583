import { useMemo } from 'react';

import { and, collection, CollectionReference, or, orderBy, Query } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { OrgMembershipStatus } from '../enums/OrgMembershipStatus';
import { firestore } from '../firebase';
import Person from '../types/Person';

interface UsePersonsProps {
  isActiveUser?: boolean;
  orgMembershipStatus?: OrgMembershipStatus;
  orgId?: string;
}

export default function usePersons(props: UsePersonsProps) {
  const { isActiveUser, orgMembershipStatus, orgId } = props;
  const { isGod } = useAuth();
  const { isOrgMember, userProfile, getAdminOrgs, getMemberOrgs, isUserLoading } = useData();

  // Memoize these values
  const adminOrgs = useMemo(() => getAdminOrgs(), [getAdminOrgs]);
  const memberOrgs = useMemo(() => getMemberOrgs(), [getMemberOrgs]);

  console.log('usePersons input:', {
    isActiveUser,
    orgMembershipStatus,
    orgId,
    isGod,
    userProfile,
  });

  const getProfilesQuery = () => {
    const activeConditions = [];
    const orgConditions = [];
    const testConditions = [];
    const orgIds: string[] = [];
    // We are getting profiles for a specific org.

    if (orgId) {
      (isGod || isOrgMember(orgId)) && orgIds.push(orgId);
    } else {
      memberOrgs?.length > 0 && orgIds.push(...getMemberOrgs());
      console.log('****orgIds: ', orgIds);
    }

    if (isGod && isActiveUser === false) {
      activeConditions.push(where('isActive', '==', false));
    } else {
      activeConditions.push(where('isActive', '==', true));
    }

    if (isGod) {
      testConditions.push(where('isTest', 'in', [true, false]));
    } else {
      testConditions.push(where('isTest', 'in', [false, null]));
    }

    switch (orgMembershipStatus) {
      case OrgMembershipStatus.INACTIVE:
        (isGod || orgIds.every((org) => adminOrgs.includes(org))) &&
          orgConditions.push(where('inactiveOrgIds', 'array-contains-any', orgIds));
        break;
      case OrgMembershipStatus.ALL:
        (isGod || orgIds.every((org) => adminOrgs.includes(org))) ?
          orgConditions.push(
            or(where('orgIds', 'array-contains-any', orgIds), where('inactiveOrgIds', 'array-contains-any', orgIds))
          ) :
          orgConditions.push(where('orgIds', 'array-contains-any', orgIds));
        break;
      default:
        orgConditions.push(where('orgIds', 'array-contains-any', orgIds));
        break;
    }

    return query(
      collection(firestore, 'profile') as CollectionReference<Person>,
      and(...activeConditions, ...orgConditions, ...testConditions),
      orderBy('name')
    ) as Query<Person>;
  };

  // Memoize the query
  const qProfiles = useMemo(() => {
    if (isUserLoading && !userProfile) {
      return null;
    }

    return getProfilesQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveUser, orgMembershipStatus, orgId, isGod, adminOrgs, memberOrgs]);

  const [persons, isPersonsLoading, personsError] = useCollectionData<Person>(qProfiles);

  if (!isUserLoading && !userProfile && !isPersonsLoading && !personsError) {
    return {
      persons: null,
      isPersonsLoading: false,
      personsError: new Error('User must be logged in to view persons'),
    };
  }

  return {
    persons: persons || null,
    isPersonsLoading: isPersonsLoading || isUserLoading,
    personsError,
  };
}

import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventEditor from '../components/event/EventEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import { useEventMutators } from '../dataMutators/useEventMutators';
import DefaultObjects from '../types/DefaultObjects';
import Event from '../types/Event';
import Person from '../types/Person';
export default function EventCreatePage(): ReactElement {
  const { orgId, leagueId, seasonId } = useParams();

  const { setPageName } = useApp();
  const { getAdminLeagues, getAdminOrgs, isLeagueAdmin, isOrgAdmin, userProfile } = useData();
  const { addEvent } = useEventMutators();
  const navigate = useNavigate();

  useEffect(() => {
    setPageName('Create Event');
  }, [setPageName]);

  const validateUser = (userProfile: Person | null | undefined): boolean => {
    if (userProfile) {
      if (orgId) {
        if (leagueId) {
          return isLeagueAdmin(leagueId, orgId) ?? false;
        } else {
          return isOrgAdmin(orgId) ?? false;
        }
      } else {
        return getAdminOrgs().length > 0 || getAdminLeagues().length > 0 ? true : false;
      }
    } else {
      return false;
    }
  };

  const handleSubmit = (event: Event) => {
    if (event) {
      console.log('handleSubmit: ', event);
      addEvent(event);
    }
    navigate(-1);
  };

  const createNewEvent = (): Event => {
    return {
      ...DefaultObjects.Event,
      creatorId: userProfile?.itemId ?? '',
      leagueId: leagueId ?? '',
      seasonId: seasonId ?? '',
      orgId: orgId ?? '',
    };
  };

  const event = createNewEvent();

  return (
    <GratiPageWrapper isAuthRequired={true} validateUser={validateUser}>
      <EventEditor event={event} isNewEvent={true} handleSubmit={handleSubmit} />
    </GratiPageWrapper>
  );
}

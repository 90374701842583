import { ReactElement } from 'react';

import { ref } from 'firebase/storage';

import { Link } from 'react-router-dom';

import { useDownloadURL } from 'react-firebase-hooks/storage';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MenuBookIcon from '@mui/icons-material/MenuBook';

import { imagesRef } from '../../firebase';
import Handbook from '../../types/Handbook';

interface HandbookListItemProps {
  handbook: Handbook;
  isClickable?: boolean;
  isDividerDisplayed?: boolean;
  renderActions?: ReactElement | null;
}

export default function HandbookListItem(props: HandbookListItemProps): ReactElement {
  const { handbook, isClickable = true, isDividerDisplayed = true, renderActions } = props;
  const [imageUrl, isImageLoading] = useDownloadURL(handbook?.logo ? ref(imagesRef, handbook.logo) : null);
  const version = `v${
    handbook.history?.length ? Math.max(...handbook.history.map((h) => h.revision)).toString() : '1'
  }${handbook.isDeleted ? ' Deleted' : !handbook.isPublished ? ' Unpublished' : ''}`;

  const StyledName = (): ReactElement => {
    return (
      <Stack
        direction="column"
        spacing={0}
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'space-around',
          width: '100%',
          lineHeight: 1,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="h6">{handbook.name}</Typography>
          <Typography variant="overline">{version}</Typography>
        </Stack>
      </Stack>
    );
  };

  const StyledDetails = (): ReactElement => {
    return (
      <Stack
        direction="column"
        spacing={0}
        sx={{
          alignItems: 'flex-start',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Typography variant="body1" sx={{ fontFamily: { xs: 'Roboto Condensed', sm: 'roboto' } }}>
          {handbook.description}
        </Typography>
      </Stack>
    );
  };

  return (
    <ListItem
      divider={isDividerDisplayed}
      className={`${handbook.isTest ? 'test' : ''} ${!handbook.isPublished ? 'unpublished' : ''}`}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Box
        component={isClickable ? Link : 'div'}
        to={isClickable ? `/handbook/${handbook.orgId}/${handbook.itemId}` : undefined}
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          minWidth: 0,
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <ListItemAvatar sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <Avatar alt={handbook.name} src={!isImageLoading && imageUrl ? imageUrl : ''}>
            <MenuBookIcon color={handbook.isPublished ? 'primary' : 'disabled'} />
          </Avatar>
        </ListItemAvatar>
        <ListItemAvatar sx={{ color: 'surface.contrastText' }}>
          <StyledName />
          <StyledDetails />
        </ListItemAvatar>
      </Box>
      {renderActions && (
        <ListItemText>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{renderActions}</Box>
        </ListItemText>
      )}
    </ListItem>
  );
}

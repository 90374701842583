import { ReactElement, useState, memo, useCallback } from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";

import { useData } from "../../contextProviders/DataProvider";
import { useMembershipMutators } from "../../dataMutators/useMembershipMutators";
import { OrgMembershipStatus } from "../../enums/OrgMembershipStatus";
import Membership from "../../types/Membership";
import Org from "../../types/Org";
import Person from "../../types/Person";
import PersonDirectory from "../person/PersonDirectory";

export interface OrgMemberDirectoryProps {
  org: Org;
  orgMembershipStatus?: OrgMembershipStatus;
}

export const OrgMemberDirectory = memo((props: OrgMemberDirectoryProps) : ReactElement => {
  const { org, orgMembershipStatus = OrgMembershipStatus.ALL } = props;
  const { isOrgAdmin, isSelf } = useData();
  const { deactivateOrgMembership, deleteOrgMembership, addOrgMembership, updateMembership } = useMembershipMutators();
  const [openId, setOpenId] = useState<string | null>(null);
  const [confirmingId, setConfirmingId] = useState<string | null>(null);

  const getOrgMembershipStatus = (person: Person, orgId: string) => {
    if (person.orgIds?.includes(orgId)) {
      return 'active';
    }
    if (person.inactiveOrgIds?.includes(orgId)) {
      return 'inactive';
    }
    return null;
  };

  const renderActions = useCallback((person: Person) => {
    const isOpen = openId === person.itemId;
    const isConfirming = confirmingId === person.itemId;

    const handleDisablePerson = (personId: string) => {
      if (org) {
        deactivateOrgMembership(org, personId);
      }
      console.log("handleDisablePerson", personId);
    }
  
    const handleRemovePerson = (personId: string) => {
      if (org) {
        deleteOrgMembership(org, personId);
      }
      console.log("handleRemovePerson", personId);
    }

    const handleAdminChange = (person: Person, isAdmin: boolean) => {
      if (org) {
        const partialMembership: Partial<Membership> = {
          orgId: org.itemId,
          itemId: person.itemId,
          isAdmin: isAdmin,
        };
        updateMembership(partialMembership);
      }
    }
  
    const handleAddPerson = (personId: string) => {
      if (org) {
        addOrgMembership(org, personId);
      }
      console.log("handleAddPerson", personId);
    }

    return {
      isOpen,
      actions: (isSelf(person) ? <></> :
        <Stack direction="row" spacing={1}>
          {!isOpen && (
            <IconButton onClick={() => setOpenId(person.itemId)}>
              <MoreVertIcon />
            </IconButton>
          )}
          {isOpen && !isConfirming && 
            getOrgMembershipStatus(person, org.itemId) === 'inactive' && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              startIcon={<PersonAddIcon />}
              onClick={() => {
                handleAddPerson(person.itemId);
                setOpenId(null);
              }}
            >
              Enable
            </Button>
          )}
          {isOpen && !isConfirming && 
            getOrgMembershipStatus(person, org.itemId) === 'inactive' && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              startIcon={<PersonRemoveIcon />}
              onClick={() => setConfirmingId(person.itemId)}
            >
              Remove
            </Button>
          )}
          {isOpen && !isConfirming && 
            getOrgMembershipStatus(person, org.itemId) === 'active' && (
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  startIcon={<PersonOffIcon />}
                  onClick={() => {
                    handleDisablePerson(person.itemId);
                    setOpenId(null);
                  }}
                >
                  Disable
                </Button>
                {isOrgAdmin(org.itemId, person) ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    startIcon={<RemoveModeratorIcon />}
                    onClick={() => {
                      handleAdminChange(person, false);
                      setOpenId(null);
                    }}
                  >
                    Remove admin
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddModeratorIcon />}
                    onClick={() => {
                      handleAdminChange(person, true);
                      setOpenId(null);
                    }}
                  >
                    Add admin
                  </Button>
                )}
              </Stack>
          )}
          {isOpen && !isConfirming && (
            <Button 
              size="small" 
              variant="contained" 
              color="secondary" 
              onClick={() => setOpenId(null)}
            >
              Cancel
            </Button>
          )}
          {isConfirming && (
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<PersonRemoveIcon />}
                onClick={() => {
                  handleRemovePerson(person.itemId);
                  setConfirmingId(null);
                  setOpenId(null);
                }}
              >
                Confirm removal
              </Button>
              <Button 
                size="small" 
                variant="outlined" 
                color="secondary" 
                onClick={() => {
                  setConfirmingId(null);
                  setOpenId(null);
                }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      )
    }
  }, [openId, confirmingId, org, deactivateOrgMembership, deleteOrgMembership, addOrgMembership, updateMembership, isOrgAdmin, isSelf]);

  return (
    <PersonDirectory
      orgId={org.itemId}
      orgMembershipStatus={orgMembershipStatus}
      renderActions={renderActions}
    />
  );
});

OrgMemberDirectory.displayName = 'OrgMemberDirectory';
import { 
  ReactElement, 
  useCallback, 
  useEffect, 
  useState
} from "react";

import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from "../components/common/GratiPageWrapper";
import CourseCard from "../components/course/CourseCard";
import CourseEditor from "../components/course/CourseEditor";
import { useApp } from "../contextProviders/AppProvider";
import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import useCourse from "../dataHooks/useCourse";
import { useCourseMutators } from "../dataMutators/useCourseMutators";
import Course from "../types/Course";

interface CoursePageProps {
  edit?: boolean;
  new?: boolean;
}

export default function CoursePage(props: CoursePageProps): ReactElement {
  const isNew = props.new ?? false;

  const { courseId } = useParams();
  const navigate = useNavigate();
  
  const { updateActions, setPageName } = useApp();
  const { isGod } = useAuth();
  const { isOrgAdmin } = useData();
  const { addCourse, updateCourse, deleteCourse } = useCourseMutators();

  const [isEditMode, setIsEditMode] = useState(props.edit ? props.edit : false);
  const { course, isCourseLoading, courseError } = useCourse(courseId);

  useEffect(() => {
    if (isEditMode) {
      updateActions([]);
    } else {
      if (course && (isGod || (course.orgId && isOrgAdmin(course.orgId)))) {
        updateActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, isEditMode, isGod, isOrgAdmin, updateActions]);

  useEffect(() => {
    if (isNew) {
      setPageName("Create Course");
    } else if (course) {
      setPageName(course.name);
    } else if (courseError) {
      setPageName(courseError.message);
    }
  }, [course, courseError, isNew, setPageName]);

  const handleEditClick = useCallback(() => {
    if (course) {
      navigate('/course-edit/' + course.itemId, { 
        replace: true,
        state: { preventReload: true} 
      });
    }
    setIsEditMode(true);
  }, [course, navigate]);

  const handleCancel = useCallback(() => {
    if (course && !isNew) {
      console.log('Navigating back to course page!');
      navigate('/course/' + course.itemId, { 
        replace: true,
        state: { preventReload: true} 
      });
    } else {
      navigate(-1);
    }
    setIsEditMode(false);
  }, [course, isNew, navigate]);

  const handleSubmit = useCallback((updatedCourse: Course) => {
    if (updatedCourse) {
      if (isNew) {
        addCourse(updatedCourse).then((addedCourse) => {
          if (addedCourse) {
            navigate('/course/' + addedCourse.itemId, { 
              replace: true,
            });
          } else {
            console.error("Course not added: ", updatedCourse);
          }
        });
      } else {
        updateCourse(updatedCourse).then(() => {
          navigate('/course/' + updatedCourse.itemId, { 
            replace: true,
            state: { preventReload: true} 
          });
        });
      }
    }
    setIsEditMode(false);
  }, [isNew, navigate, addCourse, updateCourse]);

  const handleDelete = useCallback(() => {
    if (course) {
      console.log(`Deleting course ${course.itemId}`);
      deleteCourse(course).then(() => {
        navigate(-1);
      });
    } else {
      navigate(-1);
    }
  }, [course, navigate, deleteCourse]);

  const CourseDetails = (): ReactElement => {
    if (course) {
      return (
        <Stack spacing={2}>
          <CourseCard course={course} variant="none" isClickable={false} />
          <Divider sx={{ display: { sm: 'none' } }} />
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  /* const Holes = (): ReactElement => {
    if (course) {
      return (<Box></Box>);
    } else {
      return (<Box></Box>);
    }
  };*/

  return (
    <GratiPageWrapper isContentLoading={isCourseLoading && !course}>
      <>
        {isEditMode && (course && (isGod || (course.orgId && isOrgAdmin(course.orgId)))) ? (
          <CourseEditor 
            course={course} 
            handleSubmit={handleSubmit} 
            handleCancel={handleCancel} 
            handleDelete={isNew ? undefined : handleDelete}
          />
        ) : (
          !isNew || (isNew && isGod) ? (
            <CourseDetails />
          ) : (
            <Box>
              <Typography variant="h6">
                You are not authorized to do this.
              </Typography>
            </Box>
          )
        )}
      </>
    </GratiPageWrapper>
  )
};
import { ReactElement, useEffect, useState, useCallback } from 'react';

import { Timestamp } from 'firebase/firestore';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import Papa from 'papaparse';

import { useData } from '../../contextProviders/DataProvider';

// import { CompHandicap, CompScoring } from '../../types/Competition';
import DefaultObjects from '../../types/DefaultObjects';
import Event from '../../types/Event';
import EventDay from '../../types/EventDay';
import FirestoreDataTypes from '../../types/FirestoreDataTypes';
import League from '../../types/League';
import HiddenInput from '../common/HiddenInput';

// import Score from '../../types/Score';
import { convertStringToType } from '../../utils/utils';

interface CsvEventRecord {
  courseId?: string;
  courseName?: string;
  datetime: string;
  description: string;
  isGolf: string;
  isShotgun: string;
  isTest: string;
  leagueId: string;
  leagueName: string;
  maxPlayers: string;
  numTeeTimes: string;
  orgId: string;
  orgName: string;
  seasonId: string;
  seasonName: string;
  signupOpensAt: string;
  signupClosesAt: string;
  timezone: string;
}

type ParseFailure = {
  row: number;
  data: Partial<CsvEventRecord>;
  error: string;
};

interface EventCsvUploadProps {
  league: League;
  mapping: Array<{ field: string; dbname: keyof Event }>;
  onEventsLoaded: (events: Event[], failures?: ParseFailure[]) => void;
  hasImportAction?: boolean;
  resetKey?: number;
}

export default function EventCsvUpload(props: EventCsvUploadProps): ReactElement {
  const { league, mapping, onEventsLoaded, hasImportAction = true, resetKey = 0 } = props;
  const { userProfile } = useData();
  const [csvFile, setCsvFile] = useState<string>('');
  const [parseProgress, setParseProgress] = useState<{ current: number; total: number }>({ current: 0, total: 0 });
  const [isProcessing, setIsProcessing] = useState(false);
  // const [events, setEvents] = useState<Event[]>([]);
  const [failures, setFailures] = useState<ParseFailure[]>([]);

  useEffect(() => {
    if (csvFile && league) {
      console.log('Processing CSV file');
      parseCsvContents(csvFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvFile, league]);

  useEffect(() => {
    setCsvFile('');
    setParseProgress({ current: 0, total: 0 });
    setIsProcessing(false);
    setFailures([]);
  }, [resetKey]);

  type ParseFailure = {
    row: number;
    data: Partial<CsvEventRecord>;
    error: string;
  };

  const parseCsvContents = useCallback(
    (contents: string) => {
      const parsedEvents: Event[] = [];

      if (!league) {
        onEventsLoaded([], [{ row: 0, data: {}, error: 'League not found' }]);
        console.error('League not found');
        return;
      }

      setIsProcessing(true);

      Papa.parse<CsvEventRecord>(contents, {
        header: true,
        skipEmptyLines: true,
        delimiter: ',',
        transformHeader: (header) => {
          const normalizeField = (field: string) =>
            field
              .trim()
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '');

          const trimmedHeader = header.trim();
          const mappedField = mapping.find((m) => normalizeField(m.field) === normalizeField(trimmedHeader));

          return mappedField ? String(mappedField.dbname) : trimmedHeader;
        },
        step: (results) => {
          const row = results.data as CsvEventRecord;

          // Initialize event with basic fields
          const event = {
            ...DefaultObjects.Event,
            creatorId: userProfile?.itemId || 'system',
            createTime: Timestamp.now(),
            orgId: league.orgId,
            orgName: league.orgName,
            leagueId: league.itemId,
            leagueName: league.name,
          } as Event;

          // Determine number of days from row keys
          const dayIndices = Object.keys(row)
            .map((key) => key.match(/^days\[(\d+)\]/)?.[1])
            .filter((index): index is string => index !== undefined)
            .map((index) => parseInt(index));
          const maxDayIndex = Math.max(...dayIndices, 0);

          // Initialize days array
          event.days = Array(maxDayIndex + 1)
            .fill(null)
            .map(() => ({ ...DefaultObjects.Event.days[0] }));

          // Only process fields that are in our mapping
          const mappedFields = new Set(mapping.map((m) => String(m.dbname)));

          (Object.keys(row) as Array<keyof CsvEventRecord>).forEach((key) => {
            const value = row[key];
            if (value === undefined || value === '') return;

            const dayFieldMatch = key.match(/^days\[(\d+)\]\./);
            if (dayFieldMatch) {
              const dayIndex = parseInt(dayFieldMatch[1]);
              const fieldName = key.split('.')[1] as keyof EventDay;
              (event.days[dayIndex][fieldName] as FirestoreDataTypes) = convertStringToType(
                value,
                fieldName,
                event.days[dayIndex]
              );
            } else if (mappedFields.has(String(key))) {
              (event[key as keyof Event] as FirestoreDataTypes) = convertStringToType(value, key, event);
            }
          });

          setParseProgress((prev) => ({
            current: prev.current + 1,
            total: prev.total + 1,
          }));

          parsedEvents.push(event);

          return event;
        },
        complete: () => {
          setIsProcessing(false);
          setParseProgress({ current: 0, total: 0 });
          console.log('CSV parsing complete');
          onEventsLoaded(parsedEvents, failures);
        },
        error: (error: Error) => {
          console.error('Error parsing CSV:', error);
          setIsProcessing(false);
          setParseProgress({ current: 0, total: 0 });
          onEventsLoaded([], [{ row: 0, data: {}, error: error.message }]);
        },
      });
    },
    [league, userProfile, mapping, onEventsLoaded, failures]
  );

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === 'string') {
          setCsvFile(contents);
        }
        reader.onload = null; // Remove listener
      };

      reader.onerror = () => {
        console.error('Error reading file');
        reader.abort(); // Abort on error
        reader.onload = null;
        reader.onerror = null;
      };

      reader.readAsText(file);
    }
    // Clear the input value so the same file can be uploaded again
    event.target.value = '';
  };

  return (
    <Stack direction="row" spacing={2} display="flex" justifyContent="center">
      {hasImportAction && (
        <Button
          component="label"
          role={undefined}
          size="small"
          variant="contained"
          tabIndex={-1}
          startIcon={<FileUploadIcon />}
          disabled={isProcessing || !league}
        >
          Import CSV File
          <HiddenInput type="file" accept=".csv" onChange={handleFileUpload} />
        </Button>
      )}

      {isProcessing && (
        <>
          <Typography variant="body2" align="center">
            Processing row {parseProgress.current} of {parseProgress.total}
          </Typography>
          <LinearProgress variant="determinate" value={(parseProgress.current / parseProgress.total) * 100} />
        </>
      )}
    </Stack>
  );
}

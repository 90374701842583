import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Handbook from '../types/Handbook';

export interface UseHandbookProps {
  orgId: string | undefined;
  handbookId: string | undefined;
}

export default function useHandbook(props: UseHandbookProps) {
  const { orgId, handbookId } = props;
  const { userProfile, isUserLoading, isOrgMember } = useData();

  const handbookRef = useMemo(() => {
    if (!orgId) return null;

    return doc(collection(firestore, 'org', orgId, 'handbook'), handbookId) as DocumentReference<Handbook>;
  }, [orgId, handbookId]);

  const [handbook, isHandbookLoading, handbookError] = useDocumentData<Handbook>(handbookRef);

  if (!isUserLoading && !userProfile && !isHandbookLoading && !handbookError) {
    return {
      handbook: null,
      isHandbookLoading: false,
      handbookError: new Error('User must be logged in to view handbook pages'),
    };
  }

  if (!isUserLoading && !isHandbookLoading && !handbookError && orgId && !isOrgMember(orgId)) {
    return {
      handbook: null,
      isHandbookLoading: false,
      handbookError: new Error('Not authorized to view this handbook page'),
    };
  }

  return {
    handbook: handbook || null,
    isHandbookLoading: isHandbookLoading || isUserLoading,
    handbookError,
  };
}

import { ReactElement, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import GratiCsvTable, { GratiTableColumn, GratiTableRow } from '../components/common/GratiCsvTable';
import GratiPageWrapper from '../components/common/GratiPageWrapper';
import EventCsvUpload from '../components/event/EventCsvUpload';
import LeagueSelector from '../components/league/LeagueSelector';
import { useApp } from '../contextProviders/AppProvider';
import { useEventMutators } from '../dataMutators/useEventMutators';
import EventDay from '../types/EventDay';
import League from '../types/League';

import type Event from '../types/Event';

const CSV_COLUMNS: Array<GratiTableRow<Event>> = [
  { field: 'Name', required: true, dbname: 'name', example: 'Opening Day' },
  { field: 'Details', required: true, dbname: 'description', example: 'First round!' },
  { field: 'When', required: true, dbname: 'days[0].datetime' as keyof Event, example: '2024-01-01T10:00:00' },
  { field: 'Where', required: false, dbname: 'days[0].courseName' as keyof Event, example: 'Woodlands' },
  { field: 'Golf?', required: true, dbname: 'days[0].isGolf' as keyof Event, example: 'TRUE/FALSE' },
  { field: 'Shotgun?', required: true, dbname: 'days[0].isShotgun' as keyof Event, example: 'TRUE/FALSE' },
  { field: 'Num Players', required: true, dbname: 'maxPlayers', example: '24' },
  { field: 'Num Tee Times', required: true, dbname: 'numTeeTimes', example: '6' },
  { field: 'Member Member?', required: false, dbname: 'isMemberMember', example: 'TRUE/FALSE' },
  { field: 'Member Guest?', required: false, dbname: 'isMemberGuest', example: 'TRUE/FALSE' },
  { field: 'Signup Opens', required: true, dbname: 'signupOpensAt', example: '2024-01-01T10:00:00' },
  { field: 'Signup Closes', required: true, dbname: 'signupClosesAt', example: '2024-01-01T10:00:00' },
  { field: 'Timezone', required: true, dbname: 'timezone', example: 'America/Los_Angeles' },
  { field: 'Day 2 When', required: false, dbname: 'days[1].datetime' as keyof Event, example: '2024-01-01T10:00:00' },
  { field: 'D2 Where', required: false, dbname: 'days[1].courseName' as keyof Event, example: 'Woodlands' },
  { field: 'D2 Golf?', required: false, dbname: 'days[1].isGolf' as keyof Event, example: 'TRUE/FALSE' },
  { field: 'D2 Shotgun?', required: false, dbname: 'days[1].isShotgun' as keyof Event, example: 'TRUE/FALSE' },

  //days: EventDay[];
];

interface GuideRow {
  property: string;
  [key: string]: string; // Index signature for dynamic fields
}

// For the Guide view
const GUIDE_COLUMNS: Array<GratiTableColumn<GuideRow>> = [
  { field: 'Property', getValue: (row: GuideRow) => row.property },
  ...CSV_COLUMNS.map((col) => ({
    field: col.field,
    getValue: (row: GuideRow) => row[col.field],
  })),
];

// Create transposed rows for the guide
const GUIDE_ROWS: GuideRow[] = [
  { property: 'Required', ...CSV_COLUMNS.reduce((acc, col) => ({ ...acc, [col.field]: col.required }), {}) },
  { property: 'Database Field', ...CSV_COLUMNS.reduce((acc, col) => ({ ...acc, [col.field]: col.dbname }), {}) },
  { property: 'Example', ...CSV_COLUMNS.reduce((acc, col) => ({ ...acc, [col.field]: col.example }), {}) },
];

const formatDateTime = (date: Date) =>
  `${date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })}\n${date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })}`;

// For the uploaded Events view
const EVENT_COLUMNS = CSV_COLUMNS.map((guide) => ({
  field: guide.field,
  getValue: (event: Event) => {
    const dayMatch = String(guide.dbname).match(/^days\[(\d+)\]\.(.+)/);
    if (dayMatch) {
      const [, index, field] = dayMatch;
      const value = event.days[parseInt(index)]?.[field as keyof EventDay];
      return field === 'datetime' && value instanceof Date ? formatDateTime(value) : String(value ?? '');
    }

    const value = event[guide.dbname];
    return (guide.dbname === 'signupOpensAt' || guide.dbname === 'signupClosesAt') && value instanceof Date
      ? formatDateTime(value)
      : String(value ?? '');
  },
}));

export default function EventUploadPage(): ReactElement {
  const { leagueId } = useParams<{ leagueId?: string }>();
  const { setPageName } = useApp();
  const [activeStep, setStep] = useState<'upload' | 'review' | 'saving'>('upload');
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<League | undefined>(undefined);
  const { addEvent } = useEventMutators();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();
  const [resetCounter, setResetCounter] = useState(0);

  const steps = [
    { key: 'upload', label: 'Upload CSV' },
    { key: 'review', label: 'Review Events' },
    { key: 'save', label: 'Save Events' },
  ] as const;

  useEffect(() => {
    setPageName('Event Uploader');
  }, [setPageName]);

  const handleLeagueChange = (league: League | undefined) => {
    setSelectedLeague(league);
    if (activeStep === 'review' && league && events.length > 0) {
      setEvents(
        events.map((event) => ({
          ...event,
          orgId: league.orgId,
          orgName: league.orgName,
          leagueId: league.itemId,
          leagueName: league.name,
        }))
      );
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);
    try {
      for (let i = 0; i < events.length; i++) {
        await addEvent(events[i]);
        setUploadProgress(((i + 1) / events.length) * 100);
      }
      // Success - redirect or show message
      navigate('/events');
    } catch (error) {
      console.error('Error uploading events:', error);
      // Show error message
    } finally {
      setIsUploading(false);
    }
  };

  const handleClear = () => {
    setEvents([]);
    setStep('upload');
    setResetCounter((prev) => prev + 1); // Force reset of upload component
  };

  return (
    <GratiPageWrapper>
      <Stack spacing={2}>
        <Stepper activeStep={steps.findIndex((s) => s.key === activeStep)} sx={{ mb: 4 }}>
          {steps.map((step) => (
            <Step key={step.key}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Typography variant="body1">Select a league to upload events into.</Typography>

        <LeagueSelector defaultValue={leagueId ?? null} showOrgNames={true} onChange={handleLeagueChange} />

        {activeStep === 'upload' && (
          <GratiCsvTable<GuideRow>
            columns={GUIDE_COLUMNS}
            rows={GUIDE_ROWS}
            description="Your CSV file should have the following columns:"
            hasCsvDownloadAction={true}
          />
        )}

        {activeStep === 'review' && (
          <>
            <GratiCsvTable
              columns={EVENT_COLUMNS}
              rows={events}
              isEditable={true}
              onRowChange={(rowIndex, updatedEvent) => {
                setEvents((prev) => prev.map((event, i) => (i === rowIndex ? updatedEvent : event)));
              }}
            />

            <Stack alignItems="center" spacing={2}>
              <Typography component="div" variant="body1" sx={{ mt: 2 }}>
                You are uploading {events.length} events to the <strong>{selectedLeague?.name}</strong> league in the{' '}
                <strong>{selectedLeague?.orgName}</strong> organization.
              </Typography>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  loading={isUploading}
                  loadingPosition="start"
                  size="small"
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  onClick={handleUpload}
                  sx={{ mt: 2 }}
                >
                  Upload {events.length} Events
                </Button>
                {events?.length > 0 && (
                  <Button variant="outlined" size="small" onClick={handleClear}>
                    Clear Events
                  </Button>
                )}
              </Stack>
            </Stack>

            {isUploading && <LinearProgress variant="determinate" value={uploadProgress} sx={{ mt: 1 }} />}
          </>
        )}

        {selectedLeague && (
          <EventCsvUpload
            league={selectedLeague}
            mapping={CSV_COLUMNS}
            hasImportAction={activeStep === 'upload'}
            onEventsLoaded={(parsedEvents) => {
              console.log('Events loaded:', parsedEvents);
              setEvents(parsedEvents);
              setStep('review');
            }}
            resetKey={resetCounter}
          />
        )}
      </Stack>
    </GratiPageWrapper>
  );
}

import { collection, deleteDoc, setDoc } from 'firebase/firestore';
import { doc, updateDoc } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Event from '../types/Event';

export const useEventMutators = () => {
  const { isLeagueAdmin, userProfile } = useData();

  const addEvent = async (event: Event, isTest = false): Promise<Event | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }
    console.log('Adding event: ', event);

    if (!event.leagueId || !event.orgId) {
      console.error('Missing required event fields:', event.name);
      return null;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile.itemId, 'Event:', event.itemId);
      return null;
    }

    try {
      const eventRef = doc(collection(firestore, `org/${event.orgId}/event`));

      const newEvent = {
        ...event,
        createTime: Timestamp.now(),
        creatorId: userProfile.itemId,
        datetime: event.days.reduce((earliest, day) => 
          (day.datetime < earliest ? day.datetime : earliest), event.days[0].datetime),
        itemId: eventRef.id,
        isTest,
      };

      await setDoc(eventRef, newEvent);
      return newEvent;
    } catch (error) {
      console.error('Error adding event:', error);
      return null;
    }
  };

  const deleteEvent = async (event: Event): Promise<void> => {
    if (!event.itemId || !event.orgId) {
      console.error('Event itemId or orgId not found:', event.itemId, event.orgId);
      return;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Event:', event.itemId);
      return;
    }

    try {
      const eventRef = doc(firestore, `org/${event.orgId}/event/${event.itemId}`);
      await deleteDoc(eventRef);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const updateEvent = async (event: Partial<Event>): Promise<void> => {
    if (!event.orgId || !event.itemId || !event.leagueId) {
      console.error('Missing required fields:', {
        orgId: event.orgId,
        itemId: event.itemId,
        leagueId: event.leagueId,
      });
      return;
    }

    if (!isLeagueAdmin(event.leagueId, event.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Event:', event.itemId);
      return;
    }

    try {
      const { orgId, itemId, leagueId, ...eventUpdates } = event;
      console.log('updating event: ', event);
      const eventRef = doc(firestore, `org/${orgId}/event/${itemId}`);

      const updates = {
        ...eventUpdates,
      };


      // TODO: This should be moved to the backend.
      if (event.days) {
        updates.datetime = event.days.reduce(
          (earliest, day) => (day.datetime < earliest ? day.datetime : earliest),
          event.days[0].datetime
        );
      }

      await updateDoc(eventRef, updates);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  return {
    addEvent,
    deleteEvent,
    updateEvent,
  };
};

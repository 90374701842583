import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Course from '../types/Course';

export default function useCourse(courseId: string | undefined) {
  const { userProfile, isUserLoading } = useData();

  const courseRef = useMemo(() => {
    if (!courseId) return null;
    // Note: Courses might be publicly readable - adjust authorization check as needed
    if (!userProfile) return null;

    return doc(collection(firestore, 'course'), courseId) as DocumentReference<Course>;
  }, [courseId, userProfile]);

  const [course, isCourseLoading, courseError] = useDocumentData<Course>(courseRef);

  if (!courseId) {
    throw new Error('Invalid courseId');
  }

  if (!isUserLoading && !userProfile && !isCourseLoading && !courseError) {
    return {
      course: null,
      isCourseLoading: false,
      courseError: new Error('User must be logged in to view courses'),
    };
  }

  return {
    course: course || null,
    isCourseLoading: isCourseLoading || isUserLoading,
    courseError,
  };
}

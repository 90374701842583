import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';

import useHandbooks from '../../dataHooks/useHandbooks';
import Handbook from '../../types/Handbook';
import Hourglass from '../common/Hourglass';

import HandbookListItem from './HandbookListItem';

interface HandbookDirectoryProps {
  orgId?: string;
  renderActions?: (handbook: Handbook) => ReactElement | null;
}

export default function HandbookDirectory(props: HandbookDirectoryProps): ReactElement {
  const { orgId, renderActions } = props;
  const { handbooks, isHandbooksLoading, handbooksError } = useHandbooks({ orgId });

  if (isHandbooksLoading) {
    return <Hourglass />;
  } else if (handbooksError) {
    return <Alert severity="error">Error loading handbooks {handbooksError.message}</Alert>;
  } else if (!handbooks || handbooks.length === 0) {
    return <Alert severity="error">No handbooks</Alert>;
  } else {
    return (
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        {handbooks.flatMap((handbook: Handbook) => {
          return (
            <HandbookListItem 
              key={handbook.itemId} 
              handbook={handbook} 
              isDividerDisplayed={true} 
              renderActions={renderActions?.(handbook)}
            />
          );
        })}
      </List>
    );
  }
}

import { forwardRef } from "react";

import { OverridableComponent } from "@mui/material/OverridableComponent";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { SvgIconTypeMap } from "@mui/material";

const GratiIcon = forwardRef<SVGSVGElement, SvgIconProps>(function GratiIcon(props, ref) {
  return (
    <SvgIcon {...props} ref={ref} viewBox="0 0 634 699">
      <path fill="#5B2B82" opacity="1.000000" stroke="none" 
        d="M1.000000,200.468658 
        C19.032701,202.681442 35.476063,209.175980 48.486881,222.179062 
        C61.309254,234.993820 69.304901,250.544662 71.005814,268.895477 
        C71.493843,274.160675 71.746208,279.451019 71.986969,284.735260 
        C72.696007,300.297119 72.342735,315.988525 74.292023,331.390442 
        C76.349480,347.647064 80.021355,363.767883 83.989464,379.698578 
        C90.398674,405.429443 102.454575,428.750275 116.993469,450.747589 
        C129.444275,469.585663 144.126877,486.662506 160.638229,502.059723 
        C188.104553,527.672791 218.429581,549.223206 252.704346,564.847351 
        C273.411316,574.286560 294.646118,582.363953 317.029572,586.967346 
        C324.581512,588.520386 332.213837,589.685913 339.815430,590.993530 
        C345.897278,592.039734 352.353058,586.490540 353.928925,582.048645 
        C356.453613,574.932556 355.532257,568.086060 349.816681,564.192749 
        C344.749268,560.740967 338.909149,557.997498 333.021759,556.249390 
        C307.883270,548.785278 282.545105,541.666870 259.654266,528.611938 
        C246.693604,521.220276 234.551025,512.212769 222.742889,503.029266 
        C209.038910,492.371368 198.491699,478.795410 190.666855,463.234619 
        C184.076416,450.128632 179.660065,436.159271 179.312592,421.592957 
        C179.052444,410.686615 179.760544,399.451782 184.125381,389.010773 
        C184.419418,388.307404 185.055283,387.746948 185.404144,387.288361 
        C195.240234,395.315002 204.547440,403.675659 214.637833,410.949951 
        C227.179901,419.991699 240.020432,428.730896 253.351685,436.543396 
        C281.196320,452.861145 311.981201,461.698425 342.927612,469.810425 
        C364.553253,475.479187 386.508789,479.947021 407.988647,486.097839 
        C428.916443,492.090576 448.032654,502.506287 465.473938,515.400818 
        C487.445496,531.644592 505.557892,551.605347 516.797913,576.836121 
        C523.498718,591.877563 529.175598,607.447388 529.993042,624.187927 
        C530.567627,635.954590 530.877258,647.745972 530.785706,659.522278 
        C530.769775,661.571777 528.611633,664.693542 526.695129,665.466736 
        C511.581573,671.564270 496.522797,677.977173 480.979187,682.777832 
        C466.101471,687.372803 450.738403,690.532288 435.440948,693.581848 
        C426.095367,695.444946 416.467773,695.882568 406.975616,697.032959 
        C405.604858,697.199158 404.283508,697.772644 402.969666,698.578796 
        C380.979095,699.000000 358.958221,699.000000 336.501709,698.580811 
        C335.554443,697.807617 335.079865,697.221802 334.525757,697.133179 
        C317.475555,694.405762 300.340607,692.136353 283.378052,688.948425 
        C255.198257,683.652527 228.389542,673.869629 203.004501,660.783813 
        C177.332779,647.550110 152.501678,632.604919 131.336151,612.670837 
        C113.988068,596.332031 96.475937,579.951782 80.963463,561.931152 
        C58.923214,536.327271 42.091755,507.123810 28.996906,475.907501 
        C16.127327,445.228302 6.924853,413.568115 3.869216,380.283905 
        C3.731225,378.780792 1.992923,377.424622 1.000000,376.000000 
        C1.000000,317.645782 1.000000,259.291534 1.000000,200.468658 
      z"/>
<path fill="#5B2B82" opacity="1.000000" stroke="none" 
	d="M415.531342,1.000002 
	C416.878143,1.659832 417.695221,2.783885 418.643738,2.908628 
	C429.749878,4.369257 440.991150,5.070546 451.973389,7.151254 
	C478.307983,12.140649 504.415161,18.235205 529.197449,28.752813 
	C538.583923,32.736439 547.547852,37.717365 556.695435,42.261669 
	C561.481995,44.639511 566.555359,46.597919 570.966064,49.543900 
	C582.795166,57.444740 594.508179,65.544800 605.931335,74.018715 
	C613.338745,79.513687 620.212952,85.738251 627.203186,91.778114 
	C629.283508,93.575623 630.947632,95.854836 634.266541,99.538155 
	C628.785767,104.243454 623.344482,108.830406 617.997620,113.524895 
	C609.815674,120.708504 601.543152,127.800926 593.619141,135.261398 
	C589.598511,139.046799 586.356934,143.647018 582.567566,147.695694 
	C580.435669,149.973373 577.838745,151.807892 575.566895,153.963867 
	C567.734924,161.396484 559.840759,168.769684 552.208679,176.403809 
	C549.141785,179.471497 546.520508,179.791794 543.183594,177.088135 
	C537.716919,172.658859 532.294189,168.142578 526.541016,164.107727 
	C518.627380,158.557724 510.641357,153.047089 502.295929,148.190460 
	C486.997437,139.287491 470.548553,132.869675 453.547119,128.119949 
	C439.870056,124.298950 426.009003,121.099159 411.631317,120.952736 
	C399.348358,120.827652 387.053833,119.783096 374.791809,120.125397 
	C355.132294,120.674187 335.874878,123.931015 316.882294,129.412933 
	C289.898468,137.201385 266.258514,150.936829 245.006607,168.771912 
	C228.116058,182.946838 213.889008,199.737961 202.734848,218.838455 
	C197.262344,228.209656 192.847305,238.196381 187.857193,247.854675 
	C187.402298,248.735092 186.336075,249.638763 185.392456,249.868134 
	C181.557571,250.800232 175.373444,247.104782 173.973923,243.393097 
	C161.433746,210.134827 137.388428,189.859039 102.999687,182.404160 
	C92.691902,180.169586 81.675980,180.397049 71.239937,184.024048 
	C69.696487,184.560471 67.963715,185.308487 66.471916,185.055222 
	C64.374596,184.699112 62.179405,183.798111 60.545315,182.484299 
	C60.021271,182.062973 60.766449,179.355881 61.567699,178.101181 
	C70.964951,163.385651 79.679855,148.141434 90.166862,134.237518 
	C104.331184,115.458115 120.535408,98.439461 138.819504,83.368698 
	C153.243591,71.479553 167.860641,59.863373 184.307587,51.090591 
	C202.472275,41.401573 221.077332,32.418308 239.982498,24.268770 
	C261.855713,14.839775 285.300598,10.541046 308.596954,6.159210 
	C317.319336,4.518607 326.254547,4.016337 335.083893,2.923932 
	C335.792450,2.836263 336.432373,2.193708 337.052094,1.404504 
	C363.020905,1.000000 389.041779,1.000000 415.531342,1.000002 
z"/>
<path fill="#5B2B82" opacity="1.000000" stroke="none" 
	d="M631.000000,380.000000 
	C631.000000,452.972260 630.963379,525.444580 631.088684,597.916626 
	C631.096375,602.373901 629.915161,605.200378 626.028381,607.684570 
	C607.856140,619.299500 589.928528,631.296509 571.807251,642.992371 
	C566.997131,646.096985 565.964233,645.368408 566.017944,639.515686 
	C566.277405,611.242126 559.189026,584.816833 547.254822,559.289551 
	C536.274780,535.803223 520.790588,515.885193 501.791199,498.653656 
	C497.906555,495.130463 496.898529,491.960297 496.933533,487.278564 
	C497.087555,466.688049 497.000000,446.095795 497.000000,425.000092 
	C494.412567,425.000092 492.273438,425.000092 490.134308,425.000092 
	C453.315063,424.999939 416.495819,425.002869 379.676575,424.996796 
	C373.023285,424.995697 373.004822,424.978638 373.003571,418.400787 
	C372.997070,384.580383 373.073853,350.759674 372.914246,316.940002 
	C372.892853,312.404694 374.151978,310.902740 378.836884,310.909943 
	C460.805328,311.036224 542.774048,311.000153 624.742676,311.010132 
	C626.219482,311.010315 627.696289,311.142365 629.586975,311.232666 
	C632.143982,334.055725 630.566650,356.804993 631.000000,380.000000 
z"/>
<path fill="#5B2B82" opacity="1.000000" stroke="none" 
	d="M146.000000,298.000000 
	C146.000000,326.478241 146.179489,354.458191 145.910904,382.433868 
	C145.804733,393.491699 144.560135,404.534424 144.129669,415.595551 
	C143.982193,419.385376 144.736328,423.210297 145.078552,427.019165 
	C144.621719,427.190765 144.164871,427.362335 143.708038,427.533936 
	C140.750107,422.530273 137.580185,417.638489 134.870651,412.503723 
	C122.684113,389.409454 115.480644,364.695557 110.916153,339.074554 
	C107.205910,318.248596 107.391800,297.250336 106.849480,276.231415 
	C106.397682,258.720490 103.858025,241.414169 95.765015,225.450424 
	C94.149193,222.263168 92.536072,219.074554 90.455582,214.966095 
	C92.986206,215.224060 94.570244,215.211075 96.067902,215.564346 
	C118.815002,220.930084 132.936707,235.334869 141.273254,256.887848 
	C146.441086,270.248596 145.948959,283.794678 146.000000,298.000000 
z"/>
<path fill="#5B2B82" opacity="1.000000" stroke="none" 
	d="M183.644714,346.054535 
	C182.496170,345.141418 181.470566,344.389435 181.112213,343.396606 
	C180.739258,342.363373 181.001755,341.097595 181.001633,339.933502 
	C180.999756,320.823608 181.000305,301.713684 181.000305,281.162567 
	C185.992615,283.660492 190.408997,285.316193 194.220001,287.873901 
	C209.916794,298.408539 219.331726,312.876923 219.933609,332.124359 
	C220.333221,344.903809 220.009369,357.705902 220.009369,371.686279 
	C207.566254,362.364258 198.164703,350.835815 183.644714,346.054535 
z"/>
<path fill="#5B2B82" opacity="1.000000" stroke="none" 
	d="M263.357117,401.677673 
	C255.205780,398.956726 254.349594,393.104126 254.882507,385.908966 
	C255.564240,376.704315 255.439636,367.437256 255.566437,358.195770 
	C255.605682,355.337097 255.331436,352.474091 255.196518,349.521301 
	C276.870972,357.362213 289.380585,377.618896 286.561340,413.764313 
	C278.591583,409.627533 271.129761,405.754395 263.357117,401.677673 
z"/>
</SvgIcon>
  );
}) as unknown as OverridableComponent<SvgIconTypeMap<object, "svg">> & { muiName: string; displayName: string };

GratiIcon.displayName = 'GratiIcon';  // Add display name
GratiIcon.muiName = 'SvgIcon';

export default GratiIcon;

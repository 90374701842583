import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import SeasonEditor from '../components/season/SeasonEditor';
import SeasonView from '../components/season/SeasonView';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useSeason from '../dataHooks/useSeason';

interface SeasonPageProps {
  isEditMode?: boolean;
}

export default function SeasonPage(props: SeasonPageProps): ReactElement {
  const { isEditMode } = props;

  const { updateActions, setPageName } = useApp();
  const { isLeagueAdmin } = useData();
  const { orgId, seasonId } = useParams();
  const navigate = useNavigate();
  const { season, isSeasonLoading, seasonError } = useSeason({ orgId, seasonId });

  useEffect(() => {
    const handleEditClick = () => {
      if (season) {
        navigate('/season-edit/' + season.orgId + '/' + season.itemId, { replace: true });
      }
    };

    if (season) {
      setPageName(season.leagueName + ' - ' + season.name);
      if (isLeagueAdmin(season.itemId, season.orgId)) {
        updateActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    } else if (seasonError) {
      setPageName(seasonError.message);
    }
  }, [season, seasonError, isLeagueAdmin, updateActions, setPageName, navigate]);

  console.log('season', season);
  return (
    <GratiPageWrapper isContentLoading={isSeasonLoading && !season}>
      <>
        {season && (
          <>
            {isLeagueAdmin(season.itemId, season.orgId) ? (
              <Box>
                {isEditMode ? 
                  <SeasonEditor season={season} isNew={false} /> : 
                  <SeasonView season={season} />
                }
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add event"
                  component={Link}
                  to={`/event-create/${season.orgId}/${season.leagueId}/${season.itemId}`}
                >
                  <AddIcon />
                  Add event
                </Fab>
              </Box>
            ) : (
              <Box>
                <SeasonView season={season} />
              </Box>
            )}
          </>
        )}
      </>
    </GratiPageWrapper>
  );
}
import { ReactElement, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import HandbookEditor from '../components/handbook/HandbookEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useOrg from '../dataHooks/useOrg';
import { useHandbookMutators } from '../dataMutators/useHandbookMutators';
import DefaultObjects from '../types/DefaultObjects';
import Handbook from '../types/Handbook';

export default function HandbookCreatePage(): ReactElement {
  const { orgId } = useParams();
  const { setPageName } = useApp();
  const { canCreateHandbooks, userProfile } = useData();
  const { addHandbook } = useHandbookMutators();
  const navigate = useNavigate();
  const { org, isOrgLoading, orgError } = useOrg(orgId ?? null);

  const createNewHandbook = (): Handbook => {
    return {
      ...DefaultObjects.Handbook,
      creatorId: userProfile?.itemId || '',
    };
  };

  useEffect(() => {
    if (!orgId) {
      setPageName('Create Handbook');
    } else if (org) {
      setPageName(`Create ${org.name} Handbook`);
    }
  }, [setPageName, orgId, isOrgLoading, org]);

  const handleSubmit = (handbook: Handbook) => {
    if (handbook) {
      addHandbook(handbook);
    }
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validateUser = () => {
    return canCreateHandbooks(orgId) ?? false;
  };

  if (orgError) {
    return <div>Error: {orgError.message}</div>;
  }

  return (
    <GratiPageWrapper validateUser={validateUser} isContentLoading={(orgId && (isOrgLoading || !org)) ? true : false}>
      <HandbookEditor 
        handbook={createNewHandbook()} 
        isNew={true} 
        initialSection={"0"} 
        onCancel={handleCancel} 
        onSubmit={handleSubmit} 
      />
    </GratiPageWrapper>
  );
}
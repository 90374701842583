import { and, collection, CollectionReference, orderBy, Query } from "firebase/firestore";
import { query, where } from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { OrgMembershipStatus } from "../enums/OrgMembershipStatus";
import { firestore } from "../firebase";
import Org from "../types/Org";

interface UseOrgsProps {
  orgMembershipStatus?: OrgMembershipStatus[];
}

export default function useOrgs(props: UseOrgsProps) {
  const { isGod } = useAuth();
  const { userProfile } = useData();
  const orgMembershipStatus = props.orgMembershipStatus ?? [OrgMembershipStatus.ALL];

  const getOrgsQuery = () => {
    let targetIds: string[] = [];
    
    if (orgMembershipStatus?.includes(OrgMembershipStatus.ALL)) {
      // Combine all org IDs for ALL status
      targetIds = [
        ...(userProfile?.orgIds ?? []),
        ...(userProfile?.pendingOrgIds ?? []),
        ...(isGod ? (userProfile?.inactiveOrgIds ?? []) : [])
      ];
    } else {
      // Handle individual statuses
      if (orgMembershipStatus?.includes(OrgMembershipStatus.ACTIVE)) {
        targetIds.push(...(userProfile?.orgIds ?? []));
      }
      if (orgMembershipStatus?.includes(OrgMembershipStatus.PENDING)) {
        targetIds.push(...(userProfile?.pendingOrgIds ?? []));
      }
      if (isGod && orgMembershipStatus?.includes(OrgMembershipStatus.INACTIVE)) {
        targetIds.push(...(userProfile?.inactiveOrgIds ?? []));
      }
    }
  
    // If no IDs found, return a query that will yield no results
    if (targetIds.length === 0) {
      return null;
    }
  
    return query(
      collection(firestore, 'org') as CollectionReference<Org>,
      and(
        where('isActive', '==', true),
        where('itemId', 'in', targetIds),
        where('isTest', 'in', [false, isGod])
      ),
      orderBy('name')
    ) as Query<Org>;
  }

  // *************** Org loaders ****************
  const qOrgs = userProfile && userProfile.orgIds && userProfile.orgIds.length > 0 ? 
    getOrgsQuery() : null;

  const [orgs, isOrgsLoading, orgsError] = useCollectionData(qOrgs);

  return { orgs: orgs ?? [], isOrgsLoading, orgsError };   
}

import { 
  ReactElement,
} from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import League from "../../types/League";
import SeasonDirectory from "../season/SeasonDirectory";
//import SeasonStandingCsvUpload from "../season/SeasonStandingCsvUpload";

import Season from "../../types/Season";

import LeagueCard from "./LeagueCard";
import LeagueMembers from "./LeagueMembers";
import LeagueResults from "./LeagueResults";

type LeagueViewProps = {
  league: League;
  seasons: Season[];
};

export default function LeagueView(props: LeagueViewProps ): ReactElement {
  const { league, seasons } = props;
  console.log('seasons ', seasons);

  const LeagueView = (): ReactElement => {
    if (league /* && thisSeason */) {
      return (
        <Stack spacing={2}>
          <LeagueCard league={league} variant="none" isClickable={false} />
          <Divider sx={{ display: { sm: 'none' } }} />
          <SeasonDirectory seasons={seasons} />
          <Box>
            <Typography variant="headline">Results</Typography>
          </Box>
          <LeagueResults league={league} />
          {/* isGod && thisSeason && <SeasonStandingCsvUpload season={thisSeason} /> */}
          <Box>
            <Typography variant="title">Members</Typography>
          </Box>
          <LeagueMembers league={league} isEditable={true} />
        </Stack>
      );
    } else {
      return <></>;
    }
  }; 

  return (
    <Box>
      <LeagueView />
    </Box>
  );
}
import { ReactElement } from 'react';

import { Timestamp } from 'firebase/firestore';

import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs, { Dayjs } from 'dayjs';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Course from '../../types/Course';
import { CoursePair } from '../../types/Course';
import EventDay from '../../types/EventDay';
import { DisplayDate, ToDayJS } from '../../utils/utils';
import CourseSelector from '../course/CourseSelector';

export interface EventTimeEditorProps {
  days: EventDay[];
  coursePairs: CoursePair[];
  defaultTime?: Dayjs;
  timezone?: string;
  isGolfEvent?: boolean;
  onChange: (days: EventDay[], coursePairs: CoursePair[]) => void;
}

export default function EventTimeEditor(props: EventTimeEditorProps): ReactElement {
  const {
    days,
    coursePairs,
    defaultTime = dayjs('2000-01-01T09:00:00'),
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    isGolfEvent = true,
    onChange,
  } = props;

  console.log('days: ', days);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isSmallScreen = isXS || isSm;

  const handleCourseChange = (index: number, course: Course) => {
    // Update days
    const newDays = [...days];
    newDays[index] = {
      ...newDays[index],
      courseId: course.itemId,
    };

    // Build new coursePairs
    const newCoursePairs = [...coursePairs];

    // Add or update the course that was just selected
    const existingPairIndex = newCoursePairs.findIndex((pair) => pair.id === course.itemId);
    if (existingPairIndex === -1) {
      newCoursePairs.push({
        id: course.itemId,
        name: course.name,
        shortName: course.shortName,
        timezone,
      });
    }

    // Remove any coursePairs that are no longer referenced in days
    const activeIds = new Set(newDays.map((day) => day.courseId).filter(Boolean));
    const filteredPairs = newCoursePairs.filter((pair) => activeIds.has(pair.id));

    onChange(newDays, filteredPairs);
  };

  if (days.length === 0) {
    return (
      <Stack>
        <Typography>Start Time</Typography>
        <TimePicker value={defaultTime} />
      </Stack>
    );
  } else {
    return (
      <Stack spacing={2} sx={{ pb: 4 }}>
        <Typography variant="title" color="primary.secondary">
          Daily Start Times
        </Typography>
        {days.map((day, i) => {
          console.log('i: ', i, 'day: ', day);
          return (
            <Grid key={i} container spacing={2}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <TimePicker
                    label={DisplayDate(
                      (days[i].datetime as Timestamp).toDate(),
                      timezone,
                      isSmallScreen ? 'M/D/YY' : 'MMM DD, YYYY'
                    )}
                    value={ToDayJS(days[i].datetime as Timestamp, timezone)}
                    onChange={(target) => {
                      if (target != null) {
                        const newDays = [...days];
                        newDays[i].datetime = Timestamp.fromDate(target.toDate());
                        onChange(newDays, coursePairs);
                      }
                    }}
                  />
                  {(day.isGolf || isGolfEvent) && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={day.isShotgun ? day.isShotgun : false}
                            name="shotgun"
                            onChange={({ target }) => {
                              const newDays = [...days];
                              newDays[i].isShotgun = target.checked;
                              onChange(newDays, coursePairs);
                            }}
                          />
                        }
                        label="Shotgun"
                        labelPlacement={isSmallScreen ? 'top' : 'start'}
                      />
                    </FormGroup>
                  )}
                </Stack>
              </Grid>
              <Grid key={i} size={{ xs: 12, lg: 6 }}>
                {(day.isGolf || isGolfEvent) && (
                  <CourseSelector value={day.courseId ?? ''} handleChange={(course) => handleCourseChange(i, course)} />
                )}
              </Grid>
              {i < days.length - 1 && <Divider sx={{ width: '100%' }} />}
            </Grid>
          );
        })}
      </Stack>
    );
  }
}

/*
              {(day.isGolf || isGolfEvent) && courses && courses.length > 0 && (
                <TextField
                  id="location"
                  label="Location"
                  autoFocus={false}
                  select
                  aria-label="location"
                  aria-required="false"
                  value={days[i].courseId ? days[i].courseId : ""}
                  fullWidth
                  onChange={({ target }) => {
                    const newDays = [...days];
                    newDays[i].courseId = target.value;
                    onChange(newDays);
                  }}
                >
                  {CourseMenu()}
                </TextField>
              )}
*/

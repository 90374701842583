import { memo } from 'react';

import { sanitizeHtml } from '../../utils/sanitizeHtml';

interface SanitizedHtmlProps {
  content: string;
  className?: string;
}

function SanitizedHtml({ content, className }: SanitizedHtmlProps) {
  return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />;
}

export default memo(SanitizedHtml);
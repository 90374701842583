import { ReactElement, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import LeagueEditor from '../components/league/LeagueEditor';
import LeagueView from '../components/league/LeagueView';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useLeague from '../dataHooks/useLeague';
import useSeasons from '../dataHooks/useSeasons';
import { useLeagueMutators } from '../dataMutators/useLeagueMutators';
import { useSeasonMutators } from '../dataMutators/useSeasonMutators';
import League from '../types/League';
import Season from '../types/Season';

interface LeaguePageProps {
  isEditMode?: boolean;
}

export default function LeaguePage(props: LeaguePageProps): ReactElement {
  const { isEditMode } = props;

  const { updateActions, setPageName } = useApp();
  const { isLeagueAdmin, isOrgMember, userProfile } = useData();
  const { orgId, leagueId } = useParams();
  const navigate = useNavigate();
  const { deleteLeague, updateLeague } = useLeagueMutators();
  const { addSeason, updateSeason, deleteSeason } = useSeasonMutators();

  const { league, isLeagueLoading, leagueError } = useLeague(userProfile ? { orgId, leagueId } : {});
  const { seasons, isSeasonsLoading, seasonsError } = useSeasons(userProfile ? { orgId, leagueId } : {});

  useEffect(() => {
    if (league) {
      setPageName(league.name);
      if (isLeagueAdmin(league.itemId, league.orgId)) {
        updateActions([
          {
            name: 'Edit',
            handleClick: () => navigate('/league-edit/' + league.orgId + '/' + league.itemId, { replace: true }),
            icon: EditIcon,
          },
        ]);
      }
    } else if (leagueError) {
      setPageName(leagueError.message);
    }
  }, [league, leagueError, isLeagueAdmin, updateActions, setPageName, navigate]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = (league: League) => {
    if (league) {
      updateLeague(league);
    }
    navigate('/league/' + league.orgId + '/' + league.itemId, { replace: true });
  };

  const handleDelete = (league: League) => {
    console.log(`Deleting league ${league.itemId}`);
    deleteLeague(league);
    navigate(-1);
  };

  const handleSeasonAdded = (season: Season) => {
    addSeason(season);
  };

  const handleSeasonChange = useMemo(
    () =>
      debounce((season: Partial<Season>) => {
        try {
          updateSeason(season);
        } catch (error) {
          console.error('Error updating season: ', error);
        }
      }, 500),
    [updateSeason]
  );

  const handleSeasonDelete = (season: Season) => {
    try {
      deleteSeason(season);
    } catch (error) {
      console.error('Error deleting season: ', error);
    }
  };

  const validateUser = () => {
    if (!orgId || !leagueId) {
      return false;
    }
    if (isEditMode) {
      return isLeagueAdmin(leagueId, orgId) ?? false;
    } else {
      return isOrgMember(orgId) ?? false;
    }
  };

  return (
    <GratiPageWrapper isContentLoading={isLeagueLoading || isSeasonsLoading} validateUser={validateUser}>
      <>
        {league && seasons != null && (
          <>
            {isLeagueAdmin(league.itemId, league.orgId) ? (
              <Box>
                {isEditMode ? (
                  <LeagueEditor
                    league={league}
                    isNew={false}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                    onDelete={handleDelete}
                    onSeasonAdd={handleSeasonAdded}
                    onSeasonChange={handleSeasonChange}
                    onSeasonDelete={handleSeasonDelete}
                  />
                ) : (
                  <LeagueView league={league} seasons={seasons} />
                )}
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add event"
                  component={Link}
                  to={`/event-create/${league.orgId}/${league.itemId}`}
                >
                  <AddIcon />
                  Add event
                </Fab>
              </Box>
            ) : (
              <Box>
                <LeagueView league={league} seasons={seasons} />
              </Box>
            )}
          </>
        )}
        {seasonsError && <Typography>Error loading seasons: {seasonsError.message}</Typography>}
      </>
    </GratiPageWrapper>
  );
}

import { Timestamp, updateDoc, writeBatch } from "firebase/firestore";
import { arrayUnion, getDoc } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";

import { Person } from "../../shared/dist/types/Person";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import League from "../types/League";
import Membership from "../types/Membership";
import Org from "../types/Org";


export const useMembershipMutators = () => {
  const { userProfile } = useData();

  const updateMembership = async (membership: Partial<Membership>): Promise<void> => {
    if (!membership.orgId || !membership.itemId) {
      console.error('Missing required fields:', {
      orgId: membership.orgId,
      itemId: membership.itemId,
    });
    return;
  }

    try {
      const { orgId, itemId, ...membershipUpdates } = membership;
      const membershipRef = doc(firestore, `org/${orgId}/membership/${itemId}`);
      await setDoc(membershipRef, { ...membershipUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating membership:', error);
    }
  };

  const addMembership = async (membership: Partial<Membership>): Promise<Membership | null> => {
    if (!membership.orgId || !membership.itemId) {
      console.error('Missing required fields:', {
        orgId: membership.orgId,
        itemId: membership.itemId,
      });
      return null;
    }

    try {
      const membershipRef = doc(firestore, `org/${membership.orgId}/membership/${membership.itemId}`);
      const newMembership = {
        ...membership,
        createTime: Timestamp.now(),
        creatorId: userProfile?.itemId || '',
      };

      await setDoc(membershipRef, newMembership);
      return newMembership as Membership;
    } catch (error) {
      console.error('Error adding membership:', error);
      return null;
    }
  };

  const addOrgMembership = async (org: Org, personId: string): Promise<Membership | null> => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;

      if (!person) {
        console.error('Member profile not found:', personId);
        return null;
      }

      const batch = writeBatch(firestore);
      let newMembership: Membership | null = null;

      // Handle membership document
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${personId}`);
      const membershipDoc = await getDoc(membershipRef);

      if (membershipDoc.exists()) {
        const membership = membershipDoc.data() as Membership;
        if (!membership.isMember || !membership.isActive) {
          newMembership = { ...membership, isMember: true, isActive: true };
          batch.update(membershipRef, { ...newMembership });
        } else {
          console.log('Membership already exists:', person.itemId);
        }
      } else {
        newMembership = {
          createTime: Timestamp.now(),
          creatorId: userProfile?.itemId || '',
          isMember: true,
          isActive: true,
          isAdmin: false,
          isPending: false,
          itemId: personId,
          name: person.name,
          orgId: org.itemId,
        };
        batch.set(membershipRef, newMembership);
      }

      // Handle orgIds and inactiveOrgIds arrays
      let needsProfileUpdate = false;
      const inactiveOrgIds = [...(person.inactiveOrgIds || [])];
      const orgIds = [...(person.orgIds || [])];

      // Remove from inactive if present
      const inactiveIndex = inactiveOrgIds.indexOf(org.itemId);
      if (inactiveIndex !== -1) {
        inactiveOrgIds.splice(inactiveIndex, 1);
        needsProfileUpdate = true;
      }

      // Add to active if not present
      if (!orgIds.includes(org.itemId)) {
        orgIds.push(org.itemId);
        needsProfileUpdate = true;
      }

      // Update profile if either array changed
      if (needsProfileUpdate) {
        const profileRef = doc(firestore, 'profile/' + personId);
        batch.set(profileRef, { ...person, orgIds, inactiveOrgIds }, { merge: true });
      }

      await batch.commit();
      return newMembership;
    } catch (error) {
      console.error('Error adding org membership:', error);
      return null;
    }
  };

  const acceptOrgMembership = async (org: Org, personId?: string): Promise<void> => {
    const acceptingPersonId = personId || userProfile?.itemId;

    if (!acceptingPersonId) {
      console.error('No person ID provided and no user profile found');
      return;
    }

    if (!org.itemId) {
      console.error('Org itemId not found');
      return;
    }

    try {
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${acceptingPersonId}`);
      await updateDoc(membershipRef, { isPending: false });
    } catch (error) {
      console.error('Error accepting org membership:', error);
    }
  };

  const deactivateOrgMembership = async (org: Org, personId: string): Promise<void> => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;

      if (!person) {
        console.error('Person not found:', personId);
        return;
      }

      const batch = writeBatch(firestore);

      // Update membership status
      const membershipRef = doc(firestore, `org/${org.itemId}/membership/${personId}`);
      batch.update(membershipRef, { isActive: false });

      // Handle orgIds and inactiveOrgIds arrays
      let needsProfileUpdate = false;
      const inactiveOrgIds = [...(person.inactiveOrgIds || [])];
      const orgIds = [...(person.orgIds || [])];

      // Remove from active if present
      const activeIndex = orgIds.indexOf(org.itemId);
      if (activeIndex !== -1) {
        orgIds.splice(activeIndex, 1);
        needsProfileUpdate = true;
      }

      // Add to inactive if not present
      if (!inactiveOrgIds.includes(org.itemId)) {
        inactiveOrgIds.push(org.itemId);
        needsProfileUpdate = true;
      }

      // Update profile if either array changed
      if (needsProfileUpdate) {
        const profileRef = doc(firestore, 'profile/' + personId);
        batch.set(profileRef, { ...person, orgIds, inactiveOrgIds }, { merge: true });
      }

      await batch.commit();
    } catch (error) {
      console.error('Error deactivating org membership:', error);
    }
  };

  const deleteOrgMembership = async (org: Org, personId: string, isPermanent: boolean = false) => {
    try {
      const personRef = doc(firestore, 'profile', personId);
      const personDoc = await getDoc(personRef);
      const person = personDoc.data() as Person;
      if (person) {
        const batch = writeBatch(firestore);
        const membershipRef = doc(firestore, 'org/' + org.itemId + '/membership/' + personId);
        if (isPermanent) {
          batch.delete(membershipRef);
        } else {
          batch.update(membershipRef, { isMember: false, isActive: false });
        }

        // Handle orgIds and inactiveOrgIds arrays
        let needsProfileUpdate = false;
        const inactiveOrgIds = [...(person.inactiveOrgIds || [])];
        const orgIds = [...(person.orgIds || [])];

        // Remove from active if present
        const activeIndex = orgIds.indexOf(org.itemId);
        if (activeIndex !== -1) {
          orgIds.splice(activeIndex, 1);
          needsProfileUpdate = true;
        }

        // Remove from inactive if present
        const inactiveIndex = inactiveOrgIds.indexOf(org.itemId);
        if (inactiveIndex !== -1) {
          inactiveOrgIds.splice(inactiveIndex, 1);
          needsProfileUpdate = true;
        }

        // Update profile if either array changed
        if (needsProfileUpdate) {
          const profileRef = doc(firestore, 'profile/' + personId);
          batch.set(profileRef, { ...person, orgIds, inactiveOrgIds }, { merge: true });
        }
      } else {
        throw new Error('Person not found: ' + personId);
      }
    } catch (error) {
      alert(error);
    }
  };

  const addLeagueMembership = async (league: League, memberId: string): Promise<void> => {
    try {
      const membershipRef = doc(firestore, `org/${league.orgId}/membership/${memberId}`);
      const membershipDoc = await getDoc(membershipRef);

      if (!membershipDoc.exists()) {
        console.error('Membership not found:', memberId);
        return;
      }

      const membership = membershipDoc.data() as Membership;

      if (membership.leagues?.includes(league.itemId)) {
        console.log('Membership already has this league.');
        return;
      }

      const updateLeagues = {
        leagues: arrayUnion(membership.leagues, league.itemId),
      };

      // Update both membership and profile documents
      const batch = writeBatch(firestore);
      batch.update(membershipRef, updateLeagues);

      const profileRef = doc(firestore, 'profile', memberId);
      batch.update(profileRef, { leagues: arrayUnion(league.itemId) });

      await batch.commit();
      console.log('Membership updated with league:', league.itemId);
    } catch (error) {
      console.error('Error adding league membership:', error);
    }
  };

  return {
    updateMembership,
    addMembership,
    addOrgMembership,
    acceptOrgMembership,
    deactivateOrgMembership,
    deleteOrgMembership,
    addLeagueMembership,
  };
};
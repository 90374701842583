import { useEffect, useMemo, useState } from 'react';

import { and, collectionGroup, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Event from '../types/Event';

interface UseEventsProps {
  filter?: 'openForSignup' | 'upcoming' | 'all' | null;
}

export default function useEvents(props: UseEventsProps) {
  const { filter } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMemberOfAny } = useData();
  const [hasMemberships, setHasMemberships] = useState(isOrgMemberOfAny());

  // Add state to track the current time period
  const [timeCounter, setTimeCounter] = useState(0);

  useEffect(() => {
    setHasMemberships(isOrgMemberOfAny());
  }, [isOrgMemberOfAny]);

  // Create a single memoized time value that updates with timeCounter
  const currentDatetime = useMemo(() => new Date(Date.now() + timeCounter * 0), [timeCounter]);

  const next24Hours = useMemo(() => {
    const date = new Date(currentDatetime);
    date.setHours(date.getHours() + 24);
    return date;
  }, [currentDatetime]); // Use currentDatetime as the dependency

  // Add effect to update timeCounter every 24 hours
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeCounter((prev) => prev + 1);
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    return () => clearInterval(interval);
  }, []);

  // *************** Event loaders ***************

  const eventsQuery = useMemo(() => {
    // if user isn't logged in or filter is undefined, wait
    if (!userProfile || filter === undefined || hasMemberships === undefined) return null;

    // if filter is null, caller doesn't want to fetch anything
    if (filter === null) return null;

    if (!userProfile.orgIds || userProfile.orgIds.length === 0) return null;

    const conditions = [where('orgId', 'in', userProfile.orgIds), where('isTest', 'in', [isGod, false, null])];

    if (filter === 'openForSignup') {
      conditions.push(where('signupClosesAt', '>', currentDatetime), where('signupOpensAt', '<', next24Hours));
    } else if (filter === 'upcoming') {
      conditions.push(where('datetime', '>', currentDatetime));
    }

    return query(collectionGroup(firestore, 'event') as Query<Event>, and(...conditions), orderBy('datetime', 'asc'));
  }, [userProfile, filter, isGod, currentDatetime, next24Hours, hasMemberships]);

  const [events, isEventsLoading, eventsError] = useCollectionData<Event>(eventsQuery);

  if (!isUserLoading && !userProfile && !isEventsLoading && !eventsError && filter !== undefined) {
    return {
      events: [],
      isEventsLoading: false,
      eventsError: new Error('User must be logged in to view events'),
    };
  }

  if (userProfile && !isEventsLoading && !eventsError && hasMemberships === false && filter !== undefined) {
    return {
      events: [],
      isEventsLoading: false,
      eventsError: new Error('Not authorized to view these events'),
    };
  }

  // if filter is null, return null without error
  if (filter === null) {
    return {
      events: [],
      isEventsLoading: false,
      eventsError: null,
    };
  }

  return {
    events: events || [],
    isEventsLoading: isEventsLoading || isUserLoading,
    eventsError,
  };
}

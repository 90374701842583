import { 
  ReactElement, 
  useCallback, 
  useEffect, 
} from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import OrgEditor from '../components/org/OrgEditor';
import OrgView from '../components/org/OrgView';
import { useApp } from '../contextProviders/AppProvider';
import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import useOrg from '../dataHooks/useOrg';
import { useOrgMutators } from '../dataMutators/useOrgMutators';
import Org from '../types/Org';

interface OrgPageProps {
  edit?: boolean;
  new?: boolean;
  initialTab?: string;
}

export default function OrgPage(props: OrgPageProps): ReactElement {
  const { orgId } = useParams();
  const isEditMode = props.edit ?? false;
  const isNew = props.new ?? false;
  const navigate = useNavigate();

  const { updateActions, setPageName } = useApp();
  const { isGod } = useAuth();
  const { isOrgAdmin } = useData();
  const { addOrg, updateOrg, deleteOrg } = useOrgMutators();

  const tabSelected = props.initialTab ? props.initialTab : '1';
  const { org, isOrgLoading, orgError } = useOrg(orgId);

  useEffect(() => {
    if (isEditMode) {
      updateActions([]);
    } else {
      if (org && isGod) {
        updateActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org, isEditMode, isGod, updateActions]);

  useEffect(() => {
    if (isNew) {
      setPageName("Create Org");
    } else if (org) {
      setPageName(org.name);
    } else if (orgError) {
      setPageName(orgError.message);
    }
  }, [org, orgError, isNew, setPageName]);

  const handleEditClick = useCallback(() => {
    if (org) {
      navigate('/org-edit/' + org.itemId, { 
        replace: true,
        state: { preventReload: true} 
      });
    }
  }, [org, navigate]);

  const handleCancel = useCallback(() => {
    if (org && !isNew) {
      navigate('/org/' + org.itemId, { 
        replace: true,
        state: { preventReload: true} 
      });
    } else {
      navigate(-1);
    }
  }, [org, isNew, navigate]);

  const handleSubmit = useCallback((updatedOrg: Org, isAddedToUserProfile?: boolean) => {
    if (updatedOrg) {
      if (isNew) {
        addOrg(updatedOrg, true, updatedOrg.isTest, isAddedToUserProfile).then((addedOrg) => {
          if (addedOrg) {
            navigate('/org/' + addedOrg.itemId, { 
              replace: true,
            });
          } else {
            console.error("Org not added: ", addedOrg);
          }
        });
      } else {
        updateOrg(updatedOrg).then(() => {
          navigate('/org/' + updatedOrg.itemId, { 
            replace: true,
            state: { preventReload: true } 
          });
        });
      }
    }
  }, [isNew, navigate, addOrg, updateOrg]);

  const handleDelete = useCallback(() => {
    if (org) {
      console.log(`Deleting org ${org.itemId}`);
      deleteOrg(org).then(() => {
        navigate(-1);
      });
    } else {
      navigate(-1);
    }
  }, [org, deleteOrg, navigate]);

  return (
    <GratiPageWrapper isContentLoading={isOrgLoading && !org}>
      <>
        {isEditMode && (org && isOrgAdmin(org.itemId)) ? (
          <OrgEditor 
            org={org}
            handleSubmit={handleSubmit} 
            handleCancel={handleCancel} 
            handleDelete={isNew ? undefined : handleDelete}
          /> 
        ) : ( 
          (!isNew && org) ? (
            <>
              <OrgView org={org} initialTab={tabSelected} />
              {isOrgAdmin(org.itemId) &&
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add event"
                  component={Link}
                  to={'/invite-members/' + org.itemId}
                >
                  <AddIcon />
                  Add members
                </Fab>
              }
            </>
          ) : (
            <></>
          )
        )}
      </>
    </GratiPageWrapper>
  );
}
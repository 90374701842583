import { useMemo } from 'react';

import { collection, query, where, orderBy, Query, documentId } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Org from '../types/Org';

export default function useOrgsWithAdminRights() {
  const { isGod } = useAuth();
  const { getAdminOrgs } = useData();
  const adminOrgs = getAdminOrgs();
  console.log('adminOrgs: ', adminOrgs);
  
  const orgsQuery = useMemo(() => {
    if (!adminOrgs.length) return null;
    
    return query(
      collection(firestore, 'org'),
      where(documentId(), 'in', adminOrgs),
      where('isTest', 'in', [isGod, false, null]),
      orderBy('name')
    );
  }, [adminOrgs, isGod]);

  const [orgs, isOrgsLoading, orgsError] = useCollectionData<Org>(orgsQuery as Query<Org>);

  return {
    orgs,
    isOrgsLoading,
    orgsError
  };
}
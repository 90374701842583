import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { ref, uploadBytes, deleteObject } from 'firebase/storage';

import { useAuth } from '../contextProviders/AuthProvider';
import { firestore } from '../firebase';
import Person from '../types/Person';
import PersonDetails from '../types/PersonDetails';
const profilesCollection = collection(firestore, 'profile');

export const usePersonMutators = () => {
  const { imagesRef } = useAuth();
  
  const updatePersonDoc = async (id: string, person: Partial<Person>): Promise<void> => {
    try {
      const profileDoc = doc(firestore, 'profile', id);
      await setDoc(profileDoc, { ...person }, { merge: true });
      console.log('Profile updated:', person.name);
    } catch (error) {
      console.error('Error updating profile document:', error);
    }
  };
  
  const updatePerson = async (id: string, person: Partial<Person>, picFile?: File | null): Promise<void> => {
    if (!imagesRef) {
      console.error('User not found');
      return;
    }
  
    try {
      if (picFile) {
        // Upload new profile picture
        const pictureRef = ref(imagesRef, person.picture);
        await uploadBytes(pictureRef, picFile);
        console.log('Picture uploaded');
  
        await updatePersonDoc(id, person);
      } else if (picFile === null) {
        // Delete existing profile picture
        const pictureRef = ref(imagesRef, person.itemId);
        await deleteObject(pictureRef);
        console.log('Picture deleted');
  
        await updatePersonDoc(id, {
          ...person,
          picture: '',
          pictureName: '',
        });
      } else {
        // Just update profile without picture changes
        await updatePersonDoc(id, person);
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };
  
  const updatePersonDetails = async (id: string, details: Partial<PersonDetails>): Promise<void> => {
    try {
      const personDetailsRef = doc(firestore, 'profile', id, 'details', id);
      await setDoc(personDetailsRef, { ...details }, { merge: true });
    } catch (error) {
      console.error('Error updating person details:', error);
    }
  };
  
  const updateGHINIndex = async (ghin: string, index: string): Promise<void> => {
    try {
      const q = query(profilesCollection, where('ghin', '==', ghin));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        await updatePerson(doc.id, { index });
      }
    } catch (error) {
      console.error('Error updating user index:', error);
    }
  };

  return {
    updatePerson,
    updatePersonDetails,
    updateGHINIndex,
  };
};
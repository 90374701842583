import { useMemo } from 'react';

import { and, collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Membership from '../types/Membership';

interface UseMembershipsForLeagueProps {
  orgId?: string;
  leagueId?: string;
}

export default function useMembershipsForLeague(props: UseMembershipsForLeagueProps) {
  const { orgId, leagueId } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMember } = useData();

  const membershipsQuery = useMemo(() => {
    if (!orgId || !leagueId) return null;
    if (!isGod && !isOrgMember(orgId)) return null;

    return query(
      collection(firestore, 'org', orgId, 'membership'),
      and(
        where('isActive', '==', true),
        where('orgId', 'in', userProfile?.orgIds || []),
        where('leagues', 'array-contains', leagueId)
      ),
      orderBy('name')
    ) as Query<Membership>;
  }, [orgId, leagueId, isGod, userProfile?.orgIds, isOrgMember]);

  const [memberships, isMembershipsLoading, isMembershipsError] = useCollectionData<Membership>(membershipsQuery);

  if (!isUserLoading && !userProfile && !isMembershipsLoading && !isMembershipsError) {
    return {
      memberships: null,
      isMembershipsLoading: false,
      isMembershipsError: new Error('User must be logged in to view league memberships'),
    };
  }

  if (!isUserLoading && !isMembershipsLoading && !isMembershipsError && !isGod && orgId && !isOrgMember(orgId)) {
    return {
      memberships: null,
      isMembershipsLoading: false,
      isMembershipsError: new Error('Not authorized to view these memberships'),
    };
  }

  return {
    memberships: memberships ?? [],
    isMembershipsLoading: isMembershipsLoading || isUserLoading,
    isMembershipsError: isMembershipsError,
  };
}

import { Navigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';

import Hourglass from './Hourglass';

interface PrivateRouteProps {
  children: JSX.Element;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const { isLoggedIn, isLoggingIn } = useAuth();
  const { userProfile, isUserLoading } = useData();

  if (isLoggingIn || isUserLoading) {
    return <Hourglass />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (userProfile === null) {
    return (
      <Typography variant="h6" sx={{ p: 2 }}>
        Error: Your profile failed to load. Please try again later.
      </Typography>
    );
  }

  return children;
}
import { useMemo } from 'react';

import {
  and,
  collection,
  collectionGroup,
  or,
  orderBy,
  Query,
  query,
  where,
} from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import League from '../types/League';
import Person from '../types/Person';

import useMembershipsOfPerson from './useMembershipsOfPerson';

export interface UseLeaguesProps {
  includeCatchAll?: boolean;
  includeLeagueAdmined?: boolean;
  includeOrgAdmined?: boolean;
  includeLeagueMember?: boolean;
  includeOrgMember?: boolean;
  orgId?: string | null;
  person?: Person | null;
}

// IMPORTANT: The requester can only see leagues of another person if they are members in the same orgs. 
// (god is the exception)
// This is because anyone in an org can see other org member's league memberships even if they are not in one of 
// those leagues.
// But, you can't see memberships in orgs that you are not a member of.
export default function useLeagues(props: UseLeaguesProps) {
  const {
    includeCatchAll = false,
    includeLeagueAdmined = false,  // if true, include all leagues in membership.leagueAdmins[]
    includeOrgAdmined = false,     // if true, include all leagues in orgs in membership.orgAdmins[]
    includeLeagueMember = true,   // if true, include all leagues in membership.leagues[]
    includeOrgMember = false,     // if true, include all leagues in all memberships
    orgId,
    person,
  } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMemberOfAny } = useData();

  const { memberships, isMembershipsLoading, membershipsError } = useMembershipsOfPerson({
    personId: person?.itemId ?? null,
    orgId,
  });

  const leaguesQuery = useMemo(() => {
    if (!userProfile || !person || !memberships || isMembershipsLoading) return null;

    // Get admin memberships
    const adminOrgIds =
      !orgId && includeOrgAdmined
        ? memberships.flatMap(m => m.orgId && m.isAdmin ? m.orgId : [])
        : [];
    const memberOrgIds =
      !orgId && includeOrgMember
        ? memberships.flatMap(m => m.orgId ? m.orgId : [])
        : [];
    const adminOrgLeagueIdPairs = includeLeagueAdmined
      ? memberships.flatMap(m => m.leagueAdmins && m.leagueAdmins.length > 0 ? m.leagueAdmins.flatMap(leagueAdmin => {
        const leagueIds = leagueAdmin.split(',');
        return leagueIds.map(leagueId => ({ orgId: m.orgId, leagueId }));
      }) : []) : [];

    const memberOrgLeagueIdPairs = includeLeagueMember
      ? memberships.flatMap(m => m.leagues && m.leagues.length > 0 
        ? m.leagues.flatMap(league => league.split(',').flatMap(leagueId => ({ orgId: m.orgId, leagueId }))) 
        : [])
      : [];

    console.log('adminOrgIds', adminOrgIds);
    console.log('memberOrgIds', memberOrgIds);
    console.log('adminOrgLeagueIdPairs', adminOrgLeagueIdPairs);
    console.log('memberOrgLeagueIdPairs', memberOrgLeagueIdPairs);

    const orgIds = Array.from(new Set([...adminOrgIds, ...memberOrgIds])).flatMap(o => o);
    const mergedOrgLeagueIdPairs = Array.from(new Set([...adminOrgLeagueIdPairs, ...memberOrgLeagueIdPairs])).flatMap(l => l);
    const leagueIds = mergedOrgLeagueIdPairs.map(l => l.leagueId).flatMap(l => l);
    const leagueOrgIds = mergedOrgLeagueIdPairs.map(l => l.orgId).flatMap(o => o);

    console.log('** orgIds', orgIds);
    console.log('** leagueIds', leagueIds);
    console.log('** leagueOrgIds', leagueOrgIds);

    if (!orgId && orgIds.length === 0 && leagueOrgIds.length === 0) return null;

    const leagueConditions = [];
    const orgConditions = [];
    const catchAllConditions = [];
    const deletedConditions = [];
    const testConditions = [];

    if (!includeCatchAll) {
      catchAllConditions.push(where('isCatchAll', '==', false));
    }

    if (!isGod) {
      testConditions.push(where('isTest', '==', false));
      deletedConditions.push(where('isDeleted', '==', false));
    }

    // If a specific org's leagues are requested, or if the user only belongs to a single org, then
    // we don't need to use a collectionGroup query.
    if (orgId || orgIds.length === 1) {
      console.log('** orgId', orgId);
      const queryOrgId = orgId || orgIds[0];

      orgConditions.push(where('orgId', '==', queryOrgId));

      if (leagueIds.length > 0) {
        leagueConditions.push(where('itemId', 'in', leagueIds));
      }

      return query(
        collection(firestore, 'org', queryOrgId, 'league') as Query<League>,
        ...leagueConditions,
        ...orgConditions,
        ...deletedConditions,
        ...catchAllConditions,
        ...testConditions,
        orderBy('name')
      );
    } else {
      // Otherwise use collectionGroup query with org restrictions
      if (orgIds.length > 0) {
        orgConditions.push(where('orgId', 'in', orgIds));
      }

      if (leagueIds.length > 0) {
        console.log('**** leagueIds ', leagueIds);
        console.log('**** leagueOrgs ', leagueOrgIds);
        leagueConditions.push(where('itemId', 'in', leagueIds), where('orgId', 'in', leagueOrgIds));
      }

      return query(
        collectionGroup(firestore, 'league') as Query<League>,
        and(
          or(
            ...orgConditions, 
            and(...leagueConditions),
          ),
          ...deletedConditions,
          ...catchAllConditions,
          ...testConditions,
        ),
        orderBy('name')
      );
    }
  }, [includeCatchAll, 
    includeLeagueAdmined, 
    includeOrgAdmined, 
    includeLeagueMember, 
    includeOrgMember, 
    isGod, 
    memberships, 
    orgId, 
    person, 
    userProfile, 
    isMembershipsLoading]);

  const [leagues, isLeaguesLoading, leaguesError] = useCollectionData<League>(leaguesQuery);

  // Move error checks before using the query
  if (!isUserLoading && !userProfile) {
    return {
      leagues: [],
      isLeaguesLoading: false,
      leaguesError: new Error('User must be logged in to view leagues'),
    };
  }

  if (membershipsError) {
    return {
      leagues: [],
      isLeaguesLoading: false,
      leaguesError: membershipsError,
    };
  }

  if (!isUserLoading && !isLeaguesLoading && !leaguesError && !isOrgMemberOfAny()) {
    return {
      leagues: [],
      isLeaguesLoading: false,
      leaguesError: new Error('Not authorized to view these leagues'),
    };
  }

  // if the query qualifiers are passed in as null, then don't return anything. Not an error either.
  // It just means that the calling component wanted nothing fetched.
  if (person === null) {
    return {
      leagues: [],
      isLeaguesLoading: false,
    };
  }

  return {
    leagues: leagues || [],
    isLeaguesLoading: isLeaguesLoading || isUserLoading || isMembershipsLoading,
    leaguesError: leaguesError || membershipsError,
  };
}

import { ReactElement, useEffect } from 'react';

import { Timestamp } from 'firebase/firestore';

import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import SeasonEditor from '../components/season/SeasonEditor';
import { useApp } from '../contextProviders/AppProvider';
import { useData } from '../contextProviders/DataProvider';
import useLeague from '../dataHooks/useLeague';
import DefaultObjects from '../types/DefaultObjects';
import Person from '../types/Person';
import Season from '../types/Season';

export default function SeasonCreatePage(): ReactElement {
  const { orgId, leagueId } = useParams();
  const { setPageName } = useApp();
  const { isLeagueAdmin, userProfile } = useData();
  const { league, isLeagueLoading, leagueError } = useLeague({ orgId, leagueId });

  const createNewSeason = (): Season => {
    if (!league) {
      throw new Error('League not found');
    }

    // we'll default to assuming a season is this year.
    const currentYear = new Date().getFullYear();
    const startTime = Timestamp.fromDate(new Date(currentYear, 0, 1, 0, 0, 0, 0));
    const endTime = Timestamp.fromDate(new Date(currentYear, 11, 31, 23, 59, 59, 999));

    return {
      ...DefaultObjects.Season,
      creatorId: userProfile?.itemId || '',
      orgId: league.orgId,
      orgName: league.orgName,
      leagueId: league.itemId,
      leagueName: league.name,
      startTime,
      endTime,
    };
  };

  useEffect(() => {
    setPageName('Create Season');
  }, [setPageName]);

  if (leagueError) {
    return <Typography color="error">{leagueError.message}</Typography>;
  }

  const validateUser = (userProfile: Person | null | undefined) => {
    return userProfile && leagueId && isLeagueAdmin(leagueId, league?.orgId || '') ? true : false;
  };

  return (
    <GratiPageWrapper isContentLoading={isLeagueLoading && !league} validateUser={validateUser}>
      <SeasonEditor season={createNewSeason()} isNew={true} />
    </GratiPageWrapper>
  );
}

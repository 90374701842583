import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import PersonDetails from '../types/PersonDetails';

export default function usePersonDetails(personId: string | undefined) {
  const { userProfile, isUserLoading } = useData();

  const personDetailsRef = useMemo(() => {
    if (!personId) return null;

    return doc(collection(firestore, 'profile', personId, 'details'), personId) as DocumentReference<PersonDetails>;
  }, [personId]);

  const [personDetails, isPersonDetailsLoading, personDetailsError] = useDocumentData<PersonDetails>(personDetailsRef);

  if (!isUserLoading && !userProfile && !isPersonDetailsLoading && !personDetailsError) {
    return {
      personDetails: null,
      isPersonDetailsLoading: false,
      personDetailsError: new Error('User must be logged in to view person details')
    };
  }

  return {
    personDetails: personDetails || null,
    isPersonDetailsLoading: isPersonDetailsLoading || isUserLoading,
    personDetailsError
  };
}

import { getDoc } from "firebase/firestore";
import { collection, deleteDoc, doc, setDoc, Timestamp } from "firebase/firestore";

import { useAuth } from "../contextProviders/AuthProvider";
import { useData } from "../contextProviders/DataProvider";
import { firestore } from "../firebase";
import Course from "../types/Course";

export const useCourseMutators = () => {
  const { isGod } = useAuth();
  const { isOrgAdmin, userProfile } = useData();

  const addCourse = async (course: Course, isTest = false): Promise<Course | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!isGod) {
      console.error('Permission denied: only admins can add courses');
      return null;
    }

    try {
      const courseRef = doc(collection(firestore, 'course'));

      const newCourse = {
        ...course,
        creatorId: userProfile.itemId,
        createTime: Timestamp.now(),
        itemId: courseRef.id,
        isTest,
      };

      await setDoc(courseRef, newCourse);
      return newCourse;
    } catch (error) {
      console.error('Error adding course:', error);
      return null;
    }
  };

  const deleteCourse = async (course: Course): Promise<void> => {
    if (!isGod) {
      console.error('Permission denied: only admins can delete courses');
      return;
    }

    if (!course.itemId) {
      console.error('Course itemId not found');
      return;
    }

    try {
      const courseRef = doc(firestore, 'course/' + course.itemId);
      await deleteDoc(courseRef);
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  const updateCourse = async (course: Partial<Course>): Promise<void> => {
    if (!course.itemId) {
      console.error('Course itemId not found');
      return;
    }

    // Only check org admin rights if an orgId is specified
    if (!isGod && course.orgId && !isOrgAdmin(course.orgId)) {
      console.error("Permission denied: must be admin of course's organization");
      return;
    }

    try {
      const courseRef = doc(firestore, 'course/' + course.itemId);

      // First get the existing course to preserve fields
      const existingCourse = await getDoc(courseRef);
      if (!existingCourse.exists()) {
        console.error('Course not found');
        return;
      }

      const existingData = existingCourse.data();
      const updatedCourse = {
        ...existingData,
        ...course,
        isTest: course.isTest ?? existingData.isTest, // Explicitly preserve isTest
      };

      await setDoc(courseRef, updatedCourse);
      console.log('Updated course with isTest:', updatedCourse.isTest); // Debug log
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  return {
    addCourse,
    deleteCourse,
    updateCourse,
  };
};

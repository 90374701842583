import { ReactElement, useEffect, useRef, useState } from 'react';

import { collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Filter9Icon from '@mui/icons-material/Filter9';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import { useEventRegistrationMutators } from '../../dataMutators/useEventRegistrationMutators';
import { firestore } from '../../firebase';
import Event from '../../types/Event';
import Person from '../../types/Person';
import Registration from '../../types/Registration';
import Hourglass from '../common/Hourglass';
import PersonAutocomplete from '../person/PersonAutocomplete';
type EventAttendeesProps = {
  event: Event;
  isEditable?: boolean;
};

export default function EventAttendees(props: EventAttendeesProps): ReactElement {
  const event = props.event;
  const isEditable = props.isEditable;

  const { addEventRegistration } = useEventRegistrationMutators();
  const personSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Person | null>(null);
  const [regList, setRegList] = useState<Registration[]>([]);
  const [waitList, setWaitList] = useState<Registration[]>([]);

  const qReg = query(
    collection(firestore, 'org/' + event.orgId + '/event/' + event.itemId + '/reg'),
    where('isRegistered', '==', true),
    orderBy('datetime')
  ) as Query<Registration>;

  const [regs, isRegLoading, isRegError] = useCollectionData<Registration>(qReg);

  useEffect(() => {
    if (isRegLoading) {
      console.log('Loading registrations');
    } else if (regs) {
      if (event.maxPlayers !== 0 && regs.length > event.maxPlayers) {
        setRegList(regs.slice(0, event.maxPlayers));
        setWaitList(regs.slice(event.maxPlayers, regs.length));
      } else {
        setRegList(regs);
        setWaitList([]);
      }
    } else if (isRegError) {
      console.log('Error loading registrations: ' + isRegError);
    }
  }, [regs, isRegLoading, isRegError, event.maxPlayers]);

  const handleSelectionChange = (event: React.SyntheticEvent, option: Person | null /* reason: string */) => {
    if (option !== null) {
      console.log('Selected in Attendeelist: ' + option?.name);
    } else {
      console.log('Selected in Attendeelist: null');
    }
    personSelected.current = option;
  };

  const handleAddPerson = () => {
    if (personSelected.current !== null) {
      console.log('Adding person: ' + personSelected.current.name);
      addEventRegistration(event, true, personSelected.current);
      setAddPersonFieldValue(null);
    }
  };

  interface AttendeeRowProps {
    attendee: Registration;
    partner?: Registration | null;
  }

  // For MemberMember events, assumes that if a partnership has been established,
  // the attendee has the partner info and the partner may not have registered or confirmed the partnership.
  const AttendeeRow = (props: AttendeeRowProps): ReactElement => {
    const attendee = props.attendee;
    const partner = props.partner;

    console.log("AttendeeRow: partner: ", partner);
    const isPartnerPending = event?.isMemberMember && (partner && !partner.partnerId);
    const isSecondaryAction = !(attendee.isPlayingGame && attendee.isEighteen && attendee.isRiding);

    const secondaryAction = 
      isSecondaryAction ? 
        <Stack direction="row" component={'span'}
          spacing={0.25} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            flexDirection: 'row', 
            alignItems: 'center', 
            color: 'surface.tint'}}>
          {!(attendee.isPlayingGame) && <MoneyOffIcon fontSize='small'/>}
          {!(attendee.isEighteen) && <Filter9Icon fontSize='small'/>}
          {!attendee.isRiding && <DirectionsWalkIcon fontSize='small'/>}
        </Stack> 
      : null;

    return (
      <ListItem 
        key={attendee.playerId} 
        disableGutters 
        component="li" 
        sx={{ 
          display: 'list-item',
          flexDirection: 'row',
          alignItems: 'center',
          pl: 1, // Add some padding after the number
          py: 0,
          // These ensure content stays in one line after the number
          '& > *': {
            display: {xs: 'inline-flex', sm: 'flex', md: 'inline-flex'}, 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }
        }}
      >
        <ListItemText 
          primary={attendee.playerName} 
          primaryTypographyProps={{color: "surface.contrastText"}}
          secondary={secondaryAction}
          secondaryTypographyProps={{display: isSecondaryAction ? 'flex' : 'none'}} 
          sx={{ pr: 1 }}
        />
        {attendee?.partnerName && (event?.isMemberMember || event?.isMemberGuest) && (
          <ListItemText 
            primary={"(" + attendee.partnerName + ")"} 
            sx={{ fontStyle: isPartnerPending ? 'italic' : 'normal', color: isPartnerPending ? 'surface.light' : 'surface.onVariant'}}
          />
        )}
      </ListItem>
    );
  };

  const AttendeesByPartner = (): [Registration, Registration | null][] => {
    const attendees: [Registration, Registration | null][] = [];
    for (let i = 0; i < regList.length; i++) {
      const attendee = regList[i];
      const partner =
        regList.find((reg) => reg.playerId === attendee?.partnerId || reg.partnerId === attendee?.playerId) || null;
      if (
        attendees.find(
          (a) =>
            a[0].playerId === attendee?.playerId ||
            a[0].partnerId === attendee?.playerId ||
            a[0].playerId === attendee?.partnerId
        )
      ) {
        continue;
      }
      if (partner && attendee.partnerId !== partner.playerId) {
        attendees.push([partner, attendee]);
      } else {
        attendees.push([attendee, partner]);
      }
    }
    return [...attendees].sort((a, b) => a[0].playerName.localeCompare(b[0].playerName));
  };

  if (!isRegLoading && regList) {
    let count = 0;
    return (
      <Stack sx={{width: 1}}>
          <Typography variant="headline" gutterBottom>
            Attendees
          </Typography>
          <List
            component="ol"
            dense={true}
            sx={{
              pl: 2,
              pr: 1,
              listStyleType: 'decimal',
            }}
            >
            {AttendeesByPartner().map((attendee) => {
              count++;
              return <AttendeeRow key={count} attendee={attendee[0]} partner={attendee[1]} />;
            })}
          </List>
        {waitList.length > 0 && (
          <Stack>
            <Typography variant="h5" component="h5" gutterBottom>
              Wait List
            </Typography>
            <List
            component="ol"
            dense={true}
            sx={{
              pl: 2,
              pr: 1,
              listStyleType: 'decimal',
            }}
            >
              {[...waitList]
                .sort((a, b) => (a.datetime < b.datetime ? -1 : 1))
                .map((attendee) => {
                  return <AttendeeRow key={attendee.playerId} attendee={attendee} />;
                })}
            </List>
          </Stack>
        )}
        <Stack direction="row" spacing={0.5} sx={{py: 1, justifyContent: "flex-start", alignItems: "center", color: 'surface.tint'}}>
          <MoneyOffIcon/>
          <Typography variant="body2" sx={{pr: 1}}>No Game</Typography>
          <Filter9Icon />
          <Typography variant="body2" sx={{pr: 1}}>9 Holes</Typography>
          <DirectionsWalkIcon />
          <Typography variant="body2" sx={{pr: 1}}>Walking</Typography>
        </Stack>
        {isEditable && (
          <Stack direction="row" spacing={2} sx={{justifyContent: "flex-start", alignItems: "center"}}>
            <PersonAutocomplete
              id="addPerson"
              size="small"
              label="Person to add"
              selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ''}
              handleOnChange={handleSelectionChange}
            />
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={() => handleAddPerson()}
            >
              Add
            </Button>
          </Stack>
        )}
      </Stack>
    );
  } else if (isRegLoading) {
    return <Hourglass />;
  } else {
    if (isRegError) {
      console.log('Error loading registrations: ' + isRegError);
    }
    return <Box />;
  }
}

import { ReactElement, useEffect, useRef } from 'react';

import { Dayjs } from 'dayjs';

import { PickersDay } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export interface EventDateCalendarProps {
  isMultiDay?: boolean;
  currentDate?: Dayjs;
  value?: Dayjs[];
  defaultValue?: Dayjs[];
  onChange?: (newDates: Dayjs[] | null) => void;
  orientation?: 'portrait' | 'landscape';
}

export default function EventDateCalendar(props: EventDateCalendarProps): ReactElement {
  const { isMultiDay, value, onChange } = props;
  const refDates = useRef<Dayjs[]>(value ?? []);

  useEffect(() => {
    refDates.current = value ?? [];
  }, [value]);

  useEffect(() => {
    if (!isMultiDay && refDates.current.length > 1) {
      const firstDate = refDates.current.reduce(
        (acc, current) => (current.valueOf() < acc.valueOf() ? current : acc),
        refDates.current[0]
      );
      refDates.current = [firstDate];
      onChange?.(refDates.current);
    }
  }, [isMultiDay, onChange]);

  function isSameDay(a: Dayjs, b: Dayjs) {
    return a.year() === b.year() && a.month() === b.month() && a.date() === b.date();
  }

  const handleDayClick = (day: Dayjs) => {
    if (isMultiDay) {
      if (refDates.current && refDates.current.some((d) => isSameDay(d, day))) {
        if (refDates.current.length > 1) {
          refDates.current = refDates.current.filter((d) => !isSameDay(d, day));
        }
      } else {
        refDates.current = day ? [...refDates.current, day] : [];
      }
    } else {
      refDates.current = [day];
    }

    onChange?.(refDates.current);
  };

  return (
    <StaticDatePicker
      value={props.currentDate}
      orientation={props.orientation}
      defaultValue={refDates.current[0]}
      slots={{
        day: (props) => {
          const isSelected = refDates.current.some((day) => isSameDay(day, props.day));
          return <PickersDay {...props} selected={isSelected} onClick={() => handleDayClick(props.day)} />;
        },
      }}
      slotProps={{
        toolbar: {
          sx: {
            display: 'none',
          },
        },
        actionBar: {
          actions: [],
        },
      }}
    />
  );
}

import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Person from '../types/Person';

export default function usePerson(personId: string | undefined | null) {
  const { userProfile, isUserLoading } = useData();

  const personRef = useMemo(() => {
    if (!personId) return null;

    return doc(collection(firestore, 'profile'), personId) as DocumentReference<Person>;
  }, [personId]);

  const [person, isPersonLoading, personError] = useDocumentData<Person>(personRef);

  if (!isUserLoading && !userProfile && !isPersonLoading && !personError) {
    return {
      person: null,
      isPersonLoading: false,
      personError: new Error('User must be logged in to view profiles'),
    };
  }

  if (personId === null) {
    return {
      person: null,
      isPersonLoading: false,
      personError: null,
    };
  }

  return {
    person: person || null,
    isPersonLoading: isPersonLoading || isUserLoading,
    personError,
  };
}

import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Season from '../types/Season';

interface UseSeasonProps {
  orgId?: string;
  seasonId?: string;
}

export default function useSeason(props: UseSeasonProps) {
  const { orgId, seasonId } = props;

  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMember } = useData();

  // Only create document reference if we have all required data and user is authorized
  const seasonRef = useMemo(() => {
    if (!orgId || !seasonId) return null;

    return doc(collection(firestore, 'org', orgId, 'season'), seasonId) as DocumentReference<Season>;
  }, [orgId, seasonId]);

  const [season, isSeasonLoading, seasonError] = useDocumentData<Season>(seasonRef);

  if (!isUserLoading && !userProfile && !isSeasonLoading && !seasonError) {
    return {
      season: null,
      isSeasonLoading: false,
      seasonError: new Error('User must be logged in to view seasons'),
    };
  }

  if (!isUserLoading && !isSeasonLoading && !seasonError && !isGod && orgId && !isOrgMember(orgId)) {
    return {
      season: null,
      isSeasonLoading: false,
      seasonError: new Error('Not authorized to view this season'),
    };
  }

  return {
    season: season || null,
    isSeasonLoading: isSeasonLoading || isUserLoading,
    seasonError,
  };
}

import { ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Season from '../../types/Season';

import SeasonListItem from './SeasonListItem';

interface SeasonDirectoryProps {
  seasons: Season[] | null;
}

export default function SeasonDirectory(props: SeasonDirectoryProps): ReactElement {
  const { seasons } = props;

  const hasMultipleOrgs = seasons?.length ? new Set(seasons.map((season) => season.orgId)).size > 1 : false;
  const hasMultipleLeagues = seasons?.length ? new Set(seasons.map((season) => season.leagueId)).size > 1 : false;

  return (
    <Stack spacing={2}>
      <Typography variant="headline">Seasons</Typography>
      {seasons?.map((season) => (
        <SeasonListItem 
          key={season.itemId} 
          season={season} 
          isOrgNameDisplayed={hasMultipleOrgs} 
          isLeagueNameDisplayed={hasMultipleLeagues} 
        />
      ))}
    </Stack>
  );
}

import { ReactNode, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useApp } from '../../contextProviders/AppProvider';
import { useData } from '../../contextProviders/DataProvider';
import Person from '../../types/Person';

import Hourglass from './Hourglass';

interface GratiPageWrapperProps {
  children: ReactNode;
  isContentLoading?: boolean;
  isAuthRequired?: boolean;
  isNoAuthRequired?: boolean;
  validateUser?: (userProfile: Person | null | undefined) => boolean;
}

export default function GratiPageWrapper(props: GratiPageWrapperProps) {
  const { children, validateUser } = props;
  const isNoAuthRequired = props.isNoAuthRequired ?? false;
  const isAuthRequired = props.isAuthRequired ?? !isNoAuthRequired;
  const isContentLoading = props.isContentLoading ?? false;

  const navigate = useNavigate();
  const { updateActions } = useApp();
  const { userProfile, isUserLoading } = useData();

  const [ isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    return () => {
      updateActions([]);
    };
  }, [updateActions]);

  useEffect(() => {
    async function loadPage() {
      if (isNoAuthRequired && userProfile) {
        navigate('/');
      } else if (isAuthRequired && !userProfile) {
        console.log("auth required isAuthRequired: ", isAuthRequired, "userProfile: ", userProfile);
        navigate('/login');
      } else {
        setIsPageLoading(false);
      }
    }
    
    if (!isContentLoading && !isUserLoading && (userProfile || isNoAuthRequired)) {
      loadPage();
    }
  }, [userProfile, isAuthRequired, isNoAuthRequired, isUserLoading, isContentLoading, navigate]);

  if (isPageLoading) {
    return <Hourglass />;
  } else {
    return (
      <>
        {(!validateUser || validateUser(userProfile)) ?
          children :
          <Box>
            <Typography component="h6">
              You aren&apos;t allowed to do this.
            </Typography> 
          </Box>
        }
      </>
    );
  }
}

import { collection, doc, deleteDoc, setDoc, Timestamp } from 'firebase/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Handbook from '../types/Handbook';

export const useHandbookMutators = () => {
  const { isOrgAdmin, userProfile } = useData();
  const { isGod } = useAuth();

  const addHandbook = async (handbook: Handbook, isTest = false): Promise<Handbook | null> => {
    if (!userProfile) {
      console.error('User profile not found');
      return null;
    }

    if (!handbook.orgId) {
      console.error('Handbook orgId not found');
      return null;
    }

    if (!isOrgAdmin(handbook.orgId)) {
      console.error('Permission denied: must be admin of organization');
      return null;
    }

    try {
      const handbookRef = doc(collection(firestore, `org/${handbook.orgId}/handbook`));
      const newHandbook: Handbook = {
        ...handbook,
        creatorId: userProfile.itemId,
        createTime: Timestamp.now(),
        itemId: handbookRef.id,
        history: [],
      };

      await setDoc(handbookRef, newHandbook);
      return newHandbook;
    } catch (error) {
      console.error('Error adding handbook:', error);
      return null;
    }
  };

  const deleteHandbook = async (handbook: Handbook, isPermanent = false): Promise<void> => {
    if (!handbook.itemId || !handbook.orgId) {
      console.error('Handbook itemId or orgId not found:', handbook.itemId, handbook.orgId);
      return;
    }

    if (!isOrgAdmin(handbook.orgId)) {
      console.error('Permission denied. User:', userProfile?.itemId, 'Handbook:', handbook.itemId);
      return;
    }

    try {
      const handbookRef = doc(firestore, `org/${handbook.orgId}/handbook/${handbook.itemId}`);

      if (isGod && isPermanent) {
        await deleteDoc(handbookRef);
      } else {
        await setDoc(handbookRef, { isDeleted: true }, { merge: true });
      }
    } catch (error) {
      console.error('Error deleting handbook:', error);
    }
  };

  const updateHandbook = async (handbook: Partial<Handbook>): Promise<void> => {
    if (!handbook.orgId || !handbook.itemId) {
      console.error('Missing required fields:', { orgId: handbook.orgId, itemId: handbook.itemId });
      return;
    }

    if (!isOrgAdmin(handbook.orgId)) {
      console.error('Permission denied: must be admin of organization');
      return;
    }

    try {
      const { orgId, itemId, history, ...handbookUpdates } = handbook;
      const handbookRef = doc(firestore, `org/${orgId}/handbook/${itemId}`);
      await setDoc(handbookRef, { ...handbookUpdates }, { merge: true });
    } catch (error) {
      console.error('Error updating handbook:', error);
    }
  };

  return {
    addHandbook,
    deleteHandbook,
    updateHandbook,
  };
};

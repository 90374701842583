import { alpha, createTheme, Theme } from '@mui/material/styles';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

import type {} from '@mui/lab/themeAugmentation';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-condensed/300.css';

type GratiScorecardClassKey = 'root' | 'row' | 'rowlabel' | 'cell' | 'header' | 'title' | 'total';

export const APP_BAR_HEIGHT = 8;

declare module '@mui/material/styles' {
  interface Components {
    GratiCard?: {
      variants?: {
        props: {
          variant: 'elevation' | 'outlined' | 'test' | 'none';
        };
        style: React.CSSProperties;
      }[];
      styleOverrides?: Partial<OverridesStyleRules<string, 'GratiCard', Theme>>;
    };
    GratiScorecard?: {
      styleOverrides?: Partial<OverridesStyleRules<GratiScorecardClassKey, 'GratiScorecard', Theme>>;
    };
  }
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    inactive?: PaletteOptions['primary'];
    unpublished?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
    surface?: PaletteOptions['primary'];
    outline?: PaletteOptions['primary'];
    test?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    on?: string;
    container?: string;
    onContainer?: string;
    outline?: string;
    outlineVariant?: string;
    dim?: string;
    tint?: string;
    variant?: string;
    onVariant?: string;
    bright?: string;
    containerLowest?: string;
    containerLow?: string;
    containerHigh?: string;
    containerHighest?: string;
  }

  interface SimplePaletteColorOptions {
    container?: string;
    onContainer?: string;
    outline?: string;
    outlineVariant?: string;
    fixed?: string;
    onFixed?: string;
    fixedDim?: string;
    onFixedVariant?: string;
    inverse?: string;
    onInverse?: string;
    dim?: string;
    tint?: string;
    variant?: string;
    onVariant?: string;
    bright?: string;
  }

  interface PaletteBackgroundChannel {
    default: string;
    on?: string;
  }

  // headline (h5) - top of the page
  // title (h6) - page section titles
  // h6dense (h6) - (dense variant)
  // pdense (p) - (dense variant)
  // iconbutton (body1) - navigation button label

  interface TypographyVariants {
    headline: React.CSSProperties;
    title: React.CSSProperties;
    h6dense: React.CSSProperties;
    label: React.CSSProperties;
    pdense: React.CSSProperties;
    iconbutton: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headline?: React.CSSProperties;
    title?: React.CSSProperties;
    h6dense?: React.CSSProperties;
    label?: React.CSSProperties;
    pdense?: React.CSSProperties;
    iconbutton?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headline: true;
    title: true;
    h6dense: true;
    label: true;
    pdense: true;
    iconbutton: true;
  }
}

const iPhoneInput = {
  styleOverrides: {
    root: {
      '*': {
        userSelect: 'text !important' /* Standard syntax */,
      },
    },
  },
};

const mui6Theme = createTheme({
  components: {
    MuiTextField: iPhoneInput,
    MuiInput: iPhoneInput,
    MuiInputBase: iPhoneInput,
    MuiFilledInput: iPhoneInput,
    MuiOutlinedInput: iPhoneInput,
    GratiCard: {
      variants: [
        {
          props: { variant: 'elevation' },
          style: {
            border: 'none',
            backgroundColor: 'surface.variant',
            color: 'surface.onVariant',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '1rem',
            border: '.1rem solid',
            borderColor: 'outline.main',
            backgroundColor: 'surface.variant',
            color: 'surface.onVariant',
          },
        },
        {
          props: { variant: 'test' },
          style: {
            borderRadius: '1rem',
            border: '.2rem dotted',
            borderColor: 'test.main',
            backgroundColor: 'surface.variant',
            color: 'surface.onVariant',
          },
        },
        {
          props: { variant: 'none' },
          style: {
            borderRadius: 0,
            border: 'none',
            backgroundColor: 'surface.main',
            color: 'surface.contrastText',
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            my: 1,
          }),
        overline: ({ theme }) =>
          theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'primary.container',
            color: 'primary.onContainer',
            textTransform: 'uppercase',
            fontSize: theme.spacing(2),
            letterSpacing: theme.spacing(0.2),
            pt: 0,
            pl: 0,
          }),
        tagline: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'secondary.container',
            color: 'secondary.onContainer',
            marginBottom: '0.35em',
            fontSize: theme.spacing(2),
            pr: 1,
            py: 1,
            flexGrow: 1,
          }),
        avatar: ({ theme }) =>
          theme.unstable_sx({
            width: [theme.spacing(4), theme.spacing(5)],
            height: [theme.spacing(4), theme.spacing(5)],
            transform: 'translateY(50%)',
            '& > img': {
              m: 0,
              backgroundColor: 'primary.container',
              color: 'primary.onContainer',
            },
          }),
        title: ({ theme }) =>
          theme.unstable_sx({
            typography: 'h6',
            fontWeight: 900,
            color: 'surface.contrastText',
          }),
        subtitle: ({ theme }) =>
          theme.unstable_sx({
            typography: 'h6',
          }),
        location: ({ theme }) =>
          theme.unstable_sx({
            typography: 'body1',
            fontWeight: 400,
            lineHeight: theme.spacing(1.5),
            fontSize: theme.spacing(2),
            mb: 0.5,
            color: 'surface.onVariant',
          }),
        description: ({ theme }) =>
          theme.unstable_sx({
            typography: 'body1',
            mb: 0.5,
            color: 'surface.onVariant',
          }),
        footer: ({ theme }) =>
          theme.unstable_sx({
            typography: 'body1',
            fontWeight: 100,
            lineHeight: theme.spacing(1),
            fontSize: theme.spacing(1.5),
            mb: 0.5,
            color: 'surface.onVariant',
          }),

        actionbar: ({ theme }) =>
          theme.unstable_sx({
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'flex-end',
          }),
      },
    },
    GratiScorecard: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexGrow: 0,
            width: '100%',
            padding: '0rem',
            overflowX: 'auto',
            overflowY: 'auto',
            overflowWrap: 'break-word',
            backgroundColor: 'surface.main',
            color: 'surface.contrastText',
            fontSize: '.8rem', // Adjust as needed
            fontFamily: { xs: 'Roboto Condensed', sm: 'Roboto' },
          }),
        row: ({ theme }) =>
          theme.unstable_sx({
            flexDirection: 'row',
            flexGrow: 0,
            mb: '.25rem',
          }),
        rowlabel: ({ theme }) =>
          theme.unstable_sx({
            display: 'flex',
            flexShrink: 0,
            justifyContent: 'left',
            alignItems: 'center',
            textAlign: 'left',
            width: { xs: '5rem', sm: '7rem', md: '9rem' },
            padding: '0rem',
            pl: '.5rem',
            typography: {
              textAlign: 'left',
            },
            fontSize: '.8rem', // Adjust as needed
            fontFamily: { xs: 'Roboto Condensed', sm: 'Roboto' },
          }),
        cell: ({ theme }) =>
          theme.unstable_sx({
            display: 'flex',
            flexShrink: 0,
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0rem',
            width: { xs: '1.3rem', md: '1.4rem' },
            height: { xs: '1.3rem', md: '1.4rem' },
            my: '.1rem',
            py: '.1rem',
            fontSize: '.8rem', // Adjust as needed
            fontFamily: { xs: 'Roboto Condensed', sm: 'Roboto' },
          }),
        header: ({ theme }) =>
          theme.unstable_sx({
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 0,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            class: 'TableStack',
            mt: '.5rem',
            mb: '.25rem',
            padding: '0rem',
            backgroundColor: 'surface.variant',
            color: 'surface.onVariant',
            fontSize: '.8rem', // Adjust as needed
            fontFamily: { xs: 'Roboto Condensed', sm: 'Roboto' },
          }),
        title: ({ theme }) =>
          theme.unstable_sx({
            mt: '.5rem',
            color: 'surface.contrastText',
            fontFamily: 'Roboto',
            fontSize: '1.1rem',
            fontWeight: 300,
          }),
        total: ({ theme }) =>
          theme.unstable_sx({
            justifyContent: 'flex-start',
            textAlign: 'left',
            pl: '.5rem',
            width: { xs: '3rem', sm: '4rem', md: '5rem' },
          }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'surface.container',
            color: 'surface.contrastText',
            display: 'block',
            height: APP_BAR_HEIGHT,
            minHeight: APP_BAR_HEIGHT,
            my: 0,
            py: 0,
            typography: {
              fontSize: '2rem',
            },
            '.MuiToolbar-root': {
              backgroundColor: 'surface.container',
              color: 'surface.contrastText',
              pr: '1rem',
            },
            '.NavMenu': {
              pl: '1rem',
            },
            '.AppBarIcon': {
              color: 'surface.onVariant',
              pl: '.5rem',
              pr: '.5rem',
              height: '3rem',
              wdith: '3rem',
            },
            '.AppBarText': {
              color: 'surface.onVariant',
              fontFamily: 'sans-serif',
              fontSize: { xs: '1rem', sm: '1.25rem' },
              fontWeight: 'inherit',
              letterSpacing: '0rem',
              textDecoration: 'none',
            },
            '.AppBarText-home': {
              color: 'surface.onVariant',
              fontFamily: 'monospace',
              fontSize: '1.375rem',
              fontWeight: 700,
              letterSpacing: '.3rem',
            },
          }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            width: { xs: '14rem', sm: '16rem', md: '18rem' },
          }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'surface.main',
            color: 'surface.contrastText',
          }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'surface.contrastText',
            backgroundColor: 'surface.main',
            '&.BodyContainer': {
              color: 'surface.contrastText',
              typography: 'body2',
              px: '0rem',
              pt: '0rem',
            },
          }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            mt: '.25rem',
            mb: '.5rem',
            borderColor: 'outline.variant',
          }),
        middle: ({ theme }) =>
          theme.unstable_sx({
            mx: '1rem',
          }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'surface.container',
            color: 'surface.contrastText',
            width: '5rem',
            fontSize: '1rem',
            flexShrink: 0,
            overflow: 'auto',
            '& .MuiDrawer-paper': {
              width: '5rem',
              backgroundColor: 'surface.container',
              color: 'surface.contrastText',
              boxSizing: 'border-box',
            },
            '.MuiIconButton-root': {
              typography: 'iconbutton',
              flexDirection: 'column',
              width: '100%',
            },
            '& .MuiIconButton-root:hover': {
              width: '100%',
            },
          }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            backgroundColor: 'primary.container',
            color: 'primary.onContainer',
          }),
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'surface.contrastText',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            '&.MuiGrid-item': {
              width: '100%',
            },
            '&.TableDense': {
              typography: '',
            },
          }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'surface.main',
            gap: theme.spacing(2),
            py: 1,
            '&.test': {
              border: '.2rem dotted',
              borderColor: 'test.main',
            },
            '&.admin': {
              backgroundColor: 'primary.container',
              color: 'primary.onContainer',
            },
            '&.inactive': {
              color: 'text.disabled',
              '& .MuiTypography-root': {
                color: 'text.disabled',
              },
              '& .MuiSvgIcon-root': {
                color: 'text.disabled',
              },
              opacity: 0.6,
            },
            '&.unpublished': {
              color: 'text.disabled',
              '& .MuiTypography-root': {
                color: 'text.disabled',
              },
            },
          }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'surface.onVariant',
          }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'surface.onVariant',
          }),
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'surface.contrastText',
          }),
      },
    },
    MuiStack: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            mx: 0,
            px: 0,
            py: 0,
            '&.AppStack': {
              backgroundColor: 'surface.main',
              color: 'surface.contrastText',
              display: 'flex',
              FlexGrow: 1,
            },
            '&.TableStack': {
              my: '0rem',
              pb: '.25rem',
            },
            '&.TableStack &.TableStack': {
              my: '0rem',
              pb: '.25rem',
            },
            '&.test': {
              border: '.2rem dotted',
              borderColor: 'test.main',
            },
          }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '& .MuiSwitch-switchBase': {
              '&.Mui-checked': {
                color: 'primary.fixed',
                '& + .MuiSwitch-track': {
                  backgroundColor: 'primary.main',
                },
              },
              '&.Mui-disabled': {
                color: 'outline.main',
                '& + .MuiSwitch-track': {
                  backgroundColor: 'surface.container',
                },
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: theme.spacing(1.37),
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: theme.spacing(2.5),
                height: theme.spacing(2.5),
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: theme.spacing(2),
              height: theme.spacing(2),
              margin: theme.spacing(0.25),
            },
          }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            p: 0,
            bt: 0,
            borderBottom: 1,
            width: '100%',
            backgroundColor: 'surface.main',
            color: 'surface.onVariant',
            '&.Mui-selected': {
              color: 'primary.main',
            },
          }),
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            pt: 1,
            px: 0,
            pb: 0,
          }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.firstColumn': {
              backgroundColor: 'primary.container',
              color: 'primary.onContainer',
            },
            '& .MuiTableCell-root:last-child': {
              borderRight: 'none',
            },
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: 'divider',
            padding: 1,
            minWidth: '150px',
            whiteSpace: 'nowrap',
          }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'divider',
            overflowX: 'auto',
            maxWidth: '100%',
          }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.MuiTableRow-head': {
              backgroundColor: 'primary.container',
              color: 'primary.onContainer',
            },
            backgroundColor: 'surface.main',
            color: 'surface.contrastText',
          }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'surface.main',
            color: 'surface.contrastText',
            [`& .MuiToggleButtonGroup-grouped`]: {
              '&.Mui-disabled': {
                border: 0,
              },
            },
            [`& .MuiToggleButtonGroup-middleButton, & .MuiToggleButtonGroup-lastButton`]: {
              borderLeft: '1px solid transparent',
            },
          }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-selected': {
              color: 'secondary.onContainer',
              backgroundColor: 'secondary.container',
            },
          }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headline: 'h5',
          title: 'h6',
          h6dense: 'h6',
          label: 'p',
          pdense: 'p',
          iconbutton: 'p',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    headline: {
      color: 'surface.contrastText',
      fontFamily: 'Roboto',
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    title: {
      color: 'secondary.contrastText',
      fontFamily: 'Roboto',
      fontSize: '1.1rem',
      fontWeight: 300,
    },
    h6dense: {
      color: 'surface.contrastText',
      fontFamily: 'Roboto Condensed',
      fontSize: '1rem',
      fontWeight: 800,
    },
    label: {
      color: 'secondary.light',
      fontFamily: 'Roboto',
      fontSize: '.8rem',
      fontWeight: 200,
    },
    pdense: {
      color: 'surface.contrastText',
      fontFamily: 'Roboto Condensed',
      fontSize: '.8rem',
      fontWeight: 300,
      textAlign: 'center',
    },
    iconbutton: {
      fontFamily: 'Roboto Condensed',
      fontSize: '1rem',
      fontWeight: 300,
    },
  },
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          main: '#DDB9F8',
          light: alpha('#DDB9F8', 0.5),
          dark: alpha('#DD89F8', 0.9),
          contrastText: '#402357', //aka on
          container: '#583A6F',
          onContainer: '#F2DAFF',
          fixed: '#F2DAFF',
          onFixed: '#2A0C41',
          fixedDim: '#DDB9F8',
          onFixedVariant: '#583A6F',
        },
        secondary: {
          main: '#FFB3B1',
          light: alpha('#FFB3B1', 0.5),
          dark: alpha('#FFB3B1', 0.9),
          contrastText: '#571D1F',
          container: '#733333',
          onContainer: '#FFDAD8',
          fixed: '#FFDAD8',
          onFixed: '#3B080C',
          fixedDim: '#FFB3B1',
          onFixedVariant: '#733333',
        },
        tertiary: {
          main: '#F4B7BB',
          light: alpha('#F4B7BB', 0.5),
          dark: alpha('#F4B7BB', 0.9),
          contrastText: '#4C2529',
          container: '#663A3F',
          onContainer: '#FFDADB',
          fixed: '#FFDADB',
          onFixed: '#321015',
          fixedDim: '#F4B7BB',
          onFixedVariant: '#663A3F',
        },
        error: {
          main: '#FFB4AB',
          light: alpha('#FFB4AB', 0.5),
          dark: alpha('#FFB4AB', 0.9),
          contrastText: '#690005',
          container: '#93000A',
          onContainer: '#FFDAD6',
        },
        test: {
          main: '#FFD700',
          contrastText: '#694E00',
          container: '#936B00',
          onContainer: '#FFE999',
          variant: '#7B5B00',
          onVariant: '#FFE070',
          dim: '#4C3900',
          bright: '#FFEB3B',
        },
        background: {
          default: '#151217',
          // on: "#E8E0E8",
        },
        surface: {
          main: '#161219',
          inverse: '#E8E0E8',
          onInverse: '#332F35',
          contrastText: '#E9DFEA',
          tint: '#DDB9F8',
          variant: '#4C4452',
          onVariant: '#CFC2D4',
          dim: '#161219',
          bright: '#3D3740',
          container: '#231E26',
        },
        outline: {
          main: '#968E98',
          variant: '#4B454D',
        },
        divider: '#4B454D', // Same as OutlineVariant in MD3
      },
    },
    light: {
      palette: {
        primary: {
          main: '#705289',
          contrastText: '#FFFFFF',
          container: '#F2DAFF',
          onContainer: '#2A0C41',
          fixed: '#F2DAFF',
          onFixed: '#2A0C41',
          fixedDim: '#DDB9F8',
          onFixedVariant: '#583A6F',
          inverse: '#DDB9F8',
        },
        secondary: {
          main: '#904A49',
          contrastText: '#FFFFFF',
          container: '#FFDAD8',
          onContainer: '#3B080C',
          fixed: '#FFDAD8',
          onFixed: '#3B080C',
          fixedDim: '#FFB3B1',
          onFixedVariant: '#733333',
        },
        tertiary: {
          main: '#815156',
          contrastText: '#FFFFFF',
          container: '#FFDADB',
          onContainer: '#321015',
          fixed: '#FFDADB',
          onFixed: '#321015',
          fixedDim: '#F4B7BB',
          onFixedVariant: '#663A3F',
        },
        error: {
          main: '#BA1A1A',
          contrastText: '#FFFFFF',
          container: '#FFDAD6',
          onContainer: '#410002',
        },
        test: {
          main: '#936B00', // Darker gold for contrast on light
          contrastText: '#FFFFFF', // White text on main
          container: '#FFF3C4', // Light gold container
          onContainer: '#694E00', // Dark gold text
          variant: '#FFE070', // Light gold variant
          onVariant: '#7B5B00', // Dark gold on variant
          dim: '#B88A00', // Mid gold
          bright: '#FFF8DC', // Brightest gold
        },
        background: {
          default: '#FFF7FD',
          // on: "#1E1A20",
        },
        surface: {
          main: '#FFF7FD',
          inverse: '#332F35',
          onInverse: '#F7EEF6',
          contrastText: '#1F1A22',
          tint: '#7F3CB7',
          variant: '#ECDEF0',
          onVariant: '#4C4452',
          dim: '#E1D7E2',
          bright: '#E1D7E2',
          container: '#F5EBF6',
        },
        outline: {
          main: '#7E7383',
          variant: '#CFC2D4',
        },
        divider: '#CFC2D4', // Same as OutlineVariant in MD3
      },
    },
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default mui6Theme;

/*
{
    "description": "TYPE: CUSTOM\nMaterial Theme Builder export 2024-10-06 10:44:37",
    "seed": "#803DB8",
    "coreColors": {
        "primary": "#803DB8"
    },
    "extendedColors": [],
    "schemes": {
        "light": {
            "primary": "#6923A1",
            "surfaceTint": "#7F3CB7",
            "onPrimary": "#FFFFFF",
            "primaryContainer": "#904DC8",
            "onPrimaryContainer": "#FFFFFF",
            "secondary": "#6E5384",
            "onSecondary": "#FFFFFF",
            "secondaryContainer": "#EACCFF",
            "onSecondaryContainer": "#4F3664",
            "tertiary": "#89165C",
            "onTertiary": "#FFFFFF",
            "tertiaryContainer": "#B73F82",
            "onTertiaryContainer": "#FFFFFF",
            "error": "#BA1A1A",
            "onError": "#FFFFFF",
            "errorContainer": "#FFDAD6",
            "onErrorContainer": "#410002",
            "background": "#FFF7FD",
            "onBackground": "#1F1A22",
            "surface": "#FFF7FD",
            "onSurface": "#1F1A22",
            "surfaceVariant": "#ECDEF0",
            "onSurfaceVariant": "#4C4452",
            "outline": "#7E7383",
            "outlineVariant": "#CFC2D4",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#342E37",
            "inverseOnSurface": "#F8EEF9",
            "inversePrimary": "#DFB7FF",
            "primaryFixed": "#F1DAFF",
            "onPrimaryFixed": "#2D004F",
            "primaryFixedDim": "#DFB7FF",
            "onPrimaryFixedVariant": "#661F9E",
            "secondaryFixed": "#F1DAFF",
            "onSecondaryFixed": "#280F3C",
            "secondaryFixedDim": "#DBBAF2",
            "onSecondaryFixedVariant": "#553C6B",
            "tertiaryFixed": "#FFD8E7",
            "onTertiaryFixed": "#3D0026",
            "tertiaryFixedDim": "#FFAFD3",
            "onTertiaryFixedVariant": "#86135A",
            "surfaceDim": "#E1D7E2",
            "surfaceBright": "#FFF7FD",
            "surfaceContainerLowest": "#FFFFFF",
            "surfaceContainerLow": "#FBF0FC",
            "surfaceContainer": "#F5EBF6",
            "surfaceContainerHigh": "#EFE5F0",
            "surfaceContainerHighest": "#E9DFEA"
        },
        "light-medium-contrast": {
            "primary": "#621999",
            "surfaceTint": "#7F3CB7",
            "onPrimary": "#FFFFFF",
            "primaryContainer": "#904DC8",
            "onPrimaryContainer": "#FFFFFF",
            "secondary": "#513866",
            "onSecondary": "#FFFFFF",
            "secondaryContainer": "#85699C",
            "onSecondaryContainer": "#FFFFFF",
            "tertiary": "#810C56",
            "onTertiary": "#FFFFFF",
            "tertiaryContainer": "#B73F82",
            "onTertiaryContainer": "#FFFFFF",
            "error": "#8C0009",
            "onError": "#FFFFFF",
            "errorContainer": "#DA342E",
            "onErrorContainer": "#FFFFFF",
            "background": "#FFF7FD",
            "onBackground": "#1F1A22",
            "surface": "#FFF7FD",
            "onSurface": "#1F1A22",
            "surfaceVariant": "#ECDEF0",
            "onSurfaceVariant": "#48404E",
            "outline": "#655C6B",
            "outlineVariant": "#817787",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#342E37",
            "inverseOnSurface": "#F8EEF9",
            "inversePrimary": "#DFB7FF",
            "primaryFixed": "#9755D0",
            "onPrimaryFixed": "#FFFFFF",
            "primaryFixedDim": "#7D3AB5",
            "onPrimaryFixedVariant": "#FFFFFF",
            "secondaryFixed": "#85699C",
            "onSecondaryFixed": "#FFFFFF",
            "secondaryFixedDim": "#6C5181",
            "onSecondaryFixedVariant": "#FFFFFF",
            "tertiaryFixed": "#C1478A",
            "onTertiaryFixed": "#FFFFFF",
            "tertiaryFixedDim": "#A12D70",
            "onTertiaryFixedVariant": "#FFFFFF",
            "surfaceDim": "#E1D7E2",
            "surfaceBright": "#FFF7FD",
            "surfaceContainerLowest": "#FFFFFF",
            "surfaceContainerLow": "#FBF0FC",
            "surfaceContainer": "#F5EBF6",
            "surfaceContainerHigh": "#EFE5F0",
            "surfaceContainerHighest": "#E9DFEA"
        },
        "light-high-contrast": {
            "primary": "#37005E",
            "surfaceTint": "#7F3CB7",
            "onPrimary": "#FFFFFF",
            "primaryContainer": "#621999",
            "onPrimaryContainer": "#FFFFFF",
            "secondary": "#2F1743",
            "onSecondary": "#FFFFFF",
            "secondaryContainer": "#513866",
            "onSecondaryContainer": "#FFFFFF",
            "tertiary": "#49002E",
            "onTertiary": "#FFFFFF",
            "tertiaryContainer": "#810C56",
            "onTertiaryContainer": "#FFFFFF",
            "error": "#4E0002",
            "onError": "#FFFFFF",
            "errorContainer": "#8C0009",
            "onErrorContainer": "#FFFFFF",
            "background": "#FFF7FD",
            "onBackground": "#1F1A22",
            "surface": "#FFF7FD",
            "onSurface": "#000000",
            "surfaceVariant": "#ECDEF0",
            "onSurfaceVariant": "#29212E",
            "outline": "#48404E",
            "outlineVariant": "#48404E",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#342E37",
            "inverseOnSurface": "#FFFFFF",
            "inversePrimary": "#F7E6FF",
            "primaryFixed": "#621999",
            "onPrimaryFixed": "#FFFFFF",
            "primaryFixedDim": "#460075",
            "onPrimaryFixedVariant": "#FFFFFF",
            "secondaryFixed": "#513866",
            "onSecondaryFixed": "#FFFFFF",
            "secondaryFixedDim": "#3A224F",
            "onSecondaryFixedVariant": "#FFFFFF",
            "tertiaryFixed": "#810C56",
            "onTertiaryFixed": "#FFFFFF",
            "tertiaryFixedDim": "#5C003B",
            "onTertiaryFixedVariant": "#FFFFFF",
            "surfaceDim": "#E1D7E2",
            "surfaceBright": "#FFF7FD",
            "surfaceContainerLowest": "#FFFFFF",
            "surfaceContainerLow": "#FBF0FC",
            "surfaceContainer": "#F5EBF6",
            "surfaceContainerHigh": "#EFE5F0",
            "surfaceContainerHighest": "#E9DFEA"
        },
        "dark": {
            "primary": "#DFB7FF",
            "surfaceTint": "#DFB7FF",
            "onPrimary": "#4B007D",
            "primaryContainer": "#7632AE",
            "onPrimaryContainer": "#FFF8FC",
            "secondary": "#DBBAF2",
            "onSecondary": "#3E2553",
            "secondaryContainer": "#4C3361",
            "onSecondaryContainer": "#E6C5FD",
            "tertiary": "#FFAFD3",
            "onTertiary": "#620040",
            "tertiaryContainer": "#9A266A",
            "onTertiaryContainer": "#FFF9F9",
            "error": "#FFB4AB",
            "onError": "#690005",
            "errorContainer": "#93000A",
            "onErrorContainer": "#FFDAD6",
            "background": "#161219",
            "onBackground": "#E9DFEA",
            "surface": "#161219",
            "onSurface": "#E9DFEA",
            "surfaceVariant": "#4C4452",
            "onSurfaceVariant": "#CFC2D4",
            "outline": "#988D9D",
            "outlineVariant": "#4C4452",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#E9DFEA",
            "inverseOnSurface": "#342E37",
            "inversePrimary": "#7F3CB7",
            "primaryFixed": "#F1DAFF",
            "onPrimaryFixed": "#2D004F",
            "primaryFixedDim": "#DFB7FF",
            "onPrimaryFixedVariant": "#661F9E",
            "secondaryFixed": "#F1DAFF",
            "onSecondaryFixed": "#280F3C",
            "secondaryFixedDim": "#DBBAF2",
            "onSecondaryFixedVariant": "#553C6B",
            "tertiaryFixed": "#FFD8E7",
            "onTertiaryFixed": "#3D0026",
            "tertiaryFixedDim": "#FFAFD3",
            "onTertiaryFixedVariant": "#86135A",
            "surfaceDim": "#161219",
            "surfaceBright": "#3D3740",
            "surfaceContainerLowest": "#110D14",
            "surfaceContainerLow": "#1F1A22",
            "surfaceContainer": "#231E26",
            "surfaceContainerHigh": "#2D2830",
            "surfaceContainerHighest": "#38333B"
        },
        "dark-medium-contrast": {
            "primary": "#E2BCFF",
            "surfaceTint": "#DFB7FF",
            "onPrimary": "#260043",
            "primaryContainer": "#B572EF",
            "onPrimaryContainer": "#000000",
            "secondary": "#DFBFF6",
            "onSecondary": "#220937",
            "secondaryContainer": "#A385B9",
            "onSecondaryContainer": "#000000",
            "tertiary": "#FFB6D6",
            "onTertiary": "#33001F",
            "tertiaryContainer": "#E363A7",
            "onTertiaryContainer": "#000000",
            "error": "#FFBAB1",
            "onError": "#370001",
            "errorContainer": "#FF5449",
            "onErrorContainer": "#000000",
            "background": "#161219",
            "onBackground": "#E9DFEA",
            "surface": "#161219",
            "onSurface": "#FFF9FB",
            "surfaceVariant": "#4C4452",
            "onSurfaceVariant": "#D3C6D8",
            "outline": "#AB9FB0",
            "outlineVariant": "#8A7F8F",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#E9DFEA",
            "inverseOnSurface": "#2D2830",
            "inversePrimary": "#67209F",
            "primaryFixed": "#F1DAFF",
            "onPrimaryFixed": "#1E0037",
            "primaryFixedDim": "#DFB7FF",
            "onPrimaryFixedVariant": "#53008A",
            "secondaryFixed": "#F1DAFF",
            "onSecondaryFixed": "#1D0431",
            "secondaryFixedDim": "#DBBAF2",
            "onSecondaryFixedVariant": "#442B59",
            "tertiaryFixed": "#FFD8E7",
            "onTertiaryFixed": "#2A0019",
            "tertiaryFixedDim": "#FFAFD3",
            "onTertiaryFixedVariant": "#6D0047",
            "surfaceDim": "#161219",
            "surfaceBright": "#3D3740",
            "surfaceContainerLowest": "#110D14",
            "surfaceContainerLow": "#1F1A22",
            "surfaceContainer": "#231E26",
            "surfaceContainerHigh": "#2D2830",
            "surfaceContainerHighest": "#38333B"
        },
        "dark-high-contrast": {
            "primary": "#FFF9FB",
            "surfaceTint": "#DFB7FF",
            "onPrimary": "#000000",
            "primaryContainer": "#E2BCFF",
            "onPrimaryContainer": "#000000",
            "secondary": "#FFF9FB",
            "onSecondary": "#000000",
            "secondaryContainer": "#DFBFF6",
            "onSecondaryContainer": "#000000",
            "tertiary": "#FFF9F9",
            "onTertiary": "#000000",
            "tertiaryContainer": "#FFB6D6",
            "onTertiaryContainer": "#000000",
            "error": "#FFF9F9",
            "onError": "#000000",
            "errorContainer": "#FFBAB1",
            "onErrorContainer": "#000000",
            "background": "#161219",
            "onBackground": "#E9DFEA",
            "surface": "#161219",
            "onSurface": "#FFFFFF",
            "surfaceVariant": "#4C4452",
            "onSurfaceVariant": "#FFF9FB",
            "outline": "#D3C6D8",
            "outlineVariant": "#D3C6D8",
            "shadow": "#000000",
            "scrim": "#000000",
            "inverseSurface": "#E9DFEA",
            "inverseOnSurface": "#000000",
            "inversePrimary": "#42006F",
            "primaryFixed": "#F4E0FF",
            "onPrimaryFixed": "#000000",
            "primaryFixedDim": "#E2BCFF",
            "onPrimaryFixedVariant": "#260043",
            "secondaryFixed": "#F4E0FF",
            "onSecondaryFixed": "#000000",
            "secondaryFixedDim": "#DFBFF6",
            "onSecondaryFixedVariant": "#220937",
            "tertiaryFixed": "#FFDEEA",
            "onTertiaryFixed": "#000000",
            "tertiaryFixedDim": "#FFB6D6",
            "onTertiaryFixedVariant": "#33001F",
            "surfaceDim": "#161219",
            "surfaceBright": "#3D3740",
            "surfaceContainerLowest": "#110D14",
            "surfaceContainerLow": "#1F1A22",
            "surfaceContainer": "#231E26",
            "surfaceContainerHigh": "#2D2830",
            "surfaceContainerHighest": "#38333B"
        }
    },
    "palettes": {
        "primary": {
            "0": "#000000",
            "5": "#1E0037",
            "10": "#2D004F",
            "15": "#3C0066",
            "20": "#4B007D",
            "25": "#590A91",
            "30": "#661F9E",
            "35": "#722EAA",
            "40": "#7F3CB7",
            "50": "#9A57D3",
            "60": "#B572EF",
            "70": "#CD92FF",
            "80": "#DFB7FF",
            "90": "#F1DAFF",
            "95": "#FAECFF",
            "98": "#FFF7FD",
            "99": "#FFFBFF",
            "100": "#FFFFFF"
        },
        "secondary": {
            "0": "#000000",
            "5": "#180B24",
            "10": "#23162F",
            "15": "#2E203A",
            "20": "#392A45",
            "25": "#453551",
            "30": "#50415D",
            "35": "#5D4C69",
            "40": "#695875",
            "50": "#82708F",
            "60": "#9D8AAA",
            "70": "#B8A4C5",
            "80": "#D4BFE1",
            "90": "#F1DBFE",
            "95": "#FAECFF",
            "98": "#FFF7FD",
            "99": "#FFFBFF",
            "100": "#FFFFFF"
        },
        "tertiary": {
            "0": "#000000",
            "5": "#2A0209",
            "10": "#380B13",
            "15": "#46151D",
            "20": "#532027",
            "25": "#612A31",
            "30": "#6E353C",
            "35": "#7C4148",
            "40": "#8A4C53",
            "50": "#A7646B",
            "60": "#C57D84",
            "70": "#E3979E",
            "80": "#FFB2B9",
            "90": "#FFDADC",
            "95": "#FFECED",
            "98": "#FFF8F7",
            "99": "#FFFBFF",
            "100": "#FFFFFF"
        },
        "neutral": {
            "0": "#000000",
            "5": "#121013",
            "10": "#1D1B1E",
            "15": "#282528",
            "20": "#322F33",
            "25": "#3E3A3E",
            "30": "#494649",
            "35": "#555155",
            "40": "#615D61",
            "50": "#7A767A",
            "60": "#948F93",
            "70": "#AFAAAE",
            "80": "#CBC5C9",
            "90": "#E7E1E5",
            "95": "#F6EFF3",
            "98": "#FEF7FC",
            "99": "#FFFBFF",
            "100": "#FFFFFF"
        },
        "neutral-variant": {
            "0": "#000000",
            "5": "#140F17",
            "10": "#1E1A21",
            "15": "#29242C",
            "20": "#342E37",
            "25": "#3F3942",
            "30": "#4B454D",
            "35": "#575059",
            "40": "#635C65",
            "50": "#7C757E",
            "60": "#968E98",
            "70": "#B1A8B3",
            "80": "#CDC4CE",
            "90": "#E9DFEA",
            "95": "#F8EEF9",
            "98": "#FFF7FD",
            "99": "#FFFBFF",
            "100": "#FFFFFF"
        }
    }
}
*/

import { ReactElement } from 'react';

import { Timestamp } from 'firebase/firestore'; // Explicitly import Timestamp

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Season from '../../types/Season';

interface SeasonListItemProps {
  season: Season;
  isLeagueNameDisplayed?: boolean;
  isOrgNameDisplayed?: boolean;
  isDividerDisplayed?: boolean;
  isActive?: boolean;
  isClickable?: boolean;
  renderActions?: ReactElement;
}

export default function SeasonListItem(props: SeasonListItemProps): ReactElement {
  const {
    season,
    isLeagueNameDisplayed = false,
    isOrgNameDisplayed = false,
    isClickable = true,
    isDividerDisplayed = false,
    isActive = true,
    renderActions,
  } = props;

  const startDate = (season.startTime as Timestamp).toDate();
  const endDate = (season.endTime as Timestamp).toDate();
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const dateDisplay = (): ReactElement => {
    // Check for full year condition and matching year in season name
    const isFullYear =
      startDate.getMonth() === 0 &&
      startDate.getDate() === 1 &&
      endDate.getMonth() === 11 &&
      endDate.getDate() === 31 &&
      startYear === endYear &&
      season.name.toString() === startYear.toString();

    if (isFullYear) {
      return <Typography variant="body2">Full Year</Typography>;
    } else {
      return (
        <Stack direction="column" spacing={0.5}>
          <Typography variant="body2">
            {startDate.toLocaleDateString('default', { month: 'short', day: 'numeric' })} -{' '}
            {endDate.toLocaleDateString('default', { month: 'short', day: 'numeric' })}
          </Typography>
        </Stack>
      );
    }
  };

  const orgLeagueDisplay = (): ReactElement => {
    if (isOrgNameDisplayed && isLeagueNameDisplayed) {
      return <Typography variant="body2">{season.orgName} / {season.leagueName}</Typography>;
    } else if (isOrgNameDisplayed) {
      return <Typography variant="body2">{season.orgName}</Typography>;
    } else if (isLeagueNameDisplayed) {
      return <Typography variant="body2">{season.leagueName}</Typography>;
    } else {
      return <> </>;
    }
  };

  return (
    <ListItem
      divider={isDividerDisplayed}
      className={`${season.isTest ? 'test' : ''} ${!isActive ? 'inactive' : ''}`}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Box
        component={isClickable ? Link : 'div'}
        to={isClickable ? `/season/${season.orgId}/${season.itemId}` : undefined}
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          minWidth: 0,
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <ListItemAvatar>
          <Stack direction="column" spacing={0} sx={{ alignItems: 'flex-start' }}>
            <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }}>
              {season.name}
            </Typography>
            <Typography variant="h6" sx={{ display: { xs: 'none', md: 'block' } }}>
              {season.name}
            </Typography>
          </Stack>
        </ListItemAvatar>
        <ListItemText sx={{ flex: '1 1 auto' }}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="column" spacing={0.5}>
              {dateDisplay()}
            </Stack>
            {(isLeagueNameDisplayed || isOrgNameDisplayed) && orgLeagueDisplay()}
          </Stack>
        </ListItemText>
      </Box>
      {renderActions && (
        <ListItemText>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{renderActions}</Box>
        </ListItemText>
      )}
    </ListItem>
  );
}

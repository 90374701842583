import { ReactElement } from "react";

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";

import MoveDownIcon from "@mui/icons-material/MoveDown";

import { useAuth } from "../../contextProviders/AuthProvider";
import Event from "../../types/Event";

interface EventEditorGodActionsProps {
  event: Event;
  onChangeTest: (isTest: boolean) => void;
  onCopyToSeason: () => void;
}

export default function EventEditorGodActions(props: EventEditorGodActionsProps): ReactElement {
  const { event, onChangeTest, onCopyToSeason } = props;
  const { isGod } = useAuth();

  if (!isGod || !event) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      sx={{
        backgroundColor: 'tertiary.container',
        color: 'tertiary.onContainer',
        mt: 2,
        p: 1,
      }}
    >
      <FormGroup>
        <FormControlLabel
          id="isTest"
          value="top"
          label="Test?"
          control={
            <Switch
              checked={event.isTest}
              color="secondary"
              onChange={(e) => onChangeTest(e.target.checked)}
            />
          }
        />
      </FormGroup>
      <Button variant="outlined" size="medium" onClick={onCopyToSeason} startIcon={<MoveDownIcon />}>
        Copy to Season 2023
      </Button>
    </Stack>
  );
};
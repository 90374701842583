import { ReactElement, useState } from 'react';

import { Timestamp } from 'firebase/firestore';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';

import { DateTimePicker } from '@mui/x-date-pickers';

import GratiPageWrapper from '../components/common/GratiPageWrapper';
import { useAuth } from '../contextProviders/AuthProvider';
import { useFirestoreCollectionMutators } from '../dataMutators/useFirestoreCollectionMutators';
import DefaultObjects, { SupportedTypes } from '../types/DefaultObjects';
import Person from '../types/Person';
import { FromDayJS } from '../utils/utils';

export default function FirestoreUtilityPage(): ReactElement {
  const { isGod } = useAuth();
  const {
    createSubcollectionForAll,
    removeCollectionField,
    updateCollectionField,
    removeSubcollectionForAll,
  } = useFirestoreCollectionMutators();
  const navigate = useNavigate();
  type ValueType = 'string' | 'number' | 'boolean' | 'timestamp' | 'null';
  type FormDataValue = string | number | boolean | Timestamp | null;

  interface FormData {
    collection: string;
    data: string;
    field: string;
    value: FormDataValue;
    valueType: ValueType;
    removeField: boolean;
    updateField: boolean;
    createCollection: boolean;
    documentType: SupportedTypes | '';
    removeCollection: boolean;
  }

  const [formData, setFormData] = useState<FormData>({
    collection: '',
    data: '',
    field: '',
    value: null,
    valueType: 'string',
    removeField: false,
    updateField: false,
    createCollection: false,
    documentType: '',
    removeCollection: false,
  });


  /* const handleVerifyEmail = (auth: any, actionCode: any) => {
    applyActionCode(auth, actionCode!)
      .then((res) => {
        //SUCCESS: EMAIL VERIFIED
        console.log(res, 'user UID ?');
        // updateDoc(doc(db, 'Data', res.user.uid), {
        //   verifiedEmail: true,
        // });
      })
      .catch((error) => {
        console.log(error, 'Cannot verify email');
      });
  }; */

  // Add this helper function
  const convertValue = (value: string, type: string) => {
    switch (type) {
      case 'number':
        return Number(value);
      case 'boolean':
        return value.toLowerCase() === 'true';
      case 'timestamp':
        return Timestamp.fromDate(new Date(value));
      case 'null':
        return null;
      default:
        return value; // string
    }
  };

  const getDisplayValue = (value: FormDataValue): string => {
    console.log('value', value);
    if (value === null) return 'null';
    if (value instanceof Timestamp) return value.toDate().toISOString();
    return String(value);
  };

  const validateUser = (userProfile: Person | null | undefined) => {
    return userProfile && isGod ? true : false;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.removeCollection) {
      try {
        const count = await removeSubcollectionForAll(formData.collection);
        alert(`Removed ${formData.collection.split('/').pop()} subcollection from ${count} documents`);
      } catch (error) {
        console.error('Error removing subcollections:', error);
        alert(error instanceof Error ? error.message : 'Error removing subcollections');
      }
    } else if (formData.createCollection) {
      try {
        if (!formData.documentType) {
          throw new Error('Document type must be selected');
        }

        const count = await createSubcollectionForAll(formData.collection, DefaultObjects[formData.documentType]);
        alert(`Created ${formData.collection.split('/').pop()} subcollection for ${count} documents`);
      } catch (error) {
        console.error('Error creating subcollections:', error);
        alert(error instanceof Error ? error.message : 'Error creating subcollections');
      }
    } else if (formData.removeField) {
      removeCollectionField(formData.collection, formData.field);
    } else {
      updateCollectionField(formData.collection, formData.field, formData.value);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <GratiPageWrapper validateUser={validateUser}>
      <Stack spacing={2} component="form" onSubmit={(e) => handleSubmit(e)}>
        <TextField
          required
          autoFocus={true}
          id={'collection'}
          label="Firestore Collection"
          onChange={(e) => setFormData({ ...formData, collection: e.target.value })}
        />
        {formData.createCollection && DefaultObjects && (
          <TextField
            select
            required
            id="documentType"
            label="Document Type"
            value={formData.documentType}
            onChange={(e) => setFormData({ ...formData, documentType: e.target.value as SupportedTypes })}
          >
            <MenuItem value="">Select a type...</MenuItem>
            {Object.keys(DefaultObjects).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          required={!formData.removeField && !formData.createCollection && !formData.removeCollection}
          disabled={formData.createCollection || formData.removeCollection}
          id={'field'}
          label="Field to update"
          onChange={(e) => setFormData({ ...formData, field: e.target.value })}
        />
        <TextField
          select
          required={!formData.removeField && !formData.createCollection && !formData.removeCollection}
          disabled={formData.removeField || formData.createCollection || formData.removeCollection}
          id="valueType"
          label="Value Type"
          value={formData.valueType}
          onChange={(e) => setFormData({ ...formData, valueType: e.target.value as typeof formData.valueType })}
        >
          <MenuItem value="string">String</MenuItem>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="boolean">Boolean</MenuItem>
          <MenuItem value="timestamp">Timestamp</MenuItem>
          <MenuItem value="null">Null</MenuItem>
        </TextField>
        {!formData.removeField &&
          !formData.createCollection &&
          !formData.removeCollection &&
          (formData.valueType === 'timestamp' ? (
            <DateTimePicker
              disabled={formData.removeField || formData.createCollection || formData.removeCollection}
              label="Field value"
              value={formData.value instanceof Timestamp ? dayjs(formData.value.toDate()) : null}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  value: FromDayJS(dayjs(newValue))
                });
              }}
            />
          ) : (
            <TextField
              required={!formData.removeField && !formData.createCollection && !formData.removeCollection}
              disabled={formData.removeField || formData.createCollection || formData.removeCollection}
              id="value"
              label="Field value"
              type={formData.valueType === 'number' ? 'number' : 'text'}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  value: convertValue(e.target.value, formData.valueType),
                })
              }
            />
          ))}
        <FormControlLabel
          control={
            <Checkbox
              id={'removeField'}
              name={'removeField'}
              disabled={formData.collection.length === 0 || formData.field.length === 0 || formData.createCollection}
              checked={formData.removeField && !formData.createCollection}
              onChange={(e) => {
                const checked = e.target.checked;
                setFormData({
                  ...formData,
                  removeField: checked,
                  createCollection: false,
                  updateField: !checked,
                });
              }}
            />
          }
          label="Remove field"
        />
        <FormControlLabel
          control={
            <Checkbox
              id={'createCollection'}
              name={'createCollection'}
              disabled={formData.removeField || formData.collection.length === 0}
              checked={formData.createCollection}
              onChange={(e) => {
                const checked = e.target.checked;
                setFormData({
                  ...formData,
                  createCollection: checked,
                  removeField: false,
                  updateField: !checked,
                });
              }}
            />
          }
          label="Create subcollection"
        />
        <FormControlLabel
          control={
            <Checkbox
              id={'removeCollection'}
              name={'removeCollection'}
              disabled={formData.createCollection || formData.removeField || formData.collection.length === 0}
              checked={formData.removeCollection}
              onChange={(e) => {
                const checked = e.target.checked;
                setFormData({
                  ...formData,
                  removeCollection: checked,
                  createCollection: false,
                  removeField: false,
                  updateField: !checked,
                });
              }}
            />
          }
          label="Remove subcollection"
        />
        {formData.removeField && (
          <span>
            Remove the field: <b>{formData.field}</b> from <b>{formData.collection}</b>
          </span>
        )}
        {formData.updateField && formData.collection.length > 0 && formData.field.length > 0 && (
          <span>
            Collection: <b>{formData.collection}</b> Field: <b>{formData.field}</b> Value:{' '}
            <b>{getDisplayValue(formData.value)}</b>
          </span>
        )}
        {formData.createCollection && formData.collection.length > 0 && (
          <span>
            Subcollection <b>{formData.collection.split('/').pop()}</b> will be created under each doc in collection{' '}
            <b>{formData.collection.split('/')[0]}</b>
          </span>
        )}
        {formData.removeCollection && formData.collection.length > 0 && (
          <span>
            Subcollection <b>{formData.collection.split('/').pop()}</b> will be removed from each doc in collection{' '}
            <b>{formData.collection.split('/')[0]}</b>
          </span>
        )}
        <Stack direction="row" spacing={2} sx={{ py: 1, justifyContent: 'flex-start' }}>
          <Button variant="contained" size="medium" type="submit">
            Submit
          </Button>
          <Button variant="outlined" size="medium" type="button" onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </GratiPageWrapper>
  );
}

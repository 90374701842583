import { ReactElement, useMemo, useState } from 'react';

import { UploadResult } from 'firebase/storage';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import useOrgsWithAdminRights from '../../dataHooks/useOrgsWithAdminRights';
import Handbook from '../../types/Handbook';
import GratiImageUpload from '../common/GratiImageUpload';
import GratiRichEditor from '../common/GratiRichEditor';
import Hourglass from '../common/Hourglass';
import OrgSelector from '../org/OrgSelector';

import HandbookEditorGodActions from './HandbookEditorGodActions';

export type HandbookEditorProps = {
  handbook: Handbook;
  isNew?: boolean;
  initialSection?: string;
  onCancel: (currentSection: string) => void;
  onDelete?: (handbook: Handbook, isPermanentDelete?: boolean) => void;
  onSubmit: (handbook: Handbook, currentSection: string) => void;
};

export default function HandbookEditor(props: HandbookEditorProps): ReactElement {
  const { isNew = false, initialSection = '0', onCancel, onDelete, onSubmit } = props;

  const { isGod } = useAuth();
  const { isOrgAdmin } = useData();

  const [section, setSection] = useState(initialSection);
  const [deleteHandbookConfirm, setDeleteHandbookConfirm] = useState(false);
  const [deleteSectionConfirm, setDeleteSectionConfirm] = useState('');
  const [isPermanentDelete, setIsPermanentDelete] = useState(false);
  const [handbook, setHandbook] = useState<Handbook>(props.handbook);
  const { orgs, isOrgsLoading, orgsError } = useOrgsWithAdminRights();

  // Get org pairs from orgs user can admin
  const orgPairs = useMemo(() => {
    if (isNew) {
      if (!orgs) return []; // Handle potential null or undefined orgs
      return orgs.map((org) => ({
        orgId: org.itemId,
        orgName: org.name,
      }));
    } else {
      return [];
    }
  }, [orgs, isNew]);

  const handleDeleteHandbook = () => {
    if (onDelete) {
      onDelete(handbook, isPermanentDelete);
    }
    setDeleteHandbookConfirm(false);
  };

  const handleDeleteSection = () => {
    const newSections = [...handbook.sections];
    const deletedIndex = parseInt(deleteSectionConfirm);
    newSections.splice(deletedIndex, 1);
    setHandbook({ ...handbook, sections: newSections });
    setDeleteSectionConfirm('');

    // Switch to an appropriate tab after deletion
    const newIndex = Math.min(deletedIndex, newSections.length - 1);
    if (newIndex >= 0) {
      setSection(newIndex.toString());
    } else {
      setSection('0');
    }
  };

  const handleAddSection = () => {
    const newSections = [...handbook.sections];
    newSections.push({ title: 'New Section', html: '<p></p>', type: 'chapter' });
    setHandbook({ ...handbook, sections: newSections });
    // Switch to the new section
    setSection((newSections.length - 1).toString());
  };

  const handleImageUpdated = (snapshot: UploadResult | null) => {
    if (handbook) {
      setHandbook({ ...handbook, logo: snapshot ? snapshot.ref.name : '' });
    }
  };

  if (isNew && isOrgsLoading && !orgs) {
    return <Hourglass />;
  }

  if (isNew && orgsError) {
    console.error('Error loading organizations:', orgsError);
  }

  if (handbook) {
    if (isOrgAdmin(handbook.orgId) || (isNew && orgPairs.length > 0)) {
      return (
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(handbook, section);
          }}
        >
          <Stack spacing={2}>
            <Typography variant="headline">
              {isNew
                ? handbook.orgName
                  ? `Create Handbook for ${handbook.orgName}`
                  : 'Create Handbook'
                : orgs && orgs.length > 1
                ? 'Edit ' + handbook.orgName + ' Handbook'
                : 'Edit Handbook'}
            </Typography>
            <TextField
              id="name"
              label="Title"
              aria-label="Handbook Name"
              aria-required="true"
              defaultValue={handbook.name}
              onChange={({ target }) => setHandbook({ ...handbook, name: target.value })}
            />
            <GratiImageUpload
              onImageUpdated={handleImageUpdated}
              storageImageFileName={handbook.itemId + '-logo'}
              label="Icon"
            />
            {isNew && (
              <OrgSelector
                value={handbook.orgId}
                orgPairs={orgPairs}
                handleChange={(orgPair) => setHandbook({ ...handbook, orgId: orgPair.orgId, orgName: orgPair.orgName })}
              />
            )}

            <TabContext value={section}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                width="100%"
                sx={{
                  border: 1,
                  borderColor: 'outline.variant',
                  borderRadius: 1,
                }}
              >
                <TabList
                  onChange={(_event, newValue) => setSection(newValue)}
                  variant="fullWidth"
                  scrollButtons="auto"
                  aria-label="handbook content tabs"
                  TabIndicatorProps={{
                    sx: {
                      color: 'primary.onContainer',
                    },
                  }}
                >
                  {handbook.sections.map((section, index) => (
                    <Tab
                      key={index}
                      value={index.toString()}
                      label={section.title || `Section ${index + 1}`}
                      wrapped
                      sx={{
                        px: 2,
                        '&.Mui-selected': {
                          backgroundColor: 'primary.container',
                          color: 'primary.onContainer',
                        },
                        '&:not(.Mui-selected)': {
                          color: 'primary.onContainerVariant',
                        },
                      }}
                    />
                  ))}
                </TabList>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={handleAddSection}
                >
                  Add Section
                </Button>
              </Stack>
              {handbook.sections.map((section, index) => (
                <TabPanel key={index} value={index.toString()}>
                  <Stack direction="column" spacing={2}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      justifyContent={{ xs: 'center', sm: 'flex-start' }}
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      spacing={2}
                    >
                      <TextField
                        id={'sectionTitle' + index}
                        label="Section Title"
                        aria-label="Section Title"
                        aria-required="true"
                        value={section.title}
                        onChange={(e) => {
                          const newSections = [...handbook.sections];
                          newSections[index] = { ...section, title: e.target.value };
                          setHandbook({ ...handbook, sections: newSections });
                        }}
                      />
                      <Button
                        size="small"
                        variant="text"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => setDeleteSectionConfirm(index.toString())}
                      >
                        Delete Section
                      </Button>
                      {deleteSectionConfirm === index.toString() && (
                        <span>
                          <Button size="large" onClick={handleDeleteSection}>
                            Confirm
                          </Button>
                          <Button size="large" onClick={() => setDeleteSectionConfirm('')}>
                            Cancel
                          </Button>
                        </span>
                      )}
                    </Stack>
                    <GratiRichEditor
                      section={section}
                      sectionIndex={index}
                      onChange={(section, index) => {
                        const newSections = [...handbook.sections];
                        newSections[index] = section;
                        setHandbook({ ...handbook, sections: newSections });
                      }}
                    />
                  </Stack>
                </TabPanel>
              ))}
            </TabContext>
          </Stack>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={8}>
            <Button size="medium" variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
              Save
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={() => onCancel(section)}
            >
              Cancel
            </Button>
            {onDelete && !isNew && (
              <Button
                size="medium"
                variant="text"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteHandbookConfirm(true)}
              >
                Delete Handbook
              </Button>
            )}
            {deleteHandbookConfirm && (
              <span>
                <Button size="large" onClick={handleDeleteHandbook}>
                  Confirm
                </Button>
                <Button size="large" onClick={() => setDeleteHandbookConfirm(false)}>
                  Cancel
                </Button>
              </span>
            )}
          </Stack>
          {isGod && handbook && (
            <HandbookEditorGodActions
              handbook={handbook}
              onSetPermanentDelete={setIsPermanentDelete}
              onChangeTest={(isTest) => setHandbook({ ...handbook, isTest: isTest })}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Typography variant="headline">
          Not authorized to edit this handbook
        </Typography>
      );
    }
  } else {
    return <> </>;
  }
}

import { useEffect, ReactElement } from "react";

import { useNavigate } from "react-router";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import GratiPageWrapper from "../components/common/GratiPageWrapper";
import { useApp } from "../contextProviders/AppProvider";

export default function LoadingErrorPage(): ReactElement {
  const navigate = useNavigate();
  const { setPageName } = useApp();

  useEffect(() => {
    setPageName("Login to GratiGolf");
  }, [setPageName]);

  const GenerateErrorMessage = () => {
    return (
      <Alert variant="filled" severity="error">
        Login failed. It probably means there is a server issue. Please try again. 
        If the problem persists, please contact support.
      </Alert>
    );
  }

  const handleSubmit = () => {
    navigate("/login");
  }

  return (
    <GratiPageWrapper isNoAuthRequired={true}>
      <Container maxWidth="md">
        <Stack spacing={2} sx={{display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
          {GenerateErrorMessage()}
          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Try login again
          </Button>
        </Stack>
      </Container>
    </GratiPageWrapper>
  );
}

import { useMemo } from 'react';

import { collection, collectionGroup, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Season from '../types/Season';

interface UseSeasonsProps {
  fetchAll?: boolean;
  includeCatchAll?: boolean;
  leagueId?: string | null | undefined;
  orgId?: string | null | undefined;
}

export default function useSeasons(props: UseSeasonsProps) {
  const { leagueId, orgId, includeCatchAll = false, fetchAll = false } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading, isOrgMember, getMemberOrgs, getMemberLeagues } = useData();
  const memberOrgs = useMemo(() => getMemberOrgs(), [getMemberOrgs]);
  const memberLeagues = useMemo(() => getMemberLeagues(), [getMemberLeagues]);

  const seasonsQuery = useMemo(() => {
    // if the user isn't logged in, or if the user is requesting a specific league's seasons, but orgId or leagueId is undefined,
    // then we wait.
    if (!userProfile || (!fetchAll && orgId === undefined && leagueId === undefined)) return null;
    const catchAllConditions = [];
    const deletedConditions = [];
    const leagueConditions = [];
    const orgConditions = [];
    const testConditions = [];

    if (!isGod) {
      testConditions.push(where('isTest', '==', false));
      deletedConditions.push(where('isDeleted', '==', false));
    }

    if (!includeCatchAll) {
      catchAllConditions.push(where('isCatchAll', '==', false));
    }

    // If a specific org and league's seasons are requested, or if the user only belongs to a single league, then 
    // we don't need to use a collectionGroup query.
    if ((orgId || (fetchAll && memberOrgs.length === 1)) && (leagueId || (fetchAll && memberLeagues.length === 1))) {
      const queryOrgId = orgId || memberOrgs[0];
      const queryLeagueId = leagueId || memberLeagues[0];

      orgConditions.push(where('orgId', '==', queryOrgId));
      leagueConditions.push(where('leagueId', '==', queryLeagueId));

      return query(
        collection(firestore, 'org', queryOrgId, 'season') as Query<Season>,
        ...leagueConditions,
        ...deletedConditions,
        ...catchAllConditions,
        ...orgConditions,
        ...testConditions,
        orderBy('name')
      );
    } else {
      if (orgId) {
        orgConditions.push(where('orgId', '==', orgId));
      } else {
        orgConditions.push(where('orgId', 'in', memberOrgs));
      }

      if (leagueId) {
        leagueConditions.push(where('leagueId', '==', leagueId));
      }

      return query(
        collectionGroup(firestore, 'season') as Query<Season>,
        ...orgConditions,
        ...leagueConditions,
        ...catchAllConditions,
        ...deletedConditions,
        ...testConditions,
        orderBy('name')
      );
    }
  }, [fetchAll, isGod, leagueId, includeCatchAll, memberLeagues, memberOrgs, orgId, userProfile]);

  const [seasons, isSeasonsLoading, seasonsError] = useCollectionData<Season>(seasonsQuery);

  if (
    !isUserLoading && 
    !userProfile && 
    !isSeasonsLoading && 
    !seasonsError && 
    !(leagueId === undefined) && 
    !(orgId === undefined)) {
    return {
      seasons: null,
      isSeasonsLoading: false,
      seasonsError: new Error('User must be logged in to view seasons'),
    };
  }

  if (!isUserLoading && !isSeasonsLoading && !seasonsError && !isGod && orgId && leagueId && !isOrgMember(orgId)) {
    return {
      seasons: null,
      isSeasonsLoading: false,
      seasonsError: new Error('Not authorized to view this season'),
    };
  }

  // if the query qualifiers are passed in as null, then don't return anything. Not an error either. 
  // It just means that the calling component wanted nothing fetched.
  if (orgId === null && leagueId === null) {
    return {
      seasons: null,
      isSeasonsLoading: false,
    };
  }

  return {
    seasons: seasons || null,
    isSeasonsLoading: isSeasonsLoading || isUserLoading,
    seasonsError,
  };
}

import { Box, Stack } from '@mui/material';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import GratiRichTextContent from '../../types/GratiRichTextContent';

import GratiRichEditorToolbar from './GratiRichEditorToolbar';
import '../../styles/GratiRichEditor.css';

type GratiRichEditorProps = {
  section: GratiRichTextContent;
  sectionIndex: number;
  onChange?: (section: GratiRichTextContent, index: number) => void;
};

export default function GratiRichEditor(props: GratiRichEditorProps) {
  const { section, sectionIndex, onChange } = props;

  const editor = useEditor({
    extensions: [StarterKit],
    content: section.html,
    onUpdate: ({ editor }) => {
      onChange?.(
        {
          html: editor.getHTML(),
          type: section.type,
          title: section.title,
        },
        sectionIndex
      );
    },
  });

  return (
    <Stack direction="column" sx={{ height: '100vh', overflow: 'hidden' }}>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1}}>
        <GratiRichEditorToolbar editor={editor} />
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto', border: 1, borderColor: 'divider', borderRadius: 1, px: 2 }}>
        <EditorContent editor={editor} style={{  }} />
      </Box>
    </Stack>
  );
}

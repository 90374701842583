import { useMemo } from 'react';

import { collection, collectionGroup, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Membership from '../types/Membership';

interface UseMembershipsOfPersonProps {
  personId?: string | null;
  orgId?: string | null;
}

export default function useMembershipsOfPerson(props: UseMembershipsOfPersonProps) {
  const { personId, orgId } = props;
  const { isGod } = useAuth();
  const { userProfile } = useData();

  const membershipQuery = useMemo(() => {
    if (!personId) return null;
    if (!userProfile) return null;

    if(orgId) {
      if (isGod || userProfile?.orgIds?.includes(orgId)) {
        return query(
          collection(firestore, 'org', orgId, 'membership') as Query<Membership>,
          where('itemId', '==', personId),
          where('isActive', '==', true)
        );
      } else {
        return null;
      }
    }
    
      // If god or querying own memberships, can see all memberships
    // If god or querying own memberships, can see all memberships
    if (isGod || personId === userProfile.itemId) {
      console.log('isGod or personId === userProfile.itemId: ', personId);
      return query(
        collectionGroup(firestore, 'membership') as Query<Membership>,
        where('itemId', '==', personId),
        where('orgId', 'in', userProfile.orgIds)
      );
    }

    // Otherwise, can only see active memberships in shared orgs
    return query(
      collectionGroup(firestore, 'membership') as Query<Membership>,
        where('itemId', '==', personId), 
        where('orgId', 'in', userProfile.orgIds), 
        where('isActive', '==', true)
    );
  }, [personId, userProfile, isGod, orgId]);

  const [memberships, isMembershipsLoading, membershipsError] = useCollectionData<Membership>(membershipQuery);

  if (!isMembershipsLoading && 
      !membershipsError && 
      userProfile?.orgIds && 
      userProfile.orgIds.length === 0 && 
      personId) {
    return {
      memberships: [],
      isMembershipsLoading: false,
      membershipsError: new Error('User must be logged in to view memberships'),
    };
  }

  // if the query qualifiers are passed in as null, then don't return anything. Not an error either. 
  // It just means that the calling component wanted nothing fetched.
  if (personId === null) {
    console.log('personId is null');
    return {
      memberships: [],
      isMembershipsLoading: false,
    };
  }

  if (orgId && userProfile && !isGod && !userProfile?.orgIds?.includes(orgId)) {
    return {
      memberships: [],
      isMembershipsLoading: false,
      membershipsError: new Error('User not authorized to get these memberships'),
    };
  }

  return {
    memberships: memberships || [],
    isMembershipsLoading: isMembershipsLoading || !userProfile,
    membershipsError,
  };
}

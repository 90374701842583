import { useMemo } from 'react';

import { collection, doc, DocumentReference } from 'firebase/firestore';

import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';

import type Org from '../types/Org';

export default function useOrg(orgId: string | undefined | null) {
  const { isGod } = useAuth();
  const { userProfile, isUserLoading } = useData();

  const orgRef = useMemo(() => {
    if (!orgId) return null;
    if (!isGod && !userProfile?.orgIds?.includes(orgId)) return null;

    return doc(collection(firestore, 'org'), orgId) as DocumentReference<Org>;
  }, [orgId, isGod, userProfile?.orgIds]);

  const [org, isOrgLoading, orgError] = useDocumentData<Org>(orgRef);

  if (orgId === null) {
    return {
      org: null,
      isOrgLoading: false,
    };
  }

  // Convert permission error to a proper error object
  if (!isOrgLoading && !orgError && !isGod && orgId && !userProfile?.orgIds?.includes(orgId) && !isUserLoading) {
    return {
      org: null,
      isOrgLoading: false,
      orgError: new Error('Not authorized to access this organization'),
    };
  }

  return {
    org: org || null,
    isOrgLoading: isOrgLoading || isUserLoading,
    orgError,
  };
}

import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';

import { Timestamp } from 'firebase/firestore';
import { UploadResult } from 'firebase/storage';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import dayjs, { Dayjs } from 'dayjs';

import { useAuth } from '../../contextProviders/AuthProvider';
import { useData } from '../../contextProviders/DataProvider';
import { useEventMutators } from '../../dataMutators/useEventMutators';
import Competition from '../../types/Competition';
import { CoursePair } from '../../types/Course';
import Event from '../../types/Event';
import EventDay from '../../types/EventDay';
import { CombineDateJSTimeJS, ToDayJS } from '../../utils/utils';
import GratiImageUpload from '../common/GratiImageUpload';
import CompetitionEditor from '../competition/CompetitionEditor';
import LeagueSelector from '../league/LeagueSelector';

import EventDateCalendar from './EventDateCalendar';
import EventEditorGodActions from './EventEditorGodActions';
import EventGolfSettingsEditor from './EventGolfSettingsEditor';
import EventMembershipTypeEditor from './EventMembershipEditor';
import EventSignupTimeEditor from './EventSignupTimeEditor';
import EventTimeEditor from './EventTimeEditor';
import EventTypeEditor from './EventTypeEditor';

export interface EventEditorProps {
  event: Event;
  isNewEvent: boolean;
  handleCancel?: () => void;
  handleSubmit?: (event: Event) => void;
}

export default function EventEditor(props: EventEditorProps): ReactElement {
  const { event: initialEvent, isNewEvent, handleCancel, handleSubmit } = props;
  const { isGod, isRevealingGodsSecrets } = useAuth();
  const { userProfile } = useData();
  const { deleteEvent } = useEventMutators();
  const navigate = useNavigate();

  const [updatedEvent, setUpdatedEvent] = useState<Event>(initialEvent);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [isMultiDay, setIsMultiDay] = useState<boolean>(initialEvent.days.length > 1);
  const [isGolf, setIsGolf] = useState<boolean>(initialEvent.isGolf ?? false);
  console.log('initialEvent: ', initialEvent);
  console.log('updatedEvent: ', updatedEvent);

  const handleUpdate = useCallback((updates: Partial<Event>) => {
    setUpdatedEvent((prev) => {
      const next = {
        ...prev,
        ...updates,
      };
      console.log('Updating event:', { prev, updates, next });
      return next;
    });
  }, []);

  const [defaultTime, setDefaultTime] = useState<Dayjs>(
    initialEvent && initialEvent.days.length > 0 && initialEvent.days[0].datetime
      ? ToDayJS((initialEvent.days[0].datetime as Timestamp), initialEvent.timezone)
      : dayjs('2000-01-01T09:00:00')
  );
  const defaultCourseId = useRef(
    initialEvent && initialEvent.days.length > 0 && initialEvent.days[0].courseId ? initialEvent.days[0].courseId : ''
  );

  const onCancel = useCallback(() => {
    if (handleCancel) {
      handleCancel();
    } else {
      navigate(-1);
      console.log('No cancel handler provided.');
    }
  }, [handleCancel, navigate]);

  const onSubmit = () => {
    if (handleSubmit) {
      handleSubmit(updatedEvent);
    } else {
      console.log('No submit handler provided.');
    }
  };

  //Todo: Write the logic to move events to seasons.
  const handleCopyToSeason = useCallback(() => {
    if (initialEvent) {
      console.log('Will eventually use this to migrate events to seasons.');
      // copyEventToSeason(initialEvent);
    }
  }, [initialEvent]);

  const handleImageUpdated = (snapshot: UploadResult | null) => {
    if (updatedEvent) {
      setUpdatedEvent({ ...updatedEvent, logo: snapshot ? snapshot.ref.name : '' });
    }
    console.log('Image uploaded: ' + snapshot?.ref.name);
  };

  const handleDelete = useCallback(() => {
    if (!initialEvent) return;

    deleteEvent(initialEvent)
      .then(() => {
        navigate('/events');
      })
      .catch((error) => {
        console.error('Error deleting event:', error);
      });
  }, [initialEvent, deleteEvent, navigate]);

  const handleEventDateChange = (dates: Dayjs[] | null) => {
    console.log("handleEventDateChange: ", dates);
    if (updatedEvent) {
      setIsGolf(updatedEvent.isGolf ?? false);
      if (!dates || dates.length === 0) {
        setUpdatedEvent({ ...updatedEvent, days: [] });
      } else if (updatedEvent.days.length === 0) {
        setUpdatedEvent({
          ...updatedEvent,
          days: dates.map((date) => ({
            datetime: CombineDateJSTimeJS(date, defaultTime),
            courseId: defaultCourseId.current,
            duration: 60,
            isGolf: updatedEvent.isGolf,
          })),
        });
      } else {
        console.log('Updating event days:', dates);
        const newDays = dates.map((date, i) => {
          const existingDay = updatedEvent.days.find((day) => ToDayJS(day.datetime as Timestamp).isSame(date, 'D'));
          if (existingDay) {
            return existingDay;
          } else {
            return {
              datetime: CombineDateJSTimeJS(date, defaultTime),
              courseId: defaultCourseId.current,
              duration: 60,
              isGolf: updatedEvent.isGolf,
            };
          }
        });
        console.log('New days:', newDays);
        setUpdatedEvent({ ...updatedEvent, days: newDays });
      }
    }
  };

  const handleEventTimesChange = (days: EventDay[] | null, coursePairs: CoursePair[]) => {
    if (updatedEvent) {
      if (days) {
        setUpdatedEvent({ ...updatedEvent, days: days, coursePairs: coursePairs });
        setDefaultTime(
          ToDayJS(
            days[0].datetime as Timestamp, 
            coursePairs.length > 0 ? coursePairs[0].timezone : props.event.timezone
          )
        );
      }
    }
  };

  const handleCompetitionChange = (competition: Partial<Competition>) => {
    if (updatedEvent) {
      const competitions = updatedEvent.competitions;
      if (competitions?.length) {
        console.log('Competition changed: ' + competitions[0].itemId);
      }
    }
  };

  function Competitions(): ReactElement {
    if (!updatedEvent || !updatedEvent.competitions) {
      return <Button>Add Competition</Button>;
    } else {
      return (
        <Stack spacing={2}>
          {updatedEvent.competitions.map((competition) => (
            <CompetitionEditor key={competition.itemId} competition={competition} onChange={handleCompetitionChange} />
          ))}
        </Stack>
      );
    }
  }

  const actionButtons = useMemo((): ReactElement => {
    return (
      <Stack direction="row" spacing={2}>
        <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
          {isNewEvent ? 'Create Event' : 'Update Event'}
        </Button>

        <Button variant="outlined" color="secondary" startIcon={<CancelIcon />} onClick={onCancel}>
          Cancel
        </Button>

        {!isNewEvent && ( // Only show delete for existing events
          <>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteConfirm(true)}
            >
              Delete Event
            </Button>

            {deleteConfirm && (
              <>
                <Button onClick={handleDelete}>Confirm</Button>
                <Button onClick={() => setDeleteConfirm(false)}>Cancel</Button>
              </>
            )}
          </>
        )}
      </Stack>
    );
  }, [isNewEvent, deleteConfirm, onCancel, handleDelete]);

  if (!updatedEvent) return <Container />;

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Stack>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                autoFocus
                id="name"
                label="Name"
                value={updatedEvent.name}
                onChange={({ target }) => handleUpdate({ name: target.value })}
              />
              <LeagueSelector
                value={updatedEvent.leagueId}
                required
                onChange={(league) => {
                  console.log('handle league Change: ', league);
                  handleUpdate({
                    leagueId: league?.itemId,
                    leagueName: league?.name,
                    orgId: league?.orgId,
                    orgName: league?.orgName,
                  });
                }}
                showOrgNames={(userProfile?.orgIds?.length ?? 0) > 1}
                disabled={!isNewEvent}
                helperText={!isNewEvent ? 'League can only be set when creating a new event.' : ''}
              />
              <TextField
                fullWidth
                multiline
                id="description"
                label="Description"
                value={updatedEvent.description}
                onChange={({ target }) => handleUpdate({ description: target.value })}
              />

              <GratiImageUpload
                onImageUpdated={handleImageUpdated}
                storageImageFileName={`${updatedEvent.itemId}-logo`}
                label="Logo"
              />

              <EventSignupTimeEditor event={updatedEvent} onUpdate={handleUpdate} />

              <EventTypeEditor
                isGolf={isGolf}
                isMultiDay={isMultiDay}
                onGolfChange={setIsGolf}
                onMultiDayChange={setIsMultiDay}
                onUpdate={handleUpdate}
              />
            </Stack>
          </Grid>

          {/* Right Column */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <Stack spacing={2}>
              {isGolf && <EventGolfSettingsEditor event={updatedEvent} onUpdate={handleUpdate} />}
              <EventDateCalendar
                isMultiDay={isMultiDay}
                onChange={handleEventDateChange}
                orientation="portrait"
                value={updatedEvent.days.map((day) => ToDayJS((day.datetime as Timestamp), updatedEvent.timezone))}
              />
              <EventTimeEditor
                coursePairs={updatedEvent.coursePairs}
                days={updatedEvent.days}
                defaultTime={defaultTime}
                isGolfEvent={isGolf}
                onChange={(days: EventDay[], coursePairs: CoursePair[]) => handleEventTimesChange(days, coursePairs)}
                timezone={updatedEvent.timezone}
              />
              {!isGolf && (
                <TextField
                  id="socialLocation"
                  label="Social Location"
                  value={updatedEvent.socialLocation}
                  onChange={({ target }) => handleUpdate({ socialLocation: target.value })}
                />
              )}

              {(isGolf || isRevealingGodsSecrets) && (
                <EventMembershipTypeEditor event={updatedEvent} onUpdate={handleUpdate} />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Competitions />
        {actionButtons}
        {isGod && (
          <EventEditorGodActions 
            event={updatedEvent} 
            onChangeTest={(isTest) => handleUpdate({ isTest })}
            onCopyToSeason={handleCopyToSeason}
          />
        )}
      </Stack>
    </Box>
  );
}

import { useMemo } from 'react';

import { collection, orderBy, Query } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Application from '../types/Application';

interface UseApplicationsProps {
  orgIds?: string[];
}

export default function useApplications(props: UseApplicationsProps) {
  const { isGod } = useAuth();
  const { userProfile, isUserLoading } = useData();

  // Filter orgIds to only those the user has access to
  const accessibleOrgIds = useMemo(() => {
    if (isGod) return props.orgIds; // God can access all requested orgs
    return props.orgIds ? userProfile?.orgIds?.filter((orgId) => props.orgIds?.includes(orgId)) : userProfile?.orgIds;
  }, [isGod, props.orgIds, userProfile?.orgIds]);

  // Create the query reference
  const applicationsQuery = useMemo(() => {
    if (!userProfile) return null;
    if (!isGod && (!accessibleOrgIds || accessibleOrgIds.length === 0)) return null;

    // If orgIds specified and accessible, query for those orgs
    if (accessibleOrgIds && accessibleOrgIds.length > 0) {
      return query(
        collection(firestore, 'application'),
        where('orgId', 'in', accessibleOrgIds),
        orderBy('name')
      ) as Query<Application>;
    }

    // If no orgIds specified and user is god, return all applications
    if (isGod) {
      return query(collection(firestore, 'application'), orderBy('name')) as Query<Application>;
    }

    return null;
  }, [accessibleOrgIds, isGod, userProfile]);

  const [applications, isApplicationsLoading, applicationsError] = useCollectionData<Application>(applicationsQuery);

  if (!userProfile && !isUserLoading && !isApplicationsLoading && !applicationsError) {
    return {
      applications: null,
      isApplicationsLoading: false,
      applicationsError: new Error('User must be logged in')
    };
  }

  return {
    applications: applications || null,
    isApplicationsLoading: isApplicationsLoading || isUserLoading,
    applicationsError,
  };
}

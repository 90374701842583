import { useMemo } from 'react';

import { and, collection, orderBy, Query, query, where } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../contextProviders/AuthProvider';
import { useData } from '../contextProviders/DataProvider';
import { firestore } from '../firebase';
import Course from '../types/Course';

interface UseCoursesProps {
  courseIdList?: string[];
}

export default function useCourses(props: UseCoursesProps) {
  const { courseIdList } = props;
  const { isGod } = useAuth();
  const { userProfile, isUserLoading } = useData();

  const coursesQuery = useMemo(() => {
    if (!userProfile) return null;

    if (courseIdList?.length) {
      return query(
        collection(firestore, 'course') as Query<Course>,
        and(where('isTest', 'in', [isGod, false]), where('itemId', 'in', courseIdList)),
        orderBy('name')
      );
    }

    return query(
      collection(firestore, 'course') as Query<Course>,
      where('isTest', 'in', [isGod, false]),
      orderBy('name')
    );
  }, [courseIdList, isGod, userProfile]);

  const [courses, isCoursesLoading, coursesError] = useCollectionData<Course>(coursesQuery);

  if (!userProfile && !isUserLoading && !isCoursesLoading && !coursesError) {
    return {
      courses: null,
      isCoursesLoading: false,
      coursesError: new Error('User must be logged in to view courses'),
    };
  }

  return {
    courses: courses ?? [], 
    isCoursesLoading: isCoursesLoading || isUserLoading,
    coursesError,
  };
}
